import React from "react"
import { BoxSection, Grid, GridItem } from "atmos-design-system"
import PilotCard from "../Card"
import { statuses } from "./constants"
import GradingGuidelines from "./gradingGuidelines"
import EvaluatorInformationForm from "./evaluatorInformationForm"
import PilotInformationForm from "./pilotInformationForm"
import FlightInformationForm from "./flightInformationForm"

interface EvaluationInformationProps {
  pilotInfo: SearchEmployeeResponse
  captainInfo: CaptainsInfo
  flightInfo: EvaluationFlightInfo
  evaluationInfo: EvaluationInfo
  status: string
  flightType: string
  flightTypeTitle: string
  isManual: boolean
  setFlightInfo: (payload: EvaluationFlightInfo) => void
  setFlightType: (payload: string) => void
  setEvaluator: (payload: CaptainsInfo) => void
  setPilot: (payload: SearchEmployeeResponse) => void
  setOptionalCategories: (payload: boolean) => void
  showRequiredFieldErrors: boolean
}

export default function EvaluationInformation({
  flightInfo,
  pilotInfo,
  captainInfo,
  evaluationInfo,
  status,
  flightType,
  flightTypeTitle,
  isManual,
  setFlightInfo,
  setFlightType,
  setEvaluator,
  setPilot,
  setOptionalCategories,
  showRequiredFieldErrors,
}: EvaluationInformationProps) {
  return (
    <>
      {isManual && status.toLocaleLowerCase() !== statuses.SUBMITTED ? (
        <>
          <FlightInformationForm
            flightType={flightType}
            setFlightType={setFlightType}
            flightInfo={flightInfo}
            setFlightInfo={setFlightInfo}
            showRequiredFieldErrors={showRequiredFieldErrors}
            setOptionalCategories={setOptionalCategories}
          />
          <PilotInformationForm
            pilot={pilotInfo}
            setPilot={(pilot: FormattedSearchEmployeeResponse) => {
              setPilot(pilot)
            }}
            showRequiredFieldErrors={showRequiredFieldErrors}
          />
          <EvaluatorInformationForm
            evaluator={captainInfo}
            setEvaluator={(evaluator: FormattedSearchCaptainResponse) => {
              setEvaluator(evaluator)
            }}
          />
        </>
      ) : (
        <>
          <PilotCard
            pilotName={pilotInfo.name}
            pilotPicture={pilotInfo.picture}
            base={pilotInfo.base}
            fleetType={pilotInfo.fleetType}
            dateOfHire={pilotInfo.dateOfHire}
            endDate={pilotInfo.estimatedProbationEndDate}
          />
          <BoxSection title="Flight Information:">
            <Grid variant="4up">
              <GridItem>
                <b>Flight Number</b>
                <p>
                  {flightTypeTitle} - {flightInfo.flightNo}
                </p>
              </GridItem>
              <GridItem>
                <b>Origin Date</b>
                <p>{flightInfo.originDate}</p>
              </GridItem>
              <GridItem>
                <b>Origin City</b>
                <p>{flightInfo.originCity}</p>
              </GridItem>
              <GridItem>
                <b>Destination City</b>
                <p>{flightInfo.destination}</p>
              </GridItem>
            </Grid>
          </BoxSection>
          <BoxSection title="Pilot Information:">
            <Grid variant="4up">
              <GridItem>
                <b>Name</b>
                <p>{pilotInfo.name}</p>
              </GridItem>
              <GridItem>
                <b>Employee No</b>
                <p>{pilotInfo.employeeNo}</p>
              </GridItem>
              <GridItem>
                <b>Base</b>
                <p>{pilotInfo.base}</p>
              </GridItem>
              <GridItem>
                <b>Fleet</b>
                <p>{pilotInfo.fleetType}</p>
              </GridItem>
            </Grid>
          </BoxSection>
          <BoxSection title="Captain / Instructor / Evaluator / LCA Information:">
            <Grid variant="4up">
              <GridItem>
                <b>Name</b>
                <p>{captainInfo.name}</p>
              </GridItem>
              <GridItem>
                <b>Employee No</b>
                <p>{captainInfo.employeeNo}</p>
              </GridItem>
              <GridItem>
                <b>Base</b>
                <p>{captainInfo.base}</p>
              </GridItem>
              <GridItem></GridItem>
            </Grid>
          </BoxSection>
          {status.toLocaleLowerCase() !== statuses.NEW &&
            evaluationInfo.submitted !== null &&
            evaluationInfo.modified !== null && (
              <BoxSection title="Evaluation Information:">
                <Grid variant="4up">
                  <GridItem>
                    <b>Average Rating</b>
                    <p> {evaluationInfo.avg}</p>
                  </GridItem>
                  <GridItem>
                    <b>Created On</b>
                    <p>{evaluationInfo.submitted?.on}</p>
                  </GridItem>
                  <GridItem>
                    <b>Submitted By</b>
                    <p>{evaluationInfo.submitted?.by}</p>
                  </GridItem>
                  <GridItem>
                    <b>Modified On</b>
                    <p>
                      {evaluationInfo.modified?.on} By{" "}
                      {evaluationInfo.modified?.by}
                    </p>
                  </GridItem>
                </Grid>
              </BoxSection>
            )}
        </>
      )}
      <GradingGuidelines />
    </>
  )
}
