import React, { useState, useEffect } from "react"
import { TextareaField } from "atmos-design-system"
import htmlToMarkdown from "@wcj/html-to-markdown"
import { Editor } from "react-draft-wysiwyg"
import { EditorState, ContentState, convertToRaw } from "draft-js"
import draftToHtml from "draftjs-to-html"
import htmlToDraft from "html-to-draftjs"
import { marked } from "marked"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import "./styles.scss"

export const EditorHtml = ({
  content = null,
  error = false,
  onHtml,
  onMarkdown,
}: EditorHtmlProps) => {
  const [init, setInit] = useState(true)
  const [value, setValue] = useState(EditorState.createEmpty())

  const convertHtmlToMarkdown = async (html: string) => {
    return await htmlToMarkdown({
      html,
    })
  }

  const toMarkdown = (content: EditorHtmlState) => {
    const html = draftToHtml(convertToRaw(content.getCurrentContent()))

    onHtml?.(html)

    convertHtmlToMarkdown(html)
      .then((res) => {
        onMarkdown?.(res)
      })
      .catch((err) => {
        console.error(err.message)
      })
  }

  useEffect(() => {
    if (content !== null && init) {
      const res = marked.parse(content)
      const contentBlock = htmlToDraft(res)
      if (contentBlock !== null) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        )
        const editorState = EditorState.createWithContent(contentState)
        setValue(editorState)
        setInit(false)
      }
    }
  }, [content, init])

  return (
    <div className="ebp-opt-ao-c-editor-html">
      <Editor
        editorState={value}
        wrapperClassName="ebp-opt-ao-c-editor-html--wrapper"
        editorClassName="ebp-opt-ao-c-editor-html--editor"
        toolbarClassName="ebp-opt-ao-c-editor-html--toolbar"
        toolbar={{
          options: ["inline", "blockType", "list", "link", "history"],
          inline: {
            inDropdown: false,
            options: ["bold", "italic"],
          },
          list: { inDropdown: false },
          blockType: {
            inDropdown: true,
            options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6"],
          },
        }}
        onEditorStateChange={(content: EditorHtmlState) => {
          setValue(content)
          toMarkdown(content)
        }}
      />
      {error !== false && <TextareaField error={error} />}
    </div>
  )
}
