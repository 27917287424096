import React from "react"
import MainLayout from "src/components/UI/MainLayout"
import CaptainDetails from "src/components/Captains"
import { withRouter } from "react-router-dom"

interface defaultProps {
  match: {
    params: {
      id: string
    }
  }
}

const CaptainEvaluationsView = ({
  match: {
    params: { id },
  },
}: defaultProps) => {
  return (
    <MainLayout context="captainProfileView">
      <CaptainDetails id={id} />
    </MainLayout>
  )
}

export default withRouter(CaptainEvaluationsView)
