import type { ApiRequest } from "src/api/apiInterfaces"

const rulesFormat = (response: Rules[]): RulesData[] => {
  return response.map((responseRule) => {
    return {
      id: responseRule.id,
      role: responseRule.role,
      attribute: responseRule.attribute,
      value: responseRule.value,
    }
  })
}

const RulesApi = (request: ApiRequest, getEndpoint: CallableFunction) => {
  return {
    get: async () =>
      await request
        .get<RulesResponse>(getEndpoint("rules"))
        .then((data) => rulesFormat(data.rules)),

    delete: async (id: string) =>
      await request.delete(getEndpoint("rules", id)),

    post: async (body: { role: string; attribute: string; value: string }) =>
      await request.post<RulesPostResponse>(getEndpoint("rules"), body),
  }
}

export default RulesApi
