import React, { useEffect, useState } from "react"
import { type RouteComponentProps, Link, withRouter } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import {
  Band,
  Logo,
  Alert,
  Icon,
  TextField,
  Button,
  ButtonGroup,
  Modal,
  ModalHeader,
  ModalBody,
} from "atmos-design-system"
import Cookies from "js-cookie"
import CryptoJS from "crypto-js"
import Loader from "../Loader"
import { user } from "src/api"
import profile from "../../../../public/assets/images/user_profile.jpg"
import "./styles.scss"
import type { OAuthAxiosRequestHeaders } from "src/api/apiInterfaces"
import type { UserContext } from "src/api/user/interfaces"
import GlobalAlertContainer from "../GlobalAlertContainer"
import UserSessionModal from "../UserSessionModal"
import type { RoleType } from "src/api/apiTypes"
import { Routes } from "src/utils/routes"
import GoTop from "../GoTop"
import { HELP_MAIL } from "src/utils/constants"

interface UserState {
  user: {
    uid: string
    picture: string
    name: string
    role: string
    roleType: RoleType
    context: UserContext
  }
}

interface LoggedUserState {
  loggedUser: {
    uid: string
    picture: string
    name: string
    role: string
    roleType: RoleType
    context: UserContext
  }
}

interface ImpersonatedState {
  impersonated: boolean
}

interface AlertMessage {
  title: string
  body: string
  dismissible: boolean
}

function Header({ history, match }: RouteComponentProps) {
  const [logoutUser, setLogoutUser] = useState<boolean>(false)
  const [alertMessage, setAlertMessage] = useState<AlertMessage>({
    title: "Error",
    body: "Something went wrong.",
    dismissible: true,
  })
  const [employeeId, setEmployeeId] = useState<string>("")
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [loader, setLoader] = useState<boolean>(false)
  const [displayMenu, setDisplayMenu] = useState<boolean>(false)
  const dispatch = useDispatch()
  const userState = useSelector((state: UserState) => state.user)
  const loggedUser = useSelector((state: LoggedUserState) => state.loggedUser)
  const impersonated = useSelector(
    (state: ImpersonatedState) => state.impersonated
  )

  const helpLink = () => {
    window.location.href = HELP_MAIL
  }

  function toggleMenu() {
    setDisplayMenu((prevState) => !prevState)
  }

  function getSelectedMenu(route: string) {
    return match.url === route ? "selected" : ""
  }

  function cleanImpersonation() {
    setEmployeeId("")
    setLoader(false)
    setDisplayMenu(false)
  }

  function impersonate() {
    if (employeeId.trim() === "") {
      setModalOpen(true)
    } else {
      setLoader(true)

      const accessToken = localStorage.getItem("token") ?? ""
      const authorizationHeader: OAuthAxiosRequestHeaders = {
        Authorization: `Bearer ${accessToken}`,
      }

      user
        .get(authorizationHeader, employeeId)
        .then((res) => {
          dispatch({ type: "impersonated", payload: true })
          dispatch({
            type: "user",
            payload: res,
          })
          const userData = CryptoJS.AES.encrypt(
            JSON.stringify(res),
            process.env["REACT_APP_API_SECRET"]
          )
          localStorage.setItem("impersonated", userData)
          cleanImpersonation()
          history.push(Routes.Home)
        })
        .catch((error) => {
          cleanImpersonation()

          if (error.response !== undefined && error.response.status === 403) {
            setLogoutUser(true)
            setAlertMessage({
              title: "Timeout",
              body: "Session has expired. You will be logged off now.",
              dismissible: false,
            })
          } else {
            setAlertMessage({
              title: "Invalid Employee ID",
              body: "Please provide a valid Employee ID.",
              dismissible: true,
            })
          }

          setModalOpen(true)
        })
    }
  }

  function exitFromImpersonation() {
    setDisplayMenu(false)
    dispatch({ type: "impersonated", payload: false })
    dispatch({
      type: "user",
      payload: loggedUser,
    })
    localStorage.removeItem("impersonated")
    history.push(Routes.Home)
  }

  useEffect(() => {
    if (logoutUser) {
      setTimeout(() => {
        history.push(Routes.Logout)
      }, 5000)
    }
  }, [logoutUser, history])

  useEffect(() => {
    const token = localStorage.getItem("token") ?? null
    const path = process.env["AUTH_SIGN_PATH"] ?? null

    if (path !== null && match.url !== path && token === null) {
      if (window.location.pathname !== path) {
        sessionStorage.setItem("redirectAfterLogin", window.location.href)
        history.push(path)
      }
    }
  }, [history, match])

  useEffect(() => {
    const cypher = Cookies.get("APP_SECURE") ?? null
    const path = process.env["AUTH_SIGN_PATH"] ?? "/"

    if (cypher !== null) {
      try {
        const bytes = CryptoJS.AES.decrypt(
          cypher,
          process.env["REACT_APP_API_SECRET"]
        )
        const original = bytes.toString(CryptoJS.enc.Utf8)
        const user = localStorage.getItem("user") ?? null
        const userImpersonated = localStorage.getItem("impersonated") ?? null

        if (user !== null && original === process.env["REACT_APP_API_KEY"]) {
          const userHash = CryptoJS.AES.decrypt(
            user,
            process.env["REACT_APP_API_SECRET"]
          )

          const userContext = JSON.parse(userHash.toString(CryptoJS.enc.Utf8))
          dispatch({ type: "loggedUser", payload: userContext })

          if (userImpersonated !== null) {
            const userImpersonatedHash = CryptoJS.AES.decrypt(
              userImpersonated,
              process.env["REACT_APP_API_SECRET"]
            )
            const userImpersonatedContext = JSON.parse(
              userImpersonatedHash.toString(CryptoJS.enc.Utf8)
            )
            dispatch({ type: "impersonated", payload: true })
            dispatch({ type: "user", payload: userImpersonatedContext })
          } else {
            dispatch({ type: "user", payload: userContext })
          }
        } else {
          if (window.location.pathname !== path) {
            sessionStorage.setItem("redirectAfterLogin", window.location.href)
            history.push(path)
          }
        }
      } catch {
        setLogoutUser(true)
      }
    } else {
      if (window.location.pathname !== path) {
        sessionStorage.setItem("redirectAfterLogin", window.location.href)
        history.push(path)
      }
    }
  }, [history, dispatch])

  return (
    <>
      <Band variant="rhapsodyblue">
        <div className="ebp-foppt-l-header">
          <div className="ebp-foppt-l-header__logo">
            <div className="ebp-foppt-l-header__logo--icons">
              <Logo href="/" title="United Airlines homepage" />
              <Link to="/" className="ebp-foppt-l-header__logo--icons__home">
                <Icon name="home" theme="inverted" />
              </Link>
            </div>
          </div>
          <div className="ebp-foppt-l-header__menu">
            <div className="ebp-foppt-l-items--right ebp-foppt-c-user">
              {loggedUser.context.impersonation && (
                <div className="ebp-foppt-c-user__item ebp-foppt-c-user--impersonator">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault()
                    }}
                  >
                    <Icon name="traveler" />
                    <TextField
                      label=""
                      type="text"
                      placeholder="Employee ID"
                      value={employeeId}
                      required={false}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setEmployeeId(e.target.value)
                      }}
                    />
                    <button
                      type="submit"
                      className="ebp-foppt-u-cursor-hand"
                      onClick={impersonate}
                    >
                      <Icon name="arrow-right" />
                    </button>
                  </form>
                </div>
              )}
              {impersonated && (
                <>
                  <div className="ebp-foppt-c-user__item ebp-foppt-c-user--impersonator">
                    <span>
                      <img src={loggedUser.picture ?? profile} />
                      <span>{loggedUser.name} (Admin)</span>
                      <Icon
                        name="swap"
                        className="ebp-foppt-c-user__item--icon-swap"
                      />
                    </span>
                  </div>
                  <div className="ebp-foppt-c-user__item ebp-foppt-c-user--impersonator-alt">
                    <span>
                      <Icon
                        name="swap"
                        className="ebp-foppt-c-user__item--icon-swap"
                      />
                    </span>
                  </div>
                </>
              )}
              <div className="ebp-foppt-help-icon">
                <a className="ebp-foppt-u-cursor-hand" onClick={helpLink}>
                  <Icon name={"question"} />
                </a>
              </div>
              <div className="ebp-foppt-c-user__item">
                <a className="ebp-foppt-u-cursor-hand" onClick={toggleMenu}>
                  <img src={userState.picture ?? profile} />
                  <span>{userState.name}</span>
                  <Icon name={displayMenu ? "caret-up" : "caret-down"} />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="ebp-foppt-c-user__menu-toggle">
          {displayMenu && (
            <div className="ebp-foppt-c-user__menu atm-c-band--rhapsody-blue">
              <ul>
                {userState.context.flightListView && (
                  <li className={getSelectedMenu(Routes.Flights)}>
                    <Link to={Routes.Flights}>Flights</Link>
                  </li>
                )}
                {userState.context.pilotsListView && (
                  <li className={getSelectedMenu(Routes.Pilots)}>
                    <Link to={Routes.Pilots}>Admin</Link>
                  </li>
                )}
                {userState.context.captainsListView && (
                  <li className={getSelectedMenu(Routes.Captains)}>
                    <Link to={Routes.Captains}>Captains</Link>
                  </li>
                )}
              </ul>
              <ul>
                {userState.context.systemTablesRoles && (
                  <li className={getSelectedMenu(Routes.Roles)}>
                    <Link to={Routes.Roles}>User Roles</Link>
                  </li>
                )}
                {userState.context.systemTablesMail && (
                  <li className={getSelectedMenu(Routes.MailingList)}>
                    <Link to={Routes.MailingList}>Mailing List</Link>
                  </li>
                )}
                {userState.context.systemTablesUserBases && (
                  <li className={getSelectedMenu(Routes.UserBases)}>
                    <Link to={Routes.UserBases}>User Bases</Link>
                  </li>
                )}
                {userState.context.systemTablesSettings && (
                  <li className={getSelectedMenu(Routes.Settings)}>
                    <Link to={Routes.Settings}>Settings</Link>
                  </li>
                )}
                {impersonated && (
                  <li>
                    <a
                      className="ebp-foppt-u-cursor-hand"
                      onClick={exitFromImpersonation}
                    >
                      Exit from Impersonation
                    </a>
                  </li>
                )}
              </ul>
              <ul>
                <li>
                  <a onClick={helpLink}>Help</a>
                </li>
              </ul>
              <ul>
                <li>
                  <Link to={Routes.Logout}>Logout</Link>
                </li>
              </ul>
            </div>
          )}
        </div>
      </Band>
      <GlobalAlertContainer />
      <Modal
        dismissible={alertMessage.dismissible}
        isActive={modalOpen}
        onClose={() => {
          setModalOpen(false)
        }}
        focusLockProps={{
          persistentFocus: true,
          returnFocus: true,
        }}
        ariaLabelledBy="modal-heading-1"
        ariaDescribedBy="modal-description-1"
      >
        <ModalHeader></ModalHeader>
        <ModalBody>
          <div>
            <Alert iconName="error" title={alertMessage.title} variant="error">
              <p>{alertMessage.body}</p>
            </Alert>
            {alertMessage.dismissible && (
              <div className="ebp-foppt-l-items--right">
                <ButtonGroup>
                  <Button
                    text="Ok"
                    onClick={() => {
                      setModalOpen(false)
                    }}
                  />
                </ButtonGroup>
              </div>
            )}
          </div>
        </ModalBody>
      </Modal>
      <UserSessionModal />
      <GoTop scrollTop={200} />
      <Loader display={loader} />
    </>
  )
}

export default withRouter(Header)
