import React, { useEffect, useRef, useState } from "react"
import { TextField, LoadingIndicator, Icon } from "atmos-design-system"
import "./styles.scss"
import { REGEX } from "src/utils/constants"

interface SearchProps<T> {
  onSelect: (item: T) => void
  render: (item: T) => JSX.Element
  validQuery?: RegExp
  api: (params: URLSearchParams) => Promise<T[]>
  required?: TextFieldError
  label?: string
  requiredLabel?: string
  labelAfter?: React.ReactNode
  placeholder?: string
}

interface SearchItem {
  value: string
}

function Search<T extends SearchItem>({
  onSelect,
  validQuery = REGEX.simpleUser,
  render,
  api,
  required = null,
  placeholder = "Search by Name or Employee ID",
  ...props
}: SearchProps<T>) {
  const ref = useRef<TextFieldType>()
  const [hasError, setHasError] = useState<TextFieldError>(null)
  const [inputResult, setInputResult] = useState<string>("")
  const [searchResults, setSearchResults] = useState<T[]>([])
  const [loader, setLoader] = useState<boolean>(false)

  function getSearchResults(value: string) {
    setLoader(true)

    if (!loader) {
      const params = new URLSearchParams()
      params.append("query", value)
      params.append("pageSize", String(10))
      api(params)
        .then((response) => {
          setLoader(false)
          const currentValue = ref.current?.props.value ?? ""

          if (currentValue !== "") {
            setSearchResults(response)

            if (value !== currentValue && validQuery.test(currentValue)) {
              getSearchResults(currentValue)
            }
          }
        })
        .catch(() => {
          setLoader(false)
          setHasError({
            message: "There was an error, try again later.",
          })
        })
    }
  }

  function cleanUp() {
    setHasError(null)
    setLoader(false)
    setInputResult("")
    setSearchResults([])
  }

  function onSelectHandler(item: T) {
    onSelect(item)
    cleanUp()
  }

  function highlightSearch(value: string) {
    const cvalue = value.toLocaleLowerCase()
    const input = inputResult.toLocaleLowerCase()
    const index = cvalue.indexOf(input)
    let highlight = <span>{value}</span>

    if (index === 0) {
      const start = value.substring(0, inputResult.length)
      const end = value.substring(inputResult.length, cvalue.length)
      highlight = (
        <span>
          <b>{start}</b>
          {end}
        </span>
      )
    } else if (index > 0) {
      const pos = index + inputResult.length
      const start = value.substring(0, index)
      const middle = value.substring(index, pos)
      const end = value.substring(pos, value.length)

      highlight = (
        <span>
          {start}
          <b>{middle}</b>
          {end}
        </span>
      )
    }

    return highlight
  }

  const renderResults = () => {
    const res = searchResults.filter((item: T) =>
      item.value.toLocaleLowerCase().includes(inputResult.toLocaleLowerCase())
    )

    if (res.length === 0) {
      return (
        <li key={0}>
          <a>No Results</a>
        </li>
      )
    }

    return res.map((item, i) => {
      return (
        <li key={i}>
          <a
            onClick={() => {
              onSelectHandler(item)
            }}
          >
            {render({ ...item, value: highlightSearch(item.value) })}
          </a>
        </li>
      )
    })
  }

  useEffect(() => {
    setHasError(required)
  }, [required])

  return (
    <div className="ebp-foppt-c-autocomplete">
      <TextField
        type="text"
        ref={ref}
        placeholder={placeholder}
        value={inputResult}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setHasError(null)
          setInputResult(e.target.value)
        }}
        onKeyUp={(e: React.ChangeEvent<HTMLInputElement>) => {
          const value = e.target.value

          if (value.length === 0) {
            cleanUp()
          } else if (validQuery.test(value)) {
            getSearchResults(value)
          }
        }}
        error={hasError}
        {...props}
      />
      <div className="ebp-foppt-c-autocomplete__loader">
        {loader && <LoadingIndicator size={30} />}
        {!loader && searchResults.length > 0 && (
          <a
            className="ebp-foppt-u-cursor-hand ebp-foppt-c-autocomplete__close"
            onClick={cleanUp}
          >
            <Icon name="close" />
          </a>
        )}
      </div>
      {searchResults.length > 0 && (
        <ul className="ebp-foppt-c-autocomplete__list">{renderResults()}</ul>
      )}
    </div>
  )
}

export default Search
