import React from "react"
import { BoxSection, Grid, GridItem } from "atmos-design-system"
import { pilot } from "src/api"
import Search from "src/components/UI/Search"
import { EmployeePicture } from "src/components/Employee/Picture"
import { useSelector } from "react-redux"
import { type UserState } from "src/api/user/interfaces"
import { REGEX } from "src/utils/constants"

interface PilotInformationFormProps {
  pilot: SearchEmployeeResponse | null
  setPilot: (item: FormattedSearchEmployeeResponse) => void
  showRequiredFieldErrors: boolean
}

const searchPilots = async (params: URLSearchParams) => {
  params.append("status", "Active")
  params.append("status", "LeaveOfAbsence")

  return await pilot.searchPilots(params)
}

function renderSearchResults(item: FormattedSearchEmployeeResponse) {
  return (
    <>
      <EmployeePicture picture={item.picture} size="xs-small" />
      {item.value}
    </>
  )
}

export default function PilotInformationForm({
  pilot,
  setPilot,
  showRequiredFieldErrors,
}: PilotInformationFormProps) {
  const user = useSelector((state: UserState) => state.user)
  const context = user.context

  const requiredMessage =
    showRequiredFieldErrors && pilot === null
      ? context.searchRequireCompleteID
        ? { message: "Complete Employee ID is Required" }
        : { message: "Required" }
      : null

  return (
    <BoxSection title="Pilot Information:">
      <div
        className={`ebp-foppt-c-employee-search ebp-foppt-c-employee-search__inline`}
      >
        <Search<FormattedSearchEmployeeResponse>
          onSelect={setPilot}
          api={searchPilots}
          render={renderSearchResults}
          label="Pilot"
          requiredLabel="(Required)"
          required={requiredMessage}
          {...(context.searchRequireCompleteID && {
            validQuery: REGEX.userID,
            placeholder: "Search by Employee ID",
          })}
        />
      </div>
      {pilot?.employeeNo !== undefined && (
        <Grid variant="4up">
          <GridItem>
            <b>Name</b>
            <p>{pilot.name}</p>
          </GridItem>
          <GridItem>
            <b>Employee No</b>
            <p>{pilot.employeeNo}</p>
          </GridItem>
          <GridItem>
            <b>Base</b>
            <p>{pilot.base}</p>
          </GridItem>
          <GridItem>
            <b>Fleet</b>
            <p>{pilot.fleetType}</p>
          </GridItem>
        </Grid>
      )}
    </BoxSection>
  )
}
