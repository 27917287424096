import React from "react"
import he from "he"
import { CheckboxField, Grid, GridItem, Button } from "atmos-design-system"

interface EvaluationActionsProps {
  canSubmit: boolean
  fom: string[]
  submitBtn: boolean
  handleClickFOM: CallableFunction
  saveDraftForm: CallableFunction
  validateForm: CallableFunction
  goBack: CallableFunction
}

function decodeHtmlEntities(value: string): string {
  return value !== undefined && value !== null ? he.decode(value) : ""
}

export default function EvaluationActions({
  canSubmit,
  fom,
  submitBtn,
  handleClickFOM,
  saveDraftForm,
  validateForm,
  goBack,
}: EvaluationActionsProps) {
  return canSubmit ? (
    <>
      <div className="ebp-foppt-c-evaluation-form__fom">
        <div>
          <CheckboxField
            label=""
            items={[
              {
                name: "checkbox-example",
              },
            ]}
            onChange={handleClickFOM}
          />
        </div>
        <div>
          {fom.map((line: string, lineKey: number) => (
            <div key={lineKey}>
              <span>{decodeHtmlEntities(line)}</span>
            </div>
          ))}
        </div>
      </div>
      <div className="ebp-foppt-l-grid__buttons">
        <Grid variant="3up">
          <GridItem>
            <Button hideText={false} text="Back" onClick={goBack} />
          </GridItem>
          <GridItem>
            <Button
              hideText={false}
              text="Save as a Draft"
              onClick={saveDraftForm}
            />
          </GridItem>
          <GridItem>
            <Button
              hideText={false}
              text="Submit"
              variant="primary"
              disabled={submitBtn}
              onClick={validateForm}
            />
          </GridItem>
        </Grid>
      </div>
    </>
  ) : null
}
