import React from "react"
import { Tabs, Tab } from "atmos-design-system"
import MainLayout from "src/components/UI/MainLayout"
import CompleteEvaluationsList from "src/components/Flights/CompleteEvaluations"
import Pairings from "../../components/Pairings"
import "./styles.scss"
import FlightsDisclaimer from "src/components/FlightsDisclaimer/FlightsDisclaimer"

const FlightsView = () => {
  return (
    <MainLayout context="flightListView">
      <FlightsDisclaimer />
      <Tabs variant="small" className="ebp-foppt-tabs">
        <Tab title={"Flights"}>
          <Pairings />
        </Tab>
        <Tab title={"Completed Evaluations"}>
          <CompleteEvaluationsList />
        </Tab>
      </Tabs>
    </MainLayout>
  )
}

export default FlightsView
