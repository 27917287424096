import React from "react"
import {
  Button,
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  TextLink,
  TextareaField,
} from "atmos-design-system"
import { useSelector } from "react-redux"
import type { UserState } from "src/api/user/interfaces"
import { renderMultilineComments } from "./util"

interface ManagementCommentsProps {
  mngtComments: ManagementComments[]
  isSubmitted: boolean
  isMngtCommentsEmpty: boolean
  setMngtCommentsInput: (value: string) => void
  mngtCommentsInput: string
  updateModalCommentsOpen: (state: boolean) => void
  validateForm: () => void
}

export default function ManagementComments({
  isSubmitted,
  mngtComments,
  isMngtCommentsEmpty,
  setMngtCommentsInput,
  mngtCommentsInput,
  updateModalCommentsOpen,
  validateForm,
}: ManagementCommentsProps) {
  const user = useSelector((state: UserState) => state.user)
  const context = user.context

  return context.evaluationFormShowMngtComments && isSubmitted ? (
    <>
      <div className="atm-c-box-section__header">
        <h4 className="atm-c-box-section__title">Management Comments:</h4>
      </div>
      {mngtComments.length > 0 && (
        <div className="atm-c-responsive-key-value-table">
          <Table>
            <TableHeader>
              <TableRow>
                <TableCell alignment="left" tagName="th">
                  DATE
                </TableCell>
                <TableCell alignment="left" tagName="th">
                  REVIEWER
                </TableCell>
                <TableCell alignment="left" tagName="th">
                  COMMENT
                </TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {mngtComments.slice(0, 3).map((comment, key: number) => {
                return (
                  <TableRow key={key}>
                    <TableCell
                      alignment="left"
                      data-label="Table Column 1"
                      tagName="td"
                    >
                      {comment.date}
                    </TableCell>
                    <TableCell
                      alignment="left"
                      data-label="Table Column 2"
                      tagName="td"
                    >
                      {comment.reviewer}
                    </TableCell>
                    <TableCell
                      alignment="left"
                      data-label="Table Column 3"
                      tagName="td"
                    >
                      {renderMultilineComments(comment.comments)}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </div>
      )}
      {mngtComments.length > 3 && (
        <div className="ebp-foppt-l-items--align-center ebp-foppt-l-padding">
          <TextLink
            href="#avoid"
            onClick={(e: React.ChangeEvent<HTMLAnchorElement>) => {
              e.preventDefault()
              updateModalCommentsOpen(true)
            }}
          >
            View all comments
          </TextLink>
        </div>
      )}
      {context.evaluationFormQuickEdit && (
        <>
          <TextareaField
            id="input-mngt-comments"
            label="Management Comments"
            maxLength="750"
            className={isMngtCommentsEmpty ? "ebp-foppt-u-input-error" : ""}
            error={
              isMngtCommentsEmpty && {
                message: "Management comments are required.",
              }
            }
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
              const target = e.target
              setMngtCommentsInput(target.value)
            }}
            value={mngtCommentsInput}
          />
          <div className="ebp-foppt-l-items--right">
            <Button
              text="Save"
              variant="primary"
              size="large"
              onClick={validateForm}
            />
          </div>
        </>
      )}
    </>
  ) : null
}
