import React, { useEffect } from "react"
import Loader from "../../components/UI/Loader"
import Cookies from "js-cookie"

function Logout() {
  useEffect(() => {
    const logoutPage = process.env["AUTH_LOGOUT"] ?? ""
    localStorage.removeItem("token")
    localStorage.removeItem("user")
    localStorage.removeItem("impersonated")
    localStorage.removeItem("captainNotice")
    Cookies.remove("APP_SECURE")
    window.location.href = logoutPage
  }, [])

  return <Loader display />
}

export default Logout
