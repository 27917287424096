import React, { useEffect, useRef } from "react"
import "./styles.scss"

export const IntersectionObserverElement = ({
  callback,
}: IntersectionObserverElementProps) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        callback()
      }
    })
    if (ref?.current !== null) observer.observe(ref.current)
    return () => {
      observer.disconnect()
    }
  }, [callback])

  return (
    <div className="ebp-foppt-intersection-observer-element" ref={ref}></div>
  )
}
