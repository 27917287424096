import CustomTable from "src/components/UI/Table/Table"
import React, { useEffect } from "react"
import { type RouteComponentProps, withRouter } from "react-router-dom"
import { InlineCheckbox, Icon } from "atmos-design-system"
import useCompleteEvaluation from "./hooks/useCompleteEvaluation"
import { useSelector } from "react-redux"
import profile from "../../../public/assets/images/user_profile.jpg"
import { Routes } from "src/utils/routes"
import GoTop from "../UI/GoTop"
import { IntersectionObserverElement } from "../UI/IntersectionObserverElement"

function CompleteEvaluationsList({
  history,
}: RouteComponentProps): JSX.Element {
  const columns: Array<Column<CompletedEvaluations>> = [
    {
      field: "flightNo",
      title: "Flight No",
    },
    {
      field: "originCity",
      title: "Flight",
      sort: false,
      render: (row: CompletedEvaluations) =>
        `${row.originCity} — ${row.destination}`,
    },
    {
      field: "originDate",
      title: "Date",
      sort: true,
    },
    {
      field: "pilotId",
      title: "Pilot",
      sort: false,
      render: (row: CompletedEvaluations) => (
        <>
          <div className="ebp-foppt-image-cropper-tbl">
            <img src={row.picture ?? profile} alt="pilot picture" />
          </div>
          <p className="ebp-foppt-no-margin">{row.pilotName}</p>
        </>
      ),
    },
    {
      field: "evaluationDate",
      title: "Evaluation Date",
      sort: true,
    },
    {
      field: "evaluationId",
      title: "",
      render: (row: CompletedEvaluations) => {
        return (
          <a
            onClick={(e) => {
              e.stopPropagation()
              history.push(`${Routes.Evaluation}/${row.evaluationId}`)
            }}
          >
            <Icon name="caret-right" />
          </a>
        )
      },
    },
  ]
  const {
    states,
    toggleArchived,
    changeSorting,
    loadEvaluations,
    setCaptainId,
  } = useCompleteEvaluation()

  const captainId = useSelector((state: StateUserId) => {
    return state.user.uid
  })

  useEffect(() => {
    if (captainId !== states.captainId) {
      setCaptainId(captainId)
    }
  }, [captainId, setCaptainId, states.captainId])

  const onOptionsChange = (
    options: OptionsChangeFunctionParams<CompletedEvaluations>
  ) => {
    if (options.type === "sort")
      changeSorting({
        sortColumn: options.column,
        sortDirection: options.direction,
      })
  }

  const isIntersecting = () => {
    if (!states.isFetching && !states.lastCall) loadEvaluations()
  }

  return (
    <>
      <div className="ebp-foppt-l-container__archiveBtn">
        <InlineCheckbox
          label="Show older Evaluations"
          name="inlinecheckbox"
          checked={states.archived}
          onChange={toggleArchived}
        />
      </div>

      <CustomTable<CompletedEvaluations>
        columns={columns}
        data={states.data}
        options={{
          sortColumn: states.sortColumn,
          sortDirection: states.sortDirection,
        }}
        loading={states.isFetching}
        tableType="completed evaluations"
        onOptionsChange={onOptionsChange}
        onRowClick={(row) => {
          history.push(`${Routes.Evaluation}/${row.evaluationId}`)
        }}
      />
      <IntersectionObserverElement callback={isIntersecting} />
      <GoTop scrollTop={200} />
    </>
  )
}

export default withRouter(CompleteEvaluationsList)
