import React from "react"
import { useSelector } from "react-redux"
import { Icon, TextareaField } from "atmos-design-system"
import { renderMultilineComments } from "./util"
import type { UserState } from "src/api/user/interfaces"

interface CommentsProps {
  editionMode: boolean
  canSubmit: boolean
  isSubmitted: boolean
  updateSafeSave: (state: boolean) => void
  setComment: (value: string) => void
  value: string
  captainRedactedComments: StringNull
  isCaptainCommentsRedacted: boolean
  isCaptainCommentsApproved: boolean
  setRedacted: CallableFunction
  setApproval: CallableFunction
  setRedactedComments: CallableFunction
  cancelRedactedComments: CallableFunction
}

export default function Comments({
  editionMode,
  canSubmit,
  isSubmitted,
  updateSafeSave,
  setComment,
  value,
  captainRedactedComments,
  isCaptainCommentsRedacted,
  isCaptainCommentsApproved,
  setRedacted,
  setApproval,
  setRedactedComments,
  cancelRedactedComments,
}: CommentsProps) {
  const user = useSelector((state: UserState) => state.user)
  const context = user.context

  return (
    <>
      <div className="atm-c-box-section__header ebp-foppt-c-evaluation-form-section__header">
        <h4 className="atm-c-box-section__title">Comments (Optional):</h4>
      </div>
      <div className="ebp-foppt-c-evaluation-form__comments">
        <div className="ebp-foppt-c-evaluation-form__grading--buttons">
          {context.evaluationCommentsRedact &&
            editionMode &&
            isSubmitted &&
            !isCaptainCommentsRedacted && (
              <>
                <a
                  className={"ebp-foppt-u-cursor-hand"}
                  onClick={() => {
                    setRedacted(!isCaptainCommentsRedacted)
                  }}
                >
                  <Icon name="edit" />
                  <span>Edit</span>
                </a>
                {value !== "" && value !== null && (
                  <a
                    className={`ebp-foppt-u-cursor-hand ${
                      isCaptainCommentsApproved ? "approved" : ""
                    }`}
                    onClick={() => {
                      setApproval(!isCaptainCommentsApproved)
                    }}
                  >
                    <Icon
                      name={`thumbs-${
                        isCaptainCommentsApproved ? "down" : "up"
                      }`}
                    />
                    <span>
                      {isCaptainCommentsApproved ? "Unapprove" : "Approve"}
                    </span>
                  </a>
                )}
              </>
            )}
        </div>
        {canSubmit || editionMode ? (
          <>
            <TextareaField
              label=""
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                updateSafeSave(true)
                setComment(e.target.value)
              }}
              defaultValue={value}
              disabled={!canSubmit}
              placeholder={"No comments"}
            />
          </>
        ) : (
          <>
            <div className="ebp-foppt-c-evaluation-form__grading--comments-approval">
              <div
                className={`ebp-foppt-c-evaluation-form__grading--comments-approval__label ebp-foppt-c-evaluation-form__grading--comments-approval__label-${
                  isCaptainCommentsApproved ? "approved" : "unapproved"
                }`}
              >
                <Icon
                  name={`thumbs-${isCaptainCommentsApproved ? "up" : "down"}`}
                />
                <span>
                  {isCaptainCommentsApproved ? "Approved" : "Unapproved"}
                </span>
              </div>
            </div>

            {value === "" || value === null
              ? "No Comments"
              : renderMultilineComments(value)}
          </>
        )}

        {isCaptainCommentsRedacted &&
          context.evaluationCommentsRedact &&
          isSubmitted && (
            <>
              {editionMode ? (
                <div className="ebp-foppt-c-evaluation-form__comments">
                  <div className="ebp-foppt-c-evaluation-form__grading--comments-controls">
                    <label>Redacted comments:</label>
                    <a
                      className="ebp-foppt-u-cursor-hand"
                      onClick={() => {
                        cancelRedactedComments()
                      }}
                    >
                      <Icon name="x" />
                    </a>
                  </div>
                  <TextareaField
                    label=""
                    maxLength="750"
                    placeholder="Mandatory"
                    required
                    value={captainRedactedComments ?? value}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                      updateSafeSave(true)
                      setRedactedComments(e.target.value)
                    }}
                  />
                </div>
              ) : (
                <div className="ebp-foppt-c-evaluation-form__grading--comments-redacted">
                  <span>(Redacted Comments)</span>
                  <div className="ebp-foppt-c-evaluation-form__grading--comments">
                    {captainRedactedComments === "" ||
                    captainRedactedComments === null
                      ? "No Comments"
                      : renderMultilineComments(captainRedactedComments)}
                  </div>
                </div>
              )}
            </>
          )}
      </div>
    </>
  )
}
