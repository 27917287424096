import React, { useState, useEffect, useCallback, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { type RouteComponentProps, withRouter, Prompt } from "react-router-dom"
import {
  Button,
  Modal,
  ModalHeader,
  Heading,
  ModalBody,
  ButtonGroup,
  Alert,
  LoadingIndicator,
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
} from "atmos-design-system"
import { SIMULATION_CITY, statuses } from "./constants"
import useNewEvaluationForm from "./hooks/useNewEvaluationForm"
import useNewEvaluationFormControls from "./hooks/useNewEvaluationFormControls"
import { evaluations } from "../../../api"
import "./styles.scss"
import type { ErrorResponse } from "../../../api/apiInterfaces"
import type { UserState } from "src/api/user/interfaces"
import { Routes } from "src/utils/routes"
import EvaluationActions from "./actions"
import GradingForm from "./gradingForm"
import { decodeHtmlEntities, renderMultilineComments } from "./util"
import EvaluationInformation from "./evaluationInformation"
import QuickEditActions from "./quickEditActions"
import ManagementComments from "./managementComments"
import Comments from "./comments"

interface EvaluationFormProps extends RouteComponentProps {
  quickEdit: boolean
  id: string
}

function EvaluationForm({
  quickEdit = false,
  id = "0",
  history,
}: EvaluationFormProps) {
  const {
    states,
    toggleSubmit,
    updateLoader,
    updateModalOpen,
    updateModalSaveOpen,
    updateModalCommentsOpen,
    updateModalErrorOpen,
    updateModalBackOpen,
    updateModalSimulatorOpen,
    updateSafeSave,
    updateSaveAction,
    updateEditionMode,
    updateIsMngtCommentsEmpty,
    updateCanSubmit,
    updateFailedFetchingOnQuickEdit,
    updateShowRequiredFieldErrors,
    updateIsSubmitting,
  } = useNewEvaluationFormControls()
  const {
    form,
    formCopy,
    fillData,
    copyData,
    setRating,
    setOptionalCategories,
    setSubComments,
    setSubcategoryRedactedComments,
    setSubcategoryRedacted,
    setSubcategoryApproval,
    cancelSubcategoryRedactedComments,
    setRedactedComments,
    setRedacted,
    setApproval,
    cancelRedactedComments,
    setComment,
    saveForm,
    setFlightInfo,
    setFlightType,
    setEvaluator,
    setPilot,
    setEvaluationId,
    toggleChevron,
    openCategories,
  } = useNewEvaluationForm()
  const [mngtComments, setMngtComments] = useState<ManagementComments[]>([])
  const [mngtCommentsInput, setMngtCommentsInput] = useState<string>("")
  const [message, setMessage] = useState("")
  const [alert, setAlert] = useState("none")
  const dispatch = useDispatch()
  const user = useSelector((state: UserState) => state.user)
  const { specialFlightTypes } = user.evaluationSettings
  const context = user.context
  const isSubmitted = form.status.toLowerCase() === statuses.SUBMITTED

  function transformGrading(grading: Category[]) {
    const data: GradingValues[] = []
    grading.forEach((category) => {
      category.subcategories.forEach((subcategory) => {
        const item = {
          id: subcategory.id,
          comments: subcategory.comments,
          value: subcategory.value,
        }

        data.push(item)
      })
    })

    return data
  }

  function transformGradingEdit(grading: Category[]) {
    const data: GradingValues[] = []
    grading.forEach((category) => {
      category.subcategories.forEach((subcategory) => {
        const item = {
          id: subcategory.id,
          value: subcategory.value,
          comments: subcategory.isRedacted
            ? subcategory.redactedComments ?? ""
            : null,
          isApproved: subcategory.isApproved,
        }

        data.push(item)
      })
    })

    return data
  }

  function validateForm() {
    if (!states.editionMode) {
      saveEvaluation()
    } else {
      openCategories(specialFlightTypes.includes(form.flightType))
      saveForm()
      updateSaveAction(true)
    }
  }

  const validateFlightInfo = useCallback(() => {
    const { destination, originCity, originDate, flightNo } = form.flightInfo
    const validFlightNo = user.evaluationSettings.specialFlightTypes.includes(
      form.flightType
    )
      ? flightNo.length === 6
      : flightNo.length > 0
    if (
      !validFlightNo ||
      destination.length !== 3 ||
      originCity.length !== 3 ||
      originDate === "" ||
      originDate === null ||
      form.flightType === "-1"
    ) {
      window.scrollTo(0, 0)
      dispatch({
        type: "alert",
        payload: {
          id: "EvaluationForm__incompleteFlightInformation",
          display: true,
          message: "Complete the Flight information to save the evaluation",
          type: "warning",
        },
      })
      return false
    }

    if (form.pilotInfo === null) {
      window.scrollTo(0, 0)
      dispatch({
        type: "alert",
        payload: {
          id: "EvaluationForm__incompletePilotInformation",
          display: true,
          message: "Complete the Pilot information to save the evaluation",
          type: "warning",
        },
      })
      return false
    }

    return true
  }, [
    dispatch,
    form.flightInfo,
    form.flightType,
    form.pilotInfo,
    user.evaluationSettings.specialFlightTypes,
  ])

  const parseErrorMessageFromResponse = (
    response: ErrorResponse["response"]
  ): string => {
    const errorMessages =
      response.data?.errors?.responseError !== undefined
        ? response.data.errors.responseError
        : []
    return errorMessages.length > 0
      ? errorMessages[0]
      : "There was an issue when processing your request"
  }

  const scrollToTopAndShowErrorAlert = useCallback(
    (message = "Something went wrong, try again later.") => {
      window.scrollTo(0, 0)
      dispatch({
        type: "alert",
        payload: {
          id: "EvaluationForm__somethingWentWrong",
          display: true,
          message,
          type: "error",
        },
      })
    },
    [dispatch]
  )

  async function saveDraftForm() {
    if (form.isManual && !validateFlightInfo()) {
      updateShowRequiredFieldErrors(true)
      return
    }
    if (states.safeSave) {
      dispatch({ type: "loader" })

      const body = {
        evaluationId: form.evaluationId,
        captainComments: form.comments,
        gradings: transformGrading(form.gradings),
        isDraft: true,
        flightInfo: {
          flightNumber: form.flightInfo.flightNo,
          scheduledTime: form.flightInfo.originDate,
          departure: form.flightInfo.originCity.toUpperCase(),
          arrival: form.flightInfo.destination.toUpperCase(),
        },
        captainId: form.captainInfo.employeeNo,
        pilotId: form.pilotInfo.employeeNo,
        flightType: form.flightType,
      }

      evaluations
        .save(form.evaluationId, body)
        .then((response) => {
          setMessage("Evaluation was saved succesfully!")
          setAlert("Success")
          updateModalSaveOpen(true)
          updateSafeSave(false)
          setEvaluationId(response.evaluation.evaluationId)
          window.history.replaceState(
            {},
            "",
            `${Routes.Evaluation}/${response.evaluation.evaluationId}`
          )
        })
        .catch((error: ErrorResponse) => {
          scrollToTopAndShowErrorAlert(
            parseErrorMessageFromResponse(error.response)
          )
        })
        .finally(() => {
          dispatch({ type: "loader" })
        })
    } else {
      setMessage("Please make any change before saving.")
      setAlert("Warning")
      updateModalSaveOpen(true)
    }
  }

  function handleClickFOM() {
    toggleSubmit()
  }

  async function submitEvaluation() {
    updateModalOpen(false)
    updateIsSubmitting(true)
    dispatch({ type: "loader" })

    const body = {
      evaluationId: form.evaluationId,
      captainComments: form.comments,
      gradings: transformGrading(form.gradings),
      isDraft: false,
      flightInfo: {
        flightNumber: form.flightInfo.flightNo,
        scheduledTime: form.flightInfo.originDate,
        departure: form.flightInfo.originCity.toUpperCase(),
        arrival: form.flightInfo.destination.toUpperCase(),
      },
      captainId: form.captainInfo.employeeNo,
      pilotId: form.pilotInfo.employeeNo,
      flightType: form.flightType,
    }

    evaluations
      .save(form.evaluationId, body)
      .then(() => {
        dispatch({
          type: "alert",
          payload: {
            id: "EvaluationForm__savedSuccess",
            display: true,
            message: "Evaluation was saved successfully!",
            type: "success",
          },
        })
        history.push(Routes.Flights)
      })
      .catch((error: ErrorResponse) => {
        scrollToTopAndShowErrorAlert(
          parseErrorMessageFromResponse(error.response)
        )
      })
      .finally(() => {
        updateIsSubmitting(false)
        dispatch({ type: "loader" })
      })
  }

  function cancelEdit() {
    if (states.safeSave) {
      updateModalBackOpen(true)
    } else {
      updateEditionMode(false)
      fillData(JSON.parse(JSON.stringify(formCopy)))
    }
  }

  function goBack() {
    if (states.safeSave) {
      updateModalBackOpen(true)
    } else {
      history.push(Routes.Flights)
    }
  }

  function leaveView() {
    updateModalBackOpen(false)

    if (quickEdit) {
      updateSafeSave(false)
      updateEditionMode(false)
      fillData(JSON.parse(JSON.stringify(formCopy)))
    } else {
      history.push(Routes.Flights)
    }
  }

  const addMngtComments = useCallback(
    (comments: string) => {
      const newMngtComments = {
        date: new Date().toLocaleDateString(),
        reviewer: user.name,
        comments,
      }

      setMngtComments((prevState) => [newMngtComments, ...prevState])
    },
    [user]
  )

  // This function is to force the clean of management input because of
  // atmos component is using children value for <textarea> element instead of
  // "value" prop as react expects.
  function cleanCommentsInput() {
    const input = document.getElementById(
      "input-mngt-comments"
    ) as HTMLInputElement
    input.value = ""
    setMngtCommentsInput("")
  }

  const saveEvaluation = useCallback(() => {
    if (mngtCommentsInput === "") {
      updateIsMngtCommentsEmpty(true)
      document.getElementById("input-mngt-comments")?.focus()
    } else {
      const body: EvaluationSent = {
        managementComments: mngtCommentsInput,
        captainComments: form.isCaptainCommentsRedacted
          ? form.captainRedactedComments
          : null,
        isCaptainCommentsApproved: form.isCaptainCommentsApproved,
      }

      if (states.safeSave) {
        body.gradings = transformGradingEdit(form.gradings)
      }

      dispatch({ type: "loader" })

      evaluations
        .save(form.evaluationId, body)
        .then(() => {
          updateIsMngtCommentsEmpty(false)
          addMngtComments(mngtCommentsInput)
          cleanCommentsInput()
          updateModalOpen(false)
          updateEditionMode(false)
          updateSafeSave(false)
          updateModalSaveOpen(true)
          setAlert("Success")
          setMessage("Changes saved successfully.")
          copyData(JSON.parse(JSON.stringify(form)))
        })
        .catch((error: ErrorResponse) => {
          scrollToTopAndShowErrorAlert(
            parseErrorMessageFromResponse(error.response)
          )
        })
        .finally(() => {
          dispatch({ type: "loader" })
        })
    }
  }, [
    form,
    states,
    dispatch,
    copyData,
    addMngtComments,
    mngtCommentsInput,
    updateEditionMode,
    updateSafeSave,
    updateModalSaveOpen,
    updateIsMngtCommentsEmpty,
    scrollToTopAndShowErrorAlert,
    updateModalOpen,
  ])

  useEffect(() => {
    if (form.saved && states.saveAction) {
      updateSaveAction(false)
      if (form.isManual && !validateFlightInfo()) {
        updateShowRequiredFieldErrors(true)
        return
      }

      const values: string[] = []
      const comments: boolean[] = []

      if (form.gradings.length > 0) {
        form.gradings.forEach((category: Category) => {
          if (typeof category.subcategories !== undefined) {
            category?.subcategories.forEach((subcategory: Subcategory) => {
              if (subcategory.value !== null) {
                values.push(subcategory.value)
              }

              const v =
                ["4", "5"].includes(subcategory.value) &&
                (subcategory.isRedacted
                  ? subcategory.redactedComments === "" ||
                    subcategory.redactedComments === null
                  : subcategory.comments === "" ||
                    subcategory.comments === null)

              comments.push(v)
            })
          }
        })
      }

      const x = values.some((v) => v === "" || v === "-1")
      const y = comments.some((v) => v)

      if (x || y) {
        const errors = document.getElementsByClassName(
          `ebp-foppt-u-input-error--${id}`
        )
        const elements: Array<HTMLTextAreaElement | HTMLSelectElement> = []
        for (const item of errors) {
          const textareas: HTMLCollectionOf<HTMLTextAreaElement> =
            item.getElementsByTagName("textarea")
          const selects: HTMLCollectionOf<HTMLSelectElement> =
            item.getElementsByTagName("select")

          for (const textarea of textareas) {
            elements.push(textarea)
          }

          for (const select of selects) {
            elements.push(select)
          }
        }

        if (elements.length > 0) {
          const isTextArea = elements[0]?.nodeName === "TEXTAREA"
          const top = quickEdit ? 0 : 300
          const selectElement = elements[0]?.parentNode as HTMLElement
          const textareaElement = elements[0]?.offsetParent as HTMLElement
          const textareaElement2 = textareaElement?.offsetParent as HTMLElement
          const offsetTop = isTextArea
            ? selectElement?.offsetTop
            : textareaElement2?.offsetTop

          setTimeout(() => {
            const field = isTextArea
              ? (elements[0]?.parentNode as HTMLElement)
              : (elements[0]?.parentNode as HTMLElement)
            window.scrollTo(0, offsetTop - top)

            field?.classList?.toggle("ebp-foppt-u-input-error--focus")

            if (isTextArea) {
              elements[0].disabled = true
            }

            setTimeout(() => {
              field?.classList?.toggle("ebp-foppt-u-input-error--focus")

              // For Textarea, focus!
              if (isTextArea) {
                elements[0].disabled = false
                elements[0]?.focus()
              }
            }, 800)
          }, 50)
        }
      } else {
        if ((quickEdit || states.editionMode) && !states.canSubmit) {
          saveEvaluation()
        } else {
          updateModalOpen(true)
        }
      }
    }
  }, [
    form,
    states.saveAction,
    states.editionMode,
    quickEdit,
    updateSaveAction,
    updateModalOpen,
    saveEvaluation,
    history,
    id,
    states.canSubmit,
    validateFlightInfo,
    openCategories,
    updateShowRequiredFieldErrors,
  ])

  useEffect(() => {
    if (quickEdit) {
      updateLoader(true)
    } else {
      dispatch({ type: "loader" })
    }

    if (id === "0") {
      evaluations
        .getNew()
        .then((response) => {
          const gradings = response.evaluation.gradings.map(
            (grading: Grading) => {
              return {
                ...grading,
                collapsed: false,
                subcategories: grading.subcategories.map(
                  (subcategory: Subcategory) => {
                    return {
                      ...subcategory,
                      comments: "",
                    }
                  }
                ),
              }
            }
          )

          const defaultFlightType = response.evaluation.flightType
          const isSpecialFlight = specialFlightTypes.includes(defaultFlightType)

          const api: EvaluationDetail = {
            ...response.evaluation,
            flightInfo: {
              ...response.evaluation.flightInfo,
              flightNo: isSpecialFlight ? "UA" : "",
              destination: isSpecialFlight ? "" : SIMULATION_CITY,
              originCity: isSpecialFlight ? "" : SIMULATION_CITY,
              originDate: user.evaluationSettings.today,
            },
            gradings,
            comments: "",
            managementComments: [],
            saved: false,
          }

          fillData(api)
          updateCanSubmit(true)

          if (api.status.toLocaleLowerCase() !== statuses.SUBMITTED) {
            updateEditionMode(true)
          }
        })
        .catch((error: ErrorResponse) => {
          if (error.response !== undefined && error.response.status >= 400) {
            const errorMessage = parseErrorMessageFromResponse(error.response)
            scrollToTopAndShowErrorAlert(errorMessage)
          }
          history.push(Routes.Flights)
        })
        .finally(() => {
          dispatch({ type: "loader" })
        })
    } else {
      evaluations
        .get(Number(id))
        .then((response) => {
          const gradings = response.evaluation.gradings.map(
            (grading: Grading) => {
              const subcategories = grading.subcategories.map(
                (subcategory: Subcategory) => {
                  return {
                    ...subcategory,
                    comments: decodeHtmlEntities(subcategory.comments),
                    redactedComments:
                      subcategory.redactedComments !== null
                        ? decodeHtmlEntities(subcategory.redactedComments)
                        : null,
                  }
                }
              )

              return {
                ...grading,
                subcategories,
              }
            }
          )
          const comments = decodeHtmlEntities(response.evaluation.comments)
          const captainRedactedComments =
            response.evaluation.captainRedactedComments !== null
              ? decodeHtmlEntities(response.evaluation.captainRedactedComments)
              : null
          const managementComments = response.evaluation.managementComments.map(
            (row: ManagementComments) => {
              row.comments = decodeHtmlEntities(row.comments)

              return row
            }
          )

          const api = {
            ...response.evaluation,
            gradings,
            comments,
            captainRedactedComments,
            managementComments,
            saved: false,
          }

          const canSubmit =
            api.status.toLocaleLowerCase() !== statuses.SUBMITTED &&
            user.uid === api.captainInfo.employeeNo

          fillData(api)
          copyData(JSON.parse(JSON.stringify(api)))
          setMngtComments(api.managementComments)
          updateCanSubmit(canSubmit)

          if (
            !quickEdit &&
            canSubmit &&
            api.status.toLocaleLowerCase() !== statuses.SUBMITTED
          ) {
            updateEditionMode(true)
          }
        })
        .catch((error: ErrorResponse) => {
          if (error.response !== undefined && error.response.status >= 400) {
            const errorMessage = parseErrorMessageFromResponse(error.response)
            scrollToTopAndShowErrorAlert(errorMessage)
            updateFailedFetchingOnQuickEdit(errorMessage ?? "")
          }
          if (!quickEdit) {
            history.push(Routes.Flights)
          }
        })
        .finally(() => {
          if (quickEdit) {
            updateLoader(false)
          } else {
            dispatch({ type: "loader" })
          }
        })
    }

    return () => {
      updateLoader(false)
    }
  }, [
    id,
    dispatch,
    history,
    context,
    quickEdit,
    fillData,
    copyData,
    updateLoader,
    updateEditionMode,
    updateCanSubmit,
    scrollToTopAndShowErrorAlert,
    updateFailedFetchingOnQuickEdit,
    user.uid,
    specialFlightTypes,
    user.evaluationSettings.today,
  ])

  const showExitAlert = useMemo(() => {
    return !(
      states.modalBackOpen ||
      states.modalSaveOpen ||
      states.isSubmitting ||
      !states.safeSave
    )
  }, [
    states.modalBackOpen,
    states.isSubmitting,
    states.modalSaveOpen,
    states.safeSave,
  ])

  useEffect(() => {
    function handleOnBeforeUnload(e: BeforeUnloadEvent) {
      if (showExitAlert) {
        e.preventDefault()

        // Included for legacy support, e.g. Chrome/Edge < 119
        e.returnValue = true
      }
    }

    window.addEventListener("beforeunload", handleOnBeforeUnload)

    return () => {
      window.removeEventListener("beforeunload", handleOnBeforeUnload)
    }
  }, [showExitAlert])

  return states.failedFetchingOnQuickEdit.length > 0 ? (
    <p>{states.failedFetchingOnQuickEdit}</p>
  ) : (
    <>
      {!quickEdit && (
        <EvaluationInformation
          pilotInfo={form.pilotInfo}
          captainInfo={form.captainInfo}
          flightInfo={form.flightInfo}
          evaluationInfo={form.evaluationInfo}
          status={form.status}
          flightType={form.flightType}
          flightTypeTitle={form.flightTypeTitle}
          isManual={form.isManual}
          setEvaluator={setEvaluator}
          setFlightInfo={setFlightInfo}
          setFlightType={setFlightType}
          setPilot={setPilot}
          setOptionalCategories={setOptionalCategories}
          showRequiredFieldErrors={states.showRequiredFieldErrors}
        />
      )}
      <section className={!quickEdit ? "atm-c-box-section" : ""}>
        <div
          className={
            !quickEdit
              ? "ebp-foppt-c-box-section__body"
              : "ebp-foppt-c-box-section__body--alt"
          }
        >
          {states.loader && quickEdit && (
            <div className="ebp-foppt-l-items--center">
              <LoadingIndicator />
            </div>
          )}
          {context.evaluationFormQuickEdit &&
            isSubmitted &&
            form.pilotInfo.status.toLowerCase() !== statuses.COMPLETED && (
              <QuickEditActions
                editionMode={states.editionMode}
                updateEditionMode={updateEditionMode}
                cancelEdit={cancelEdit}
              />
            )}
          <GradingForm
            id={Number(id)}
            flightType={form.flightType}
            toggleChevron={toggleChevron}
            isSubmitted={isSubmitted}
            gradings={form.gradings}
            loader={states.loader}
            editionMode={states.editionMode}
            saved={form.saved}
            setRating={setRating}
            setSubComments={setSubComments}
            updateModalErrorOpen={updateModalErrorOpen}
            updateModalSimulatorOpen={updateModalSimulatorOpen}
            updateSafeSave={updateSafeSave}
            setSubcategoryRedactedComments={setSubcategoryRedactedComments}
            setSubcategoryRedacted={setSubcategoryRedacted}
            setSubcategoryApproval={setSubcategoryApproval}
            cancelSubcategoryRedactedComments={
              cancelSubcategoryRedactedComments
            }
          />
          {!states.loader && (
            <Comments
              editionMode={states.editionMode}
              canSubmit={states.canSubmit}
              isSubmitted={isSubmitted}
              setComment={setComment}
              updateSafeSave={updateSafeSave}
              value={form.comments}
              captainRedactedComments={form.captainRedactedComments}
              isCaptainCommentsRedacted={form.isCaptainCommentsRedacted}
              isCaptainCommentsApproved={form.isCaptainCommentsApproved}
              setRedacted={setRedacted}
              setApproval={setApproval}
              setRedactedComments={setRedactedComments}
              cancelRedactedComments={cancelRedactedComments}
            />
          )}
          {!states.loader && (
            <ManagementComments
              mngtComments={mngtComments}
              isSubmitted={isSubmitted}
              isMngtCommentsEmpty={states.isMngtCommentsEmpty}
              setMngtCommentsInput={setMngtCommentsInput}
              mngtCommentsInput={mngtCommentsInput}
              updateModalCommentsOpen={updateModalCommentsOpen}
              validateForm={validateForm}
            />
          )}
          <EvaluationActions
            canSubmit={states.canSubmit}
            fom={form.fom}
            submitBtn={states.submitBtn}
            saveDraftForm={saveDraftForm}
            handleClickFOM={handleClickFOM}
            validateForm={validateForm}
            goBack={goBack}
          />
        </div>
      </section>
      <Modal
        dismissible={true}
        isActive={states.modalOpen}
        onClose={() => {
          updateModalOpen(false)
        }}
        focusLockProps={{
          persistentFocus: true,
          returnFocus: true,
        }}
        ariaLabelledBy="modal-heading-1"
        ariaDescribedBy="modal-description-1"
      >
        <ModalHeader>
          <Heading id="modal-heading-1" tagName="h2">
            Evaluation
          </Heading>
        </ModalHeader>
        <ModalBody>
          <div>
            <div className="atm-u-margin-bottom-large">
              <p>You have completed the evaluation for this Pilot.</p>
              <p>Do you want to submit it right now?</p>
              <Alert iconName="warning" title="Notice" variant="warning">
                <p>
                  Once you submit the evaluation you will not be able to modify
                  it later.
                </p>
              </Alert>
            </div>
            <div className="ebp-foppt-l-items--right">
              <ButtonGroup>
                <Button
                  text="Continue Editing"
                  onClick={() => {
                    updateModalOpen(false)
                  }}
                />
                <Button
                  variant="primary"
                  text="Submit"
                  onClick={submitEvaluation}
                />
              </ButtonGroup>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        dismissible={true}
        isActive={states.modalSaveOpen}
        onClose={() => {
          updateModalSaveOpen(false)
        }}
        focusLockProps={{
          persistentFocus: true,
          returnFocus: true,
        }}
        ariaLabelledBy="modal-heading-1"
        ariaDescribedBy="modal-description-1"
      >
        <ModalHeader>
          <Heading id="modal-heading-1" tagName="h2">
            Evaluation
          </Heading>
        </ModalHeader>
        <ModalBody>
          <div>
            <div className="atm-u-margin-bottom-large">
              <Alert
                iconName={alert.toLowerCase()}
                title={alert}
                variant={alert.toLowerCase()}
              >
                <p>{message}</p>
              </Alert>
            </div>
            <div className="ebp-foppt-l-items--right">
              <ButtonGroup>
                <Button
                  text="Continue Editing"
                  onClick={() => {
                    updateModalSaveOpen(false)
                  }}
                />
                {alert.toLowerCase() === "success" && (
                  <Button
                    variant="primary"
                    text="Go to Flight History"
                    onClick={() => {
                      history.push(Routes.Flights)
                    }}
                  />
                )}
              </ButtonGroup>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        dismissible={true}
        isActive={states.modalCommentsOpen}
        onClose={() => {
          updateModalCommentsOpen(false)
        }}
        focusLockProps={{
          persistentFocus: true,
          returnFocus: true,
        }}
        variant="wide"
        ariaLabelledBy="modal-heading-1"
        ariaDescribedBy="modal-description-1"
      >
        <ModalHeader>
          <Heading id="modal-heading-1" tagName="h2">
            Managements Comments
          </Heading>
        </ModalHeader>
        <ModalBody>
          {mngtComments.length > 0 && (
            <Table>
              <TableHeader>
                <TableRow>
                  <TableCell alignment="left" tagName="th">
                    DATE
                  </TableCell>
                  <TableCell alignment="left" tagName="th">
                    REVIEWER
                  </TableCell>
                  <TableCell alignment="left" tagName="th">
                    COMMENT
                  </TableCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                {mngtComments.map((comment, key: number) => {
                  return (
                    <TableRow key={key}>
                      <TableCell
                        alignment="left"
                        data-label="Table Column 1"
                        tagName="td"
                      >
                        {comment.date}
                      </TableCell>
                      <TableCell
                        alignment="left"
                        data-label="Table Column 2"
                        tagName="td"
                      >
                        {comment.reviewer}
                      </TableCell>
                      <TableCell
                        alignment="left"
                        data-label="Table Column 3"
                        tagName="td"
                      >
                        {renderMultilineComments(comment.comments)}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          )}
        </ModalBody>
      </Modal>
      <Modal
        dismissible={true}
        isActive={states.modalErrorOpen}
        onClose={() => {
          updateModalErrorOpen(false)
        }}
        focusLockProps={{
          persistentFocus: true,
          returnFocus: true,
        }}
        ariaLabelledBy="modal-heading-1"
        ariaDescribedBy="modal-description-1"
      >
        <ModalHeader></ModalHeader>
        <ModalBody>
          <div>
            <Alert iconName="error" title="N/A Not Applicable" variant="error">
              <p>This option is ONLY allowed once for Skills subcategories.</p>
              <p>Please select another option.</p>
            </Alert>
            <div className="ebp-foppt-l-items--right">
              <ButtonGroup>
                <Button
                  text="Ok"
                  onClick={() => {
                    updateModalErrorOpen(false)
                  }}
                />
              </ButtonGroup>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        dismissible={true}
        isActive={states.modalSimulatorOpen}
        onClose={() => {
          updateModalSimulatorOpen(false)
        }}
        focusLockProps={{
          persistentFocus: true,
          returnFocus: true,
        }}
        ariaLabelledBy="modal-heading-1"
        ariaDescribedBy="modal-description-1"
      >
        <ModalHeader></ModalHeader>
        <ModalBody>
          <div>
            <Alert iconName="error" title="N/A Not Applicable" variant="error">
              <p>
                As you provided comments you must select a rating from 1 to 5.
              </p>
            </Alert>
            <div className="ebp-foppt-l-items--right">
              <ButtonGroup>
                <Button
                  text="Ok"
                  onClick={() => {
                    updateModalSimulatorOpen(false)
                  }}
                />
              </ButtonGroup>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        dismissible={true}
        isActive={states.modalBackOpen}
        onClose={() => {
          updateModalBackOpen(false)
        }}
        focusLockProps={{
          persistentFocus: true,
          returnFocus: true,
        }}
        ariaLabelledBy="modal-heading-1"
        ariaDescribedBy="modal-description-1"
      >
        <ModalHeader></ModalHeader>
        <ModalBody>
          <div>
            <Alert iconName="warning" title="Alert" variant="warning">
              <p>If you leave before saving, your changes will be lost.</p>
            </Alert>
            <div className="ebp-foppt-l-items--right">
              <ButtonGroup>
                <Button
                  text="Continue Editing"
                  onClick={() => {
                    updateModalBackOpen(false)
                  }}
                />
                <Button text="Leave" variant="primary" onClick={leaveView} />
              </ButtonGroup>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Prompt
        when={showExitAlert}
        message={"Changes you made may not be saved."}
      />
    </>
  )
}

export default withRouter(EvaluationForm)
