import React from "react"
import Settings from "src/components/Settings"
import MainLayout from "src/components/UI/MainLayout"

const SettingsView = () => {
  return (
    <MainLayout context="systemTablesSettings">
      <Settings />
    </MainLayout>
  )
}

export default SettingsView
