import React from "react"
import { withRouter } from "react-router"
import { Icon } from "atmos-design-system"
import CustomTable from "../UI/Table/Table"
import type { EvaluationListProps } from "./interfaces"
import type { PairingEvaluationSummary } from "src/api/pairings/interfaces"
import { Routes } from "src/utils/routes"

function EvaluationList({
  evaluations,
  statusClassNames,
  history,
}: EvaluationListProps) {
  const columns: Array<Column<PairingEvaluationSummary>> = [
    {
      field: "flightNo",
      title: "Flight No",
    },
    {
      field: "originDate",
      title: "Date",
      render: (row: PairingEvaluationSummary) => {
        return row.originDate
      },
    },
    {
      field: "originCity",
      title: "Flight",
      render: (row: PairingEvaluationSummary) => {
        return `${row.originCity} — ${row.destination}`
      },
    },
    {
      field: "status",
      title: "Status",
      render: (row: PairingEvaluationSummary) => {
        return (
          <>
            <div className={statusClassNames[row.status]}>
              {row.evaluationStatus}
            </div>
            {row.soonToExpireMessage !== null && (
              <div className="ebp-foppt-expiring">
                {row.soonToExpireMessage}
              </div>
            )}
          </>
        )
      },
    },
    {
      field: "evaluationId",
      title: "",
      rowSpan: 1,
      render: (row: PairingEvaluationSummary) => {
        return (
          <a
            onClick={(e) => {
              e.stopPropagation()
              history.push(`${Routes.Evaluation}/${row.evaluationId}`)
            }}
          >
            <Icon name="caret-right" />
          </a>
        )
      },
    },
  ]
  return (
    <CustomTable<PairingEvaluationSummary>
      columns={columns}
      data={evaluations}
      loading={false}
      tableType="evaluations"
      onRowClick={(row) => {
        history.push(`${Routes.Evaluation}/${row.evaluationId}`)
      }}
    />
  )
}

export default withRouter(EvaluationList)
