export function formReducer(
  state: EvaluationDetail,
  action: FormReducerAction
): EvaluationDetail {
  const ratings = ["1", "2", "3", "4", "5"]
  const selectOneOption = "-1"

  switch (action.type) {
    case "fillData": {
      return action.payload
    }

    case "setRating": {
      const clonedState: EvaluationDetail = JSON.parse(JSON.stringify(state))
      const { value, catIndex, scatIndex } = action.payload
      clonedState.gradings[catIndex].subcategories[scatIndex].value = value

      return clonedState
    }

    case "toggleChevron": {
      const clonedState: EvaluationDetail = JSON.parse(JSON.stringify(state))
      clonedState.gradings[action.payload].collapsed =
        !clonedState.gradings[action.payload].collapsed

      return clonedState
    }

    case "setOptionalCategories": {
      const isRealFlight = action.payload
      const gradings = state.gradings.map((category) => {
        const isSubcategoriesEdited = category.subcategories.some(
          (subcategory) =>
            ratings.includes(subcategory.value) ||
            !["", null].includes(subcategory.comments)
        )

        const subcategories = category.subcategories.map((subcategory) => {
          const value = subcategory.value
          const naOption = !["", null].includes(subcategory.comments)
            ? selectOneOption
            : "0"
          const selectedRating = ratings.includes(value)
          const defaultValue = isRealFlight ? selectOneOption : naOption

          if (!category.optional) {
            return subcategory
          } else {
            return {
              ...subcategory,
              value: selectedRating ? value : defaultValue,
            }
          }
        })

        const collapsed = isRealFlight ? false : category.optional

        return {
          ...category,
          collapsed: isSubcategoriesEdited ? false : collapsed,
          subcategories,
        }
      })

      return {
        ...state,
        gradings,
      }
    }

    case "openCategories": {
      const isRealFlight = action.payload
      const gradings = state.gradings.map((category) => {
        const isSubcategoriesEdited = category.subcategories.some(
          (subcategory) =>
            [selectOneOption, ...ratings].includes(subcategory.value)
        )
        return {
          ...category,
          collapsed: isRealFlight
            ? false
            : !isSubcategoriesEdited && category.optional,
        }
      })

      return {
        ...state,
        gradings,
      }
    }

    case "setSubComments": {
      const { value, catIndex, scatIndex } = action.payload
      const clonedState: EvaluationDetail = JSON.parse(JSON.stringify(state))
      clonedState.gradings[catIndex].subcategories[scatIndex].comments = value

      return clonedState
    }

    case "setComments": {
      const clonedState: EvaluationDetail = JSON.parse(JSON.stringify(state))
      clonedState.comments = action.payload

      return clonedState
    }

    case "setFlightInfo": {
      const clonedState: EvaluationDetail = JSON.parse(JSON.stringify(state))
      clonedState.flightInfo = action.payload

      return clonedState
    }

    case "setFlightType": {
      const clonedState: EvaluationDetail = JSON.parse(JSON.stringify(state))
      clonedState.flightType = action.payload

      return clonedState
    }

    case "setPilot": {
      const clonedState: EvaluationDetail = JSON.parse(JSON.stringify(state))
      clonedState.pilotInfo = action.payload

      return clonedState
    }

    case "setEvaluator": {
      const clonedState: EvaluationDetail = JSON.parse(JSON.stringify(state))
      clonedState.captainInfo = action.payload

      return clonedState
    }

    case "setEvaluationId": {
      const clonedState: EvaluationDetail = JSON.parse(JSON.stringify(state))
      clonedState.evaluationId = action.payload

      return clonedState
    }

    case "setRedactedComments": {
      return {
        ...state,
        captainRedactedComments: action.payload,
        isCaptainCommentsApproved: true,
      }
    }

    case "setRedacted": {
      return {
        ...state,
        isCaptainCommentsRedacted: action.payload,
      }
    }

    case "setApproval": {
      return {
        ...state,
        isCaptainCommentsApproved: action.payload,
      }
    }

    case "cancelRedactedComments": {
      return {
        ...state,
        captainRedactedComments: null,
        isCaptainCommentsRedacted: false,
      }
    }

    case "setSubcategoryRedactedComments": {
      const { value, catIndex, scatIndex } = action.payload
      const clonedState: EvaluationDetail = JSON.parse(JSON.stringify(state))
      clonedState.gradings[catIndex].subcategories[scatIndex].redactedComments =
        value

      return clonedState
    }

    case "setSubcategoryRedacted": {
      const { value, catIndex, scatIndex } = action.payload
      const clonedState: EvaluationDetail = JSON.parse(JSON.stringify(state))
      clonedState.gradings[catIndex].subcategories[scatIndex].isRedacted = value

      if (value) {
        clonedState.gradings[catIndex].subcategories[
          scatIndex
        ].redactedComments =
          clonedState.gradings[catIndex].subcategories[scatIndex].comments
      }

      return clonedState
    }

    case "setSubcategoryApproval": {
      const { value, catIndex, scatIndex } = action.payload
      const clonedState: EvaluationDetail = JSON.parse(JSON.stringify(state))
      clonedState.gradings[catIndex].subcategories[scatIndex].isApproved = value

      return clonedState
    }

    case "cancelSubcategoryRedactedComments": {
      const { catIndex, scatIndex } = action.payload
      const clonedState: EvaluationDetail = JSON.parse(JSON.stringify(state))
      clonedState.gradings[catIndex].subcategories[scatIndex].isRedacted = false
      clonedState.gradings[catIndex].subcategories[scatIndex].redactedComments =
        null

      return clonedState
    }

    case "saved": {
      const clonedState: EvaluationDetail = JSON.parse(JSON.stringify(state))
      clonedState.saved = true

      return clonedState
    }
  }
}
