import { onDev } from "src/utils/functions"

import type {
  ApiRequest,
  AuthRequest,
  OAuthAxiosRequestHeaders,
} from "src/api/apiInterfaces"
import type { User } from "./interfaces"

interface URLs {
  baseURL: string
  authURL: string
}

const UserApi = (
  auth: AuthRequest,
  URLs: URLs,
  getEndpoint: CallableFunction
) => {
  return {
    get: async (
      headers: OAuthAxiosRequestHeaders,
      id: string | null = null
    ) => {
      const endpoint = onDev() && id !== null ? "impersonated" : "user"
      const url = id !== null ? URLs.baseURL : URLs.authURL

      return await auth.get<User>(getEndpoint(endpoint, id), url, headers)
    },
  }
}

const UserRequest = (request: ApiRequest, getEndpoint: CallableFunction) => {
  return {
    acknowledge: async () =>
      await request.post(getEndpoint("acknowledgeCaptainNotice"), {}),
  }
}

export { UserApi, UserRequest }
