import React, { useEffect, useState } from "react"
import { type RouteComponentProps, withRouter } from "react-router-dom"
import {
  Button,
  Icon,
  Modal,
  ModalHeader,
  Heading,
  ModalBody,
  ButtonGroup,
} from "atmos-design-system"
import Search from "src/components/UI/Search"
import CustomTable from "src/components/UI/Table/Table"
import CheckboxItems from "src/components/UI/CheckboxItems"
import { captains } from "src/api"
import { useSelector } from "react-redux"
import img from "../../../public/assets/images/user_profile.jpg"
import type { UserState, UserContext } from "src/api/user/interfaces"
import useCaptainsList from "../../components/Captains/hooks/useCaptainsList"
import { Routes } from "src/utils/routes"
import GoTop from "../UI/GoTop"
import { IntersectionObserverElement } from "../UI/IntersectionObserverElement"
import {
  getCaptainsFilterSettings,
  removeCaptainsFilterSettings,
  saveCaptainsFilterSettings,
} from "src/utils/functions"
import { RoleType } from "src/api/apiTypes"
import { EmployeePicture } from "../Employee/Picture"

interface LoggedUserState {
  loggedUser: {
    uid: string
    picture: string
    name: string
    role: string
    roleType: RoleType
    context: UserContext
  }
}

const CaptainsList = ({ history }: RouteComponentProps): JSX.Element => {
  const [openModal, setOpenModal] = useState<boolean>(false)
  const { states, loadCaptains, setBases, setBasesAndStatus } =
    useCaptainsList()
  const loggedUser = useSelector((state: LoggedUserState) => state.loggedUser)
  const isSuperAdmin = loggedUser.roleType === RoleType.SuperAdmin
  const defaultBase = useSelector((state: UserState) => state.user.base)
  const context = useSelector((state: UserState) => state.user.context)

  const columns: Array<Column<CaptainsInfo>> = [
    {
      field: "name",
      title: "Employee",
      sort: false,
      render: (row: CaptainsInfo) => (
        <>
          <div className="ebp-foppt-employe-container">
            <div className="ebp-foppt-pilots-image-cropper-tbl">
              <img
                src={
                  row.picture !== "" && row.picture !== null ? row.picture : img
                }
                alt="captain picture"
              />
            </div>
            <div>
              <p className="ebp-foppt-no-margin ebp-foppt-pilot-name">
                {row.name}
              </p>
              <p className="ebp-foppt-no-margin">{row.employeeNo}</p>
            </div>
          </div>
        </>
      ),
    },
    {
      field: "base",
      title: "Base",
      sort: false,
    },
    {
      field: "role",
      title: "Role",
      sort: false,
    },

    {
      field: "employeeNo",
      title: "",
      render: (row: CaptainsInfo) => {
        return (
          <a
            onClick={() => {
              history.push(`${Routes.Captain}/${row.employeeNo}`)
            }}
            className="atm-c-table__action-cell"
          >
            <Icon name="caret-right" />
          </a>
        )
      },
    },
  ]

  const isIntersecting = () => {
    if (!states.isFetching && !states.lastCall) loadCaptains(true)
  }

  useEffect(() => {
    if (defaultBase !== "" && states.bases.length === 0) {
      const settings = getCaptainsFilterSettings()
      if (settings.bases !== null) {
        setBases({
          bases: settings.bases,
        })
      } else {
        setBasesAndStatus({
          bases: [
            {
              label: defaultBase,
              value: defaultBase,
              selected: !isSuperAdmin,
            },
          ],
          isSuperAdmin,
        })
      }
    }
  }, [
    isSuperAdmin,
    defaultBase,
    states.bases.length,
    setBasesAndStatus,
    setBases,
  ])

  const resetFilters = () => {
    const newBases: CheckboxesType[] = states.bases.map((base) => {
      return {
        label: base.label,
        value: base.value,
        selected: isSuperAdmin ? false : base.value === defaultBase,
      }
    })
    setBasesAndStatus({
      bases: newBases,
      isSuperAdmin,
    })
    removeCaptainsFilterSettings()
  }

  const filter = () => {
    saveCaptainsFilterSettings({ bases: states.bases })
    loadCaptains(false)
    setOpenModal(false)
  }

  function renderResults(item: FormattedSearchEmployeeResponse) {
    return (
      <>
        <EmployeePicture picture={item.picture} size="x-small" />
        {item.value}
      </>
    )
  }

  const handlerOpenModal = () => {
    setOpenModal(true)
  }

  return (
    <>
      <div className="ebp-foppt-filters-container">
        <Button
          text="Add Filter"
          onClick={handlerOpenModal}
          disabled={!context.captainsListBaseFilter}
        />
        <Search<FormattedSearchEmployeeResponse>
          onSelect={(item) => {
            history.push(`${Routes.Captain}/${item.id}`)
          }}
          api={captains.searchCaptains}
          render={renderResults}
        />
      </div>
      <div>
        <p className="ebp-foppt-table-label"></p>
        <CustomTable<CaptainsInfo>
          columns={columns}
          data={states.data}
          loading={states.isFetching}
          tableType="captains"
        />
        <Modal
          variant="wide"
          dismissible={true}
          isActive={openModal}
          onClose={() => {
            setOpenModal(false)
          }}
          focusLockProps={{
            persistentFocus: true,
            returnFocus: true,
          }}
          ariaLabelledBy="modal-heading-1"
          ariaDescribedBy="modal-description-1"
        >
          <ModalHeader>
            <Heading id="modal-heading-1" tagName="h2"></Heading>
          </ModalHeader>
          <ModalBody>
            <CheckboxItems
              label="Bases"
              values={states.bases}
              setValues={(bases) => {
                setBases({ bases })
              }}
            />
            <hr />
            <div className="ebp-foppt-l-items--right">
              <ButtonGroup>
                <Button
                  text="Reset"
                  onClick={() => {
                    resetFilters()
                    setOpenModal(false)
                  }}
                />

                <Button text="Filter" variant="primary" onClick={filter} />
              </ButtonGroup>
            </div>
          </ModalBody>
        </Modal>
      </div>
      <IntersectionObserverElement callback={isIntersecting} />
      <GoTop scrollTop={200} />
    </>
  )
}
export default withRouter(CaptainsList)
