import React from "react"
import UserBases from "src/components/UserBases"
import MainLayout from "src/components/UI/MainLayout"

const UserBasesListView = () => {
  return (
    <MainLayout context="systemTablesUserBases">
      <UserBases />
    </MainLayout>
  )
}
export default UserBasesListView
