import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  Alert,
  Grid,
  GridItem,
  Modal,
  ModalHeader,
  ModalBody,
  Heading,
  Button,
  ButtonGroup,
  TextField,
  Tooltip,
  TextLink,
} from "atmos-design-system"
import { pilot, pairings } from "src/api"
import { EmployeeSearch } from "../Employee/Search"
import type { UserContext } from "src/api/user/interfaces"
import { type SearchPairingsParams } from "src/api/pairings/interfaces"
import { withRouter, type RouteComponentProps } from "react-router"
import { Routes } from "src/utils/routes"
import { Link } from "react-router-dom"
import { REGEX } from "src/utils/constants"

interface UserState {
  user: {
    uid: string
    picture: string
    name: string
    role: string
    roleType: number
    context: UserContext
  }
}

interface LoggedUserState {
  loggedUser: {
    uid: string
    picture: string
    name: string
    role: string
    roleType: number
    context: UserContext
  }
}

interface ImpersonatedState {
  impersonated: boolean
}

interface AlertState {
  type: string
  title: string
  message: string
}

const ALERT = {
  type: "success",
  title: "Evaluations were created successfully.",
  message: "",
}

const ERROR_MESSAGES = {
  required: "Field is required.",
  invalidUserId: "Incorrect user id.",
}

interface PairingsSearchProps extends RouteComponentProps {
  setIsLoading: CallableFunction
  setIsDataLoaded: CallableFunction
}

const PairingsSearch = withRouter(
  ({ setIsLoading, setIsDataLoaded, history }: PairingsSearchProps) => {
    const dispatch = useDispatch()
    const [isSuccess, setIsSuccess] = useState<boolean>(false)
    const [noResults, setNoResults] = useState<boolean>(false)
    const [displayAlert, setDisplayAlert] = useState<boolean>(false)
    const [alert, setAlert] = useState<AlertState>(ALERT)
    const [modal, setModal] = useState<boolean>(false)
    const [employeeId, setEmployeeId] = useState<string>("")
    const [employee, setEmployee] =
      useState<FormattedSearchEmployeeResponse | null>(null)
    const [required, setRequired] = useState<TextFieldError>(null)
    const userState = useSelector((state: UserState) => state.user)
    const loggedUser = useSelector((state: LoggedUserState) => state.loggedUser)
    const impersonated = useSelector(
      (state: ImpersonatedState) => state.impersonated
    )

    const pilotSearchAutoComplete = loggedUser.context.pilotSearchAutoComplete
    const context = useSelector((state: UserState) => state.user.context)

    const cancel = () => {
      if (isSuccess) {
        setIsLoading(false)
        setIsDataLoaded(false)
      }
      setEmployee(null)
      setEmployeeId("")
      setRequired(null)
      setDisplayAlert(false)
      setModal(false)
      setIsSuccess(false)
    }

    const submit = () => {
      let message = ERROR_MESSAGES.required

      if (employee === null) {
        if (!pilotSearchAutoComplete) {
          if (employeeId !== "" && !REGEX.userID.test(employeeId)) {
            message = ERROR_MESSAGES.invalidUserId
          }
        }

        setRequired({ message })
      } else {
        setDisplayAlert(false)
        setIsSuccess(false)
        setRequired(null)
        dispatch({ type: "loader" })

        const params: SearchPairingsParams = {
          pilotId: employee.id,
          captainId: impersonated ? userState.uid : loggedUser.uid,
        }

        pairings
          .search(params)
          .then((response) => {
            setAlert({
              type: "success",
              title: "An evaluation is now available.",
              message:
                "Click “Go To Flights” to return to the home page and complete the evaluation. To create an additional evaluation for another date or flight not showing on your home page, please search again below.",
            })

            setIsSuccess(true)
          })
          .catch((err) => {
            setAlert({
              type: "error",
              title: "No Pairings Found.",
              message: err.response.data.message,
            })
            setNoResults(true)
          })
          .finally(() => {
            setDisplayAlert(true)
            dispatch({ type: "loader" })
          })
      }
    }

    const searchPilots = async (params: URLSearchParams) => {
      params.append("status", "Active")
      params.append("status", "LeaveOfAbsence")
      return await pilot.searchPilots(params)
    }

    return (
      <div className="ebp-foppt-c-pairings-search-button">
        <div>
          {context.evaluationFormCreateManual && (
            <Button
              variant="primary"
              text="New Evaluation"
              onClick={() => {
                history.push(Routes.NewEvaluation)
              }}
            />
          )}
        </div>
        <p>
          Probationary pilot not listed?{" "}
          <TextLink
            variant="bold"
            onClick={() => {
              setModal(true)
            }}
          >
            Search Here
          </TextLink>
        </p>

        <Modal
          variant="wide"
          dismissible={true}
          isActive={modal}
          onClose={cancel}
          focusLockProps={{
            persistentFocus: true,
            returnFocus: true,
          }}
          ariaLabelledBy="modal-heading-1"
          ariaDescribedBy="modal-description-1"
        >
          <ModalHeader>
            <Heading id="modal-heading-1" tagName="h2">
              Search Pairings
            </Heading>
          </ModalHeader>
          <ModalBody>
            <div className="ebp-foppt-c-pairings-search-modal">
              {displayAlert && (
                <>
                  <Alert
                    iconName={alert.type}
                    title={alert.title}
                    variant={alert.type}
                  >
                    <p>{alert.message}</p>
                    {noResults && (
                      <p>
                        You can create a{" "}
                        <Link to={Routes.NewEvaluation}>Manual Evaluation</Link>
                      </p>
                    )}
                  </Alert>
                  {isSuccess && (
                    <>
                      <div className="ebp-foppt-c-pairings-search-button">
                        <ButtonGroup align="right">
                          <Button
                            variant="primary"
                            text="Go To Flights"
                            onClick={() => {
                              cancel()
                            }}
                          />
                        </ButtonGroup>
                      </div>
                      <hr />
                    </>
                  )}
                </>
              )}
              <Grid variant="2up">
                <GridItem>
                  {pilotSearchAutoComplete ? (
                    <EmployeeSearch
                      label="Pilot"
                      requiredLabel="(Required)"
                      labelAfter={
                        <Tooltip buttonText="Probationary Pilot">
                          Probationary Pilot
                        </Tooltip>
                      }
                      api={searchPilots}
                      employee={employee}
                      setEmployee={setEmployee}
                      inline={false}
                      required={required}
                    />
                  ) : (
                    <>
                      <TextField
                        type="text"
                        placeholder="U123456"
                        label="Pilot"
                        requiredLabel="(Required)"
                        labelAfter={
                          <Tooltip
                            align="below"
                            buttonText="Probationary First Officer Employee ID. (e.g.
                              U123456)"
                          >
                            Probationary First Officer Employee ID. (e.g.
                            U123456)
                          </Tooltip>
                        }
                        value={employeeId}
                        maxLength="7"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const value = e.target.value
                          const hasError = !REGEX.userID.test(value)

                          setRequired(
                            hasError && value !== ""
                              ? { message: ERROR_MESSAGES.invalidUserId }
                              : null
                          )
                          setEmployeeId(value)
                          if (hasError || value === "") {
                            setEmployee(null)
                          } else {
                            setEmployee({
                              id: value,
                              name: "user",
                              picture: null,
                            })
                          }
                        }}
                        error={required}
                      />
                    </>
                  )}
                </GridItem>
              </Grid>
              <div className="ebp-foppt-c-pairings-search-button">
                <ButtonGroup align="right">
                  <Button variant="secondary" text="Cancel" onClick={cancel} />
                  <Button
                    variant="primary"
                    text="Search"
                    onClick={submit}
                    disabled={
                      !pilotSearchAutoComplete && !REGEX.userID.test(employeeId)
                    }
                  />
                </ButtonGroup>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    )
  }
)

export default PairingsSearch
