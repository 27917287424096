import type { ApiRequest } from "src/api/apiInterfaces"
import { onLocal } from "src/utils/functions"

const EvaluationsApi = (request: ApiRequest, getEndpoint: CallableFunction) => {
  return {
    get: async (id: number) =>
      await request.get<EvaluationResponse>(getEndpoint("evaluations", id)),
    save: async (id: number, body: EvaluationSent) => {
      if (onLocal()) {
        return await request.get<EvaluationResponse>(
          getEndpoint("saveEvaluation")
        )
      } else {
        return await request.post<EvaluationResponse>(
          getEndpoint("evaluations", id),
          body
        )
      }
    },
    remove: async (id: number) =>
      await request.delete(getEndpoint("evaluations", id)),
    getNew: async () =>
      await request.get<EvaluationResponse>(getEndpoint("newEvaluation")),
  }
}

export default EvaluationsApi
