import { useCallback, useReducer } from "react"
import { useDispatch } from "react-redux"
import { captains } from "src/api"
import type { CaptainsResponse } from "src/api/captains/interfaces"
type ExtraStateActions =
  | {
      type: "loadPage"
      payload: { response: CaptainsResponse; append: boolean }
    }
  | { type: "isFetching"; payload: boolean }
  | {
      type: "loadBases"
      payload: { bases: CheckboxesType[] }
    }
  | {
      type: "loadBasesAndStatus"
      payload: { bases: CheckboxesType[] }
    }
  | { type: "lastCall"; payload: boolean }

interface StateTypes {
  page: number
  pageSize: number
  lastCall: boolean
  isFetching: boolean
  data: CaptainsInfo[]
  bases: CheckboxesType[]
}

const INITIAL_STATE_EXTRA: StateTypes = {
  page: 1,
  pageSize: 50,
  lastCall: false,
  isFetching: false,
  data: [],
  bases: [],
}

function pilotListStateControls(
  state: StateTypes,
  action: ExtraStateActions
): StateTypes {
  switch (action.type) {
    case "isFetching": {
      return { ...state, [action.type]: action.payload }
    }
    case "loadPage": {
      return {
        ...state,
        isFetching: false,
        data: action.payload.append
          ? [...state.data, ...action.payload.response.captains]
          : action.payload.response.captains,
        page: Number(action.payload.response.pagination.page) + 1,
        lastCall: action.payload.response.captains.length < state.pageSize,
      }
    }
    case "loadBases": {
      return {
        ...state,
        bases: action.payload.bases,
      }
    }
    case "loadBasesAndStatus": {
      return {
        ...state,
        bases: action.payload.bases,
      }
    }
    case "lastCall": {
      return {
        ...state,
        lastCall: action.payload,
      }
    }
    default: {
      return { ...state }
    }
  }
}

function useCaptainsList() {
  const reduxDispatch = useDispatch()
  const [states, dispatch] = useReducer(
    pilotListStateControls,
    INITIAL_STATE_EXTRA
  )

  const setIsFetching = useCallback((payload: boolean) => {
    dispatch({ type: "isFetching", payload })
  }, [])

  const getParams = useCallback(
    (
      append: boolean,
      filters: {
        bases: CheckboxesType[] | null
      } = { bases: null }
    ) => {
      const filter = {
        bases:
          filters.bases !== null
            ? filters.bases.filter((x) => x.selected).map((x) => x.value)
            : states.bases
                .filter((item) => item.selected)
                .map((item) => item.value),
      }

      const params = new URLSearchParams()
      filter.bases.forEach((b) => {
        params.append("base", b)
      })

      params.append("page", append ? String(states.page) : "1")
      params.append("pageSize", String(states.pageSize))
      return params
    },
    [states]
  )

  const loadCaptains = useCallback(
    (
      append = true,
      isSuperAdmin = false,
      filters: {
        bases: CheckboxesType[] | null
      } = { bases: null }
    ) => {
      setIsFetching(true)
      captains
        .get(getParams(append, filters))
        .then((response) => {
          dispatch({
            type: "loadPage",
            payload: { append, response },
          })
          if (
            filters.bases !== null &&
            response.bases.length > filters.bases.length
          ) {
            const bases: CheckboxesType[] = response.bases.map((base) => {
              const savedBase = filters.bases.find((x) => x.label === base)
              return {
                label: base,
                value: base,
                selected: isSuperAdmin
                  ? false
                  : savedBase !== undefined
                  ? savedBase.selected
                  : false,
              }
            })
            dispatch({
              type: "loadBases",
              payload: { bases },
            })
          }
        })
        .catch((err) => {
          if (
            err?.code === "ERR_NETWORK" ||
            (err?.response !== undefined && err.response?.status >= 400)
          ) {
            dispatch({ type: "lastCall", payload: false })
            reduxDispatch({
              type: "alert",
              payload: {
                id: "CaptainList__fetchingFailed",
                display: true,
                message: "Fetching Captain List failed",
                type: "error",
              },
            })
          }
          dispatch({
            type: "isFetching",
            payload: false,
          })
        })
    },
    [setIsFetching, getParams, reduxDispatch]
  )

  const setBases = useCallback((payload: { bases: CheckboxesType[] }) => {
    dispatch({ type: "loadBases", payload })
  }, [])

  const setBasesAndStatus = useCallback(
    (payload: { bases: CheckboxesType[]; isSuperAdmin: boolean }) => {
      dispatch({ type: "loadBasesAndStatus", payload })
      loadCaptains(false, payload.isSuperAdmin, {
        bases: payload.bases,
      })
    },
    [loadCaptains]
  )

  return {
    states,
    getParams,
    setIsFetching,
    loadCaptains,
    setBases,
    setBasesAndStatus,
  }
}

export default useCaptainsList
