import React from "react"
import { TableRow } from "atmos-design-system"
import { CustomCell } from "./Cell"

export function CustomRow<T>({
  row,
  columns,
  rowIndex,
  onRowClick,
}: RowProps<T>) {
  return (
    <TableRow
      onClick={() => {
        if (onRowClick !== undefined) {
          onRowClick(row, rowIndex)
        }
      }}
      key={`row-${rowIndex}`}
      className="ebp-foppt-l-table__row"
    >
      {columns.map((column, columnIndex) => {
        return (
          <CustomCell<T>
            column={column}
            row={row}
            key={`${column.field as string}-${rowIndex}-${columnIndex}`}
          />
        )
      })}
    </TableRow>
  )
}
