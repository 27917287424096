import { useCallback, useEffect, useReducer } from "react"
import { useDispatch } from "react-redux"
import { PilotsScoreCardReducer } from "./reducer"
import { PilotsScorecardInitState } from "./data"
import { pilots } from "src/api"
import type { PilotsScorecardResponse } from "src/api/pilots/interfaces"

export const usePilotsScorecard = (uid: string) => {
  const reduxDispatch = useDispatch()

  const [states, dispatch] = useReducer(
    PilotsScoreCardReducer,
    PilotsScorecardInitState
  )

  const setData = useCallback((payload: PilotsScorecardResponse) => {
    dispatch({ type: "setData", payload })
  }, [])

  const loadData = useCallback((payload: boolean) => {
    dispatch({ type: "loadData", payload })
  }, [])

  useEffect(() => {
    if (states.isFetching) {
      reduxDispatch({ type: "loader" })
      pilots
        .getPilotsScorecard(uid)
        .then((response) => {
          setData(response)
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          reduxDispatch({ type: "loader" })
        })
    }
  }, [states.isFetching, setData, reduxDispatch, uid])

  return {
    states,
    setData,
    loadData,
  }
}
