import React, { useCallback, useEffect } from "react"
import { withRouter } from "react-router"
import Cookies from "js-cookie"
import CryptoJS from "crypto-js"
import Loader from "src/components/UI/Loader"
import type { OAuthAxiosRequestHeaders } from "src/api/apiInterfaces"
import { user } from "src/api"
import { RoleType } from "src/api/apiTypes"
import type { UserRole, UserContext } from "src/api/user/interfaces"

interface defaultProps {
  match: {
    params: {
      employeeId: string
      role: string
    }
  }
}

const templates: Record<UserRole, UserContext> = {
  Contributor: {
    impersonation: false,
    evaluationFormSubmit: true,
    evaluationFormQuickEdit: false,
    evaluationFormShowMngtComments: false,
    systemTablesMail: false,
    systemTablesRoles: false,
    systemTablesSettings: false,
    systemTablesUserBases: false,
    flightListView: true,
    pilotsListView: false,
    pilotsListBaseFilter: false,
    pilotsListCompletedFilter: false,
    pilotProfileView: false,
    pilotProfileDeleteEvaluation: false,
    captainDeleteEvaluation: false,
    captainProfileView: false,
    captainsListView: false,
    captainsListBaseFilter: false,
    pilotSearchAutoComplete: false,
  },
  "Sr. Staff Rep": {
    impersonation: false,
    evaluationFormSubmit: true,
    evaluationFormQuickEdit: false,
    evaluationFormShowMngtComments: true,
    systemTablesMail: false,
    systemTablesRoles: false,
    systemTablesSettings: false,
    systemTablesUserBases: false,
    flightListView: false,
    pilotsListView: true,
    pilotsListBaseFilter: false,
    pilotsListCompletedFilter: false,
    pilotProfileView: true,
    pilotProfileDeleteEvaluation: false,
    captainDeleteEvaluation: false,
    captainProfileView: true,
    captainsListView: true,
    captainsListBaseFilter: false,
    pilotSearchAutoComplete: false,
  },
  Admin: {
    impersonation: true,
    evaluationFormSubmit: true,
    evaluationFormQuickEdit: true,
    evaluationFormShowMngtComments: true,
    systemTablesMail: false,
    systemTablesRoles: false,
    systemTablesSettings: false,
    systemTablesUserBases: false,
    flightListView: true,
    pilotsListView: true,
    pilotsListBaseFilter: true,
    pilotsListCompletedFilter: false,
    pilotProfileView: true,
    pilotProfileDeleteEvaluation: true,
    captainDeleteEvaluation: true,
    captainProfileView: true,
    captainsListView: true,
    captainsListBaseFilter: true,
    pilotSearchAutoComplete: true,
  },
  "Super Admin": {
    impersonation: true,
    evaluationFormSubmit: true,
    evaluationFormQuickEdit: true,
    evaluationFormShowMngtComments: true,
    systemTablesMail: true,
    systemTablesRoles: true,
    systemTablesSettings: true,
    systemTablesUserBases: true,
    flightListView: true,
    pilotsListView: true,
    pilotsListBaseFilter: true,
    pilotsListCompletedFilter: true,
    pilotProfileView: true,
    pilotProfileDeleteEvaluation: true,
    captainDeleteEvaluation: true,
    captainProfileView: true,
    captainsListView: true,
    captainsListBaseFilter: true,
    pilotSearchAutoComplete: true,
  },
}
const roleMap: Record<string, UserRole> = {
  Contributor: "Contributor",
  SrStaffRep: "Sr. Staff Rep",
  Admin: "Admin",
  SuperAdmin: "Super Admin",
}
const roleTypeMap: Record<string, RoleType> = {
  Contributor: RoleType.Contributor,
  SrStaffRep: RoleType.SrStaffRep,
  Admin: RoleType.Admin,
  SuperAdmin: RoleType.SuperAdmin,
}

const LoginAs = ({ match: { params } }: defaultProps) => {
  const employeeId = params.employeeId
  const role = params.role
  const host = process.env["REACT_APP_URL"] ?? "https://www.united.com"
  const goToHomePage = useCallback(() => {
    window.location.href = host
  }, [host])
  useEffect(() => {
    if (!Object.keys(roleMap).includes(role)) {
      goToHomePage()
      return
    }
    const context = templates[roleMap[role]]
    const accessToken = localStorage.getItem("token") ?? ""
    const authorizationHeader: OAuthAxiosRequestHeaders = {
      Authorization: `Bearer ${accessToken}`,
    }
    user
      .get(authorizationHeader, employeeId)
      .then((res) => {
        res.role = roleMap[role]
        res.roleType = roleTypeMap[role]
        res.context = context
        const cypher = CryptoJS.AES.encrypt(
          process.env["REACT_APP_API_KEY"],
          process.env["REACT_APP_API_SECRET"]
        )
        const userData = CryptoJS.AES.encrypt(
          JSON.stringify(res),
          process.env["REACT_APP_API_SECRET"]
        )
        Cookies.set("APP_SECURE", cypher, { secure: true })
        localStorage.setItem("user", userData)
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        goToHomePage()
      })
  }, [host, goToHomePage, role, employeeId])
  return <Loader display />
}

export default withRouter(LoginAs)
