import React from "react"
import { withRouter } from "react-router-dom"
import EvaluationForm from "../../components/Pilot/EvaluationForm"
import MainLayout from "src/components/UI/MainLayout"

interface defaultProps {
  match: {
    params: {
      id: string
    }
  }
}

const EvaluationFormView = ({ match: { params } }: defaultProps) => {
  const { id } = params
  return (
    <MainLayout>
      <div className="ebp-foppt-l-headline">
        <h1>Pilot Evaluation Form</h1>
      </div>
      <EvaluationForm quickEdit={false} id={id} />
    </MainLayout>
  )
}

const NewEvaluationFormView = () => {
  return (
    <MainLayout context="evaluationFormCreateManualView">
      <div className="ebp-foppt-l-headline">
        <h1>Pilot Evaluation Form</h1>
      </div>
      <EvaluationForm quickEdit={false} id={"0"} />
    </MainLayout>
  )
}

export default withRouter(EvaluationFormView)

export { NewEvaluationFormView }
