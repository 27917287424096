import React from "react"
import { Accordion, AccordionPanel } from "atmos-design-system"

import type { PairingListProps } from "./interfaces"
import profile from "../../../public/assets/images/user_profile.jpg"
import EvaluationList from "./EvaluationList"
import { evaluationsToPairingEvaluationsSummary } from "src/api/pairings/PairingsApi"
import type { PilotPairingEvaluations } from "src/api/pairings/interfaces"
import "./styles.scss"

const statusClassNames: Record<string, string> = {
  Submitted: "ebp-foppt-completed-eval",
  Draft: "ebp-foppt-evaluation-in-progress",
}

function PairingList({ pairings }: PairingListProps) {
  const renderPairing = (pairing: PilotPairingEvaluations) => {
    return (
      <div>
        <div className="ebp-foppt-image-cropper-tbl">
          <img src={pairing.pilotPicture ?? profile} alt="pilot" />
        </div>
        <div className="c-icon-text-block__body ebp-foppt-pairing-info">
          <div className="atm-u-typography-preset-5--bold c-icon-text-block__title">
            {pairing.pilotName}
          </div>
          <div className="atm-u-typography-preset-7 c-icon-text-block__text">
            {`${pairing.pairingStartDate} - ${pairing.pairingEndDate}`}
          </div>
        </div>
      </div>
    )
  }

  const renderStatus = (pairing: PilotPairingEvaluations) => {
    return (
      <div className="ebp-foppt-pairing-status">
        <div className={statusClassNames[pairing.status]}>
          {pairing.statusLabel}
        </div>
        {pairing.soonToExpireMessage !== undefined ? (
          <div
            className={
              pairing.status !== "Submitted" ? "ebp-foppt-expiring" : ""
            }
          >
            {pairing.soonToExpireMessage}
          </div>
        ) : null}
      </div>
    )
  }

  return (
    <Accordion>
      {pairings.map((pairing, i) => {
        return (
          <AccordionPanel
            key={`${pairing.pairingNumber}${pairing.pairingStartDate}${pairing.pairingEndDate}-${pairing.pilotEmployeeNo}-${i}`}
            titleContent={renderPairing(pairing)}
            titleAfter={renderStatus(pairing)}
          >
            <EvaluationList
              evaluations={evaluationsToPairingEvaluationsSummary(
                pairing.evaluations
              )}
              statusClassNames={statusClassNames}
            />
          </AccordionPanel>
        )
      })}
    </Accordion>
  )
}

export default PairingList
