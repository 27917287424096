import { useCallback, useReducer } from "react"
import { useDispatch } from "react-redux"
import { pilot } from "src/api"
import type { PilotSummaryFormattedResponse } from "src/api/pilot/interfaces"

interface EvaluationActionPayload {
  evaluationId: number
}

type summaryTableAction =
  | {
      type: "loadPage"
      payload: {
        response: PilotSummaryFormattedResponse
        append: boolean
      }
    }
  | {
      type: "isFetching"
      payload: boolean
    }
  | {
      type: "setToggleStatus"
      payload: EvaluationActionPayload
    }
  | {
      type: "removeEvaluation"
      payload: EvaluationActionPayload
    }
  | {
      type: "closeAllEvaluations"
    }
  | {
      type: "toggleFlag"
    }
  | {
      type: "setId"
      payload: string
    }
  | { type: "lastCall"; payload: boolean }

interface SummaryTableReducerState {
  page: number
  pageSize: number
  lastCall: boolean
  data: EvaluationSummary[]
  flag: boolean
  isFetching: boolean
  id: string
}

const INITIAL_STATE: SummaryTableReducerState = {
  page: 1,
  pageSize: 25,
  lastCall: false,
  data: [],
  flag: false,
  isFetching: true,
  id: "",
}

export function summaryTableReducer(
  state: SummaryTableReducerState,
  action: summaryTableAction
): SummaryTableReducerState {
  switch (action.type) {
    case "isFetching": {
      return { ...state, [action.type]: action.payload }
    }
    case "loadPage": {
      return {
        ...state,
        data: action.payload.append
          ? [...state.data, ...action.payload.response.evaluations]
          : action.payload.response.evaluations,
        isFetching: false,
        page: Number(action.payload.response.pagination.page) + 1,
        lastCall: action.payload.response.evaluations.length < state.pageSize,
      }
    }

    case "setToggleStatus": {
      const { evaluationId } = action.payload
      const elemIndex = state.data.findIndex(
        (elem) => elem.evaluationId === evaluationId
      )
      const newData = [...state.data]
      newData[elemIndex].toggleStatus = !state.data[elemIndex].toggleStatus

      return {
        ...state,
        data: newData,
      }
    }

    case "removeEvaluation": {
      const { evaluationId } = action.payload

      return {
        ...state,
        data: state.data.filter((elem) => elem.evaluationId !== evaluationId),
      }
    }

    case "closeAllEvaluations": {
      const newData = [...state.data]
      newData.forEach((evaluation) => {
        evaluation.toggleStatus = false
      })
      return {
        ...state,
        data: newData,
      }
    }

    case "toggleFlag":
      return {
        ...state,
        flag: !state.flag,
      }

    case "setId":
      return {
        ...state,
        id: action.payload,
      }

    case "lastCall": {
      return {
        ...state,
        lastCall: action.payload,
      }
    }

    default: {
      return {
        ...state,
      }
    }
  }
}

function useSummaryTable() {
  const reduxDispatch = useDispatch()
  const [summary, dispatch] = useReducer(summaryTableReducer, INITIAL_STATE)

  const getParams = useCallback(
    (append: boolean, flag?: boolean) => {
      const params = new URLSearchParams()
      params.append("page", append ? String(summary.page) : "1")
      params.append("pageSize", String(summary.pageSize))
      if (flag ?? summary.flag) params.append("flag", "true")

      return params
    },
    [summary.flag, summary.page, summary.pageSize]
  )

  const loadEvaluations = useCallback(
    (append = true, id?: string, flag?: boolean) => {
      dispatch({ type: "isFetching", payload: true })
      pilot
        .getSummary(id ?? summary.id, getParams(append, flag))
        .then((response) => {
          dispatch({ type: "loadPage", payload: { append, response } })
        })
        .catch((err) => {
          dispatch({ type: "isFetching", payload: false })
          if (
            err?.code === "ERR_NETWORK" ||
            (err?.response !== undefined && err.response?.status >= 400)
          ) {
            dispatch({ type: "lastCall", payload: false })
            reduxDispatch({
              type: "alert",
              payload: {
                id: "Summary__fetchingFailed",
                display: true,
                message: "Fetching Summary failed",
                type: "error",
              },
            })
          }
        })
    },
    [getParams, reduxDispatch, summary.id]
  )

  const toggleStatus = useCallback((payload: EvaluationActionPayload) => {
    dispatch({ type: "setToggleStatus", payload })
  }, [])

  const removeEvaluation = useCallback((payload: EvaluationActionPayload) => {
    dispatch({ type: "removeEvaluation", payload })
    dispatch({ type: "closeAllEvaluations" })
  }, [])

  const closeAllEvaluations = useCallback(() => {
    dispatch({ type: "closeAllEvaluations" })
  }, [])

  const toggleFlag = useCallback(() => {
    dispatch({ type: "toggleFlag" })
    loadEvaluations(false, undefined, !summary.flag)
  }, [loadEvaluations, summary.flag])

  const setId = useCallback(
    (id: string) => {
      dispatch({ type: "setId", payload: id })
      loadEvaluations(false, id)
    },
    [loadEvaluations]
  )

  return {
    summary,
    loadEvaluations,
    toggleStatus,
    removeEvaluation,
    closeAllEvaluations,
    toggleFlag,
    setId,
  }
}

export default useSummaryTable
