import type { ApiRequest } from "src/api/apiInterfaces"
import type {
  CaptainDetailsResponse,
  CaptainEvaluationsFormattedResponse,
  CaptainEvaluationsResponse,
  GetCaptainEvaluationParams,
} from "./interfaces"

const evaluationsFormat = (
  data: CaptainEvaluationsResponse
): CaptainEvaluationsFormattedResponse => {
  const { evaluations, pagination } = data

  const captainEvaluations: CaptainEvaluation[] = evaluations.map(
    (evaluation) => {
      return {
        toggleStatus: false,
        flag: evaluation.evaluationInfo.flag,
        submittedOn: evaluation.evaluationInfo.submitted?.on ?? "",
        originDate: evaluation.flightInfo.originDate,
        flightNo: evaluation.flightInfo.flightNo,
        originCity: evaluation.flightInfo.originCity,
        destination: evaluation.flightInfo.destination,
        pilotName: evaluation.pilotInfo.name,
        evaluationId: evaluation.evaluationId,
        avg: evaluation.evaluationInfo.avg,
      }
    }
  )
  return {
    evaluations: captainEvaluations,
    pagination,
  }
}

const captainDetailsFormat = (
  response: CaptainDetailsResponse
): CaptainProfile => {
  return {
    base: response.captain.base,
    name: response.captain.name,
    picture: response.captain.picture,
    role: response.captain.role,
  }
}

const CaptainApi = (request: ApiRequest, getEndpoint: CallableFunction) => {
  return {
    getEvaluations: async (params: GetCaptainEvaluationParams) =>
      await request
        .get<CaptainEvaluationsResponse>(getEndpoint("captain_evaluations"), {
          params,
        })
        .then((data: CaptainEvaluationsResponse) => evaluationsFormat(data)),
    getDetails: async (id: string) =>
      await request
        .get<CaptainDetailsResponse>(getEndpoint("captain", id))
        .then((data: CaptainDetailsResponse) => {
          return captainDetailsFormat(data)
        }),
  }
}

export default CaptainApi
