import type { ApiRequest } from "src/api/apiInterfaces"

const SearchApi = (request: ApiRequest, getEndpoint: CallableFunction) => {
  return {
    get: async (params: URLSearchParams) =>
      await request.get<SearchResults[]>(getEndpoint("search"), { params }),
  }
}

export default SearchApi
