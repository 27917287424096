import React from "react"
import { Heading, Grid, GridItem } from "atmos-design-system"
import Card from "../UI/Card"

interface CaptainCardProps {
  captainName: string
  captainPicture?: string | null
  base: string
  role: string
}

function CaptainCard({
  captainName = "",
  captainPicture = null,
  base = "",
  role = "",
}: CaptainCardProps): JSX.Element {
  return (
    <Card picture={captainPicture}>
      <Heading tagName="h4">{captainName}</Heading>
      {role !== "" && <Heading tagName="h5">{role}</Heading>}
      <div className="ebp-foppt-l-padding">
        <Grid>
          <GridItem>
            <b>Base</b>
            <p>{base}</p>
          </GridItem>
        </Grid>
      </div>
    </Card>
  )
}

export default CaptainCard
