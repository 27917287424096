import initialValues from "./initialValues"
import { legacy_createStore as createStore } from "redux"
import type { ReducerAction } from "./interfaces"

const Reducer = (state = initialValues, action: ReducerAction) => {
  switch (action.type) {
    case "alert": {
      if (action.payload.dismissible ?? true) {
        const alertExists = state.alerts.some(
          (item) => item.id === action.payload.id
        )

        if (alertExists) {
          return state
        }

        return {
          ...state,
          alerts: [
            ...state.alerts,
            {
              ...action.payload,
            },
          ],
        }
      }

      const alertStaticExists = state.staticAlerts.some(
        (item) => item.id === action.payload.id
      )

      if (alertStaticExists) {
        return state
      }

      return {
        ...state,
        staticAlerts: [
          ...state.staticAlerts,
          {
            ...action.payload,
          },
        ],
      }
    }
    case "removeAlerts": {
      // Normal alerts are removed one by one with the timer in header
      const newArray = state.alerts.slice(1)
      return {
        ...state,
        alerts: newArray,
      }
    }
    case "removeStaticAlerts": {
      // Static alerts are only removed on page change, so we remove all
      return {
        ...state,
        staticAlerts: [],
      }
    }
    case "user": {
      return { ...state, user: { ...action.payload } }
    }
    case "loggedUser": {
      return { ...state, loggedUser: { ...action.payload } }
    }
    case "impersonated": {
      return { ...state, impersonated: action.payload }
    }
    case "pilot": {
      return { ...state, pilot: action.payload }
    }
    case "loader": {
      return { ...state, loader: !state.loader }
    }
    default: {
      return state
    }
  }
}

const store = createStore(Reducer)
export { store }
