import React from "react"
import App from "./App"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { BrowserRouter } from "react-router-dom"
import { datadogRum } from "@datadog/browser-rum"
import { store } from "./store"
import { onDev } from "./utils/functions"
import "atmos-design-system/dist/css/atmos.css"
import "./App.scss"

const rootNode = document.getElementById("root")
ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>,
  rootNode
)

if (!onDev()) {
  datadogRum.init({
    applicationId: "5e9457c7-a140-467f-a697-1e9e26bcd748",
    clientToken: "pubf1152972e3a7369442b1a763a92a0d53",
    site: "datadoghq.com",
    service: "flight-ops-probationary-tool",
    env: "development",
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
  })

  datadogRum.startSessionReplayRecording()
}
