import React, { useEffect, useState } from "react"
import { Button, TextareaField } from "atmos-design-system"
import CustomTable from "src/components/UI/Table/Table"
import { settings } from "src/api"
import { useDispatch } from "react-redux"
import { EditorHtml } from "../UI/EditorHtml"

const Settings = () => {
  const columns: Array<Column<SettingsData>> = [
    {
      field: "key",
      title: "Key",
    },
    {
      field: "value",
      title: "Value",
      render: (row: SettingsData) => {
        return (
          <>
            {row.type === "Text" && (
              <TextareaField
                defaultValue={row.value}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                  setData((prevState) => {
                    return {
                      ...prevState,
                      [row.key]: {
                        errors: false,
                        value: e.target.value,
                        type: row.type,
                      },
                    }
                  })
                }}
                error={row.errors}
              />
            )}

            {row.type === "Markup" && (
              <>
                <EditorHtml
                  content={row.value ?? ""}
                  onMarkdown={(value: string) => {
                    setData((prevState) => {
                      return {
                        ...prevState,
                        [row.key]: { errors: false, value, type: row.type },
                      }
                    })
                  }}
                  error={row.errors}
                />
              </>
            )}
          </>
        )
      },
    },
    {
      field: "key",
      title: "",
      render: (row: SettingsData) => {
        return (
          <Button
            text="SAVE"
            onClick={() => {
              updateSetting(row)
            }}
          ></Button>
        )
      },
    },
  ]
  const [data, setData] = useState<SettingsRecord>({})
  const [initialData, setInitialData] = useState<SettingsRecord>({})
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isInitialLoading, setIsInitialLoading] = useState<boolean>(true)
  const dispatch = useDispatch()

  const updateSetting = (setting: SettingsData) => {
    let error: ErrorType = false
    if (setting.value === "") {
      error = {
        message: "This field is required",
      }
    } else if (setting.value === initialData[setting.key].value)
      error = {
        message: "The value has not been modified",
      }
    if (error !== false) {
      setData((prevState) => {
        return {
          ...prevState,
          [setting.key]: {
            errors: error,
            value: setting.value,
            type: setting.type,
          },
        }
      })
      return
    }
    setIsLoading(true)
    settings
      .update(setting.key, setting.value)
      .then(() => {
        setInitialData((prevState) => {
          return {
            ...prevState,
            [setting.key]: {
              errors: false,
              value: setting.value,
              type: setting.type,
            },
          }
        })
        dispatch({
          type: "alert",
          payload: {
            id: `Settings__${setting.key}_updatedSuccess`,
            display: true,
            message: "Setting successfully updated.",
            type: "success",
          },
        })
      })
      .catch((err) => {
        if (err.response !== undefined && err.response.status >= 400) {
          dispatch({
            type: "alert",
            payload: {
              id: `Settings__${setting.key}_updatedError`,
              display: true,
              message: "Updating Setting failed",
              type: "error",
            },
          })
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    if (!isInitialLoading) {
      return
    }
    settings
      .get()
      .then((response) => {
        setData(response)
        setInitialData(response)
      })
      .catch((err) => {
        if (err.response !== undefined && err.response.status >= 400) {
          dispatch({
            type: "alert",
            payload: {
              id: `Settings__fetchingListFailed`,
              display: true,
              message: "Fetching Settings list failed",
              type: "error",
            },
          })
        }
      })
      .finally(() => {
        setIsInitialLoading(false)
      })
  }, [dispatch, isInitialLoading])

  return (
    <CustomTable
      columns={columns}
      data={Object.keys(data).map((setting) => {
        return {
          key: setting,
          type: data[setting].type,
          value: data[setting].value,
          errors: data[setting].errors,
        }
      })}
      loading={isLoading || isInitialLoading}
      tableType="Settings"
    />
  )
}

export default Settings
