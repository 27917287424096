import React from "react"
import { TableBody } from "atmos-design-system"
import { CustomRow } from "./Row"

export function CustomBody<T>({
  data,
  columns,
  rowRenderer,
  onRowClick,
}: BodyProps<T>) {
  return (
    <TableBody>
      {data.map((row, index: number) => {
        return rowRenderer !== undefined ? (
          rowRenderer(row, index, columns)
        ) : (
          <CustomRow<T>
            key={`row-${index}`}
            row={row}
            columns={columns}
            rowIndex={index}
            onRowClick={onRowClick}
          />
        )
      })}
    </TableBody>
  )
}
