import React, { Fragment, useEffect, useState } from "react"
import CustomTable from "src/components/UI/Table/Table"
import {
  Button,
  ButtonGroup,
  Heading,
  Icon,
  Checkbox,
  Modal,
  ModalBody,
  ModalHeader,
  TableCell,
  TableRow,
  TextareaField,
} from "atmos-design-system"
import Popover, { PopoverPlacement } from "@idui/react-popover"
import { CustomCell } from "src/components/UI/Table/Cell"
import EvaluationForm from "../EvaluationForm"
import { evaluations } from "src/api"
import { useDispatch, useSelector } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash, faFlag } from "@fortawesome/free-solid-svg-icons"
import "./styles.scss"
import useSummaryTable from "./hooks/useSummaryTable"
import type { UserState } from "src/api/user/interfaces"
import GoTop from "../../UI/GoTop"
import { IntersectionObserverElement } from "src/components/UI/IntersectionObserverElement"

interface SummaryProps {
  id: string
}

function Summary({ id = "" }: SummaryProps) {
  const dispatch = useDispatch()
  const context = useSelector((state: UserState) => state.user.context)

  const {
    summary,
    loadEvaluations,
    toggleStatus,
    removeEvaluation,
    closeAllEvaluations,
    toggleFlag,
    setId,
  } = useSummaryTable()

  const [selectedEvaluationId, setSelectedEvaluationId] = useState<number>(-1)
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [modalError, setModalError] = useState<boolean>(false)
  const [modalInput, setModalInput] = useState<string>("")
  const [flagModalOpen, setFlagModalOpen] = useState<boolean>(false)
  const [isDeleting, setIsDeleting] = useState<boolean>(false)

  const columns: Array<Column<EvaluationSummary>> = [
    {
      field: "flag",
      title: "",
      sort: true,
      sortIcon: (
        <Checkbox label="" name="checkbox" checked={summary.flag} readOnly />
      ),
      render: (row) => {
        return (
          <p>
            {row.flag && (
              <Popover
                content="Evaluation has at least one 4 or 5 rating"
                placement={PopoverPlacement.right}
              >
                <FontAwesomeIcon icon={faFlag} className="ebp-foppt-l-icon" />
              </Popover>
            )}
          </p>
        )
      },
    },
    {
      field: "submittedOn",
      title: "Submitted Date",
    },
    {
      field: "originDate",
      title: "Date",
    },
    {
      field: "avg",
      title: "Avg Rating",
    },
    {
      field: "flightNo",
      title: "Flight",
      render: (row: EvaluationSummary) => {
        return `${row.flightNo}|${row.originCity} — ${row.destination}`
      },
    },
    {
      field: "submittedBy",
      title: "Reviewer",
    },
    {
      field: "evaluationId",
      title: "",
      render: (row: EvaluationSummary) => {
        return context.pilotProfileDeleteEvaluation ? (
          <a
            onClick={() => {
              setSelectedEvaluationId(row.evaluationId)
              setModalOpen(true)
            }}
          >
            <FontAwesomeIcon icon={faTrash} className="ebp-foppt-l-icon" />
          </a>
        ) : null
      },
    },
    {
      field: "evaluationId",
      title: "",
      render: (row: EvaluationSummary) => {
        return (
          <a
            onClick={() => {
              toggleStatus({ evaluationId: row.evaluationId })
            }}
          >
            <Icon name={row.toggleStatus ? "caret-down" : "caret-right"} />
          </a>
        )
      },
    },
  ]
  const rowRenderer = (
    row: EvaluationSummary,
    index: number,
    columns: Array<Column<EvaluationSummary>>
  ) => {
    const extraRow = (
      <TableRow key={`extrarow-${index}`}>
        <TableCell colSpan={8}>
          <EvaluationForm id={row.evaluationId} quickEdit />
        </TableCell>
      </TableRow>
    )
    return (
      <Fragment key={`fragment-${index}`}>
        <TableRow key={`row-${index}`} className="ebp-foppt-l-table__row">
          {columns.map((column, columnIndex) => {
            return (
              <CustomCell
                column={{
                  ...column,
                  rowSpan: 1,
                }}
                row={row}
                key={`${column.field as string}-${index}-${columnIndex}`}
              />
            )
          })}
        </TableRow>
        {row.toggleStatus && extraRow}
      </Fragment>
    )
  }

  const changeSortOptions = (
    _options: OptionsChangeFunctionParams<EvaluationSummary>
  ) => {
    if (summary.data.some((evaluation) => evaluation.toggleStatus)) {
      setFlagModalOpen(true)
    } else {
      toggleFlag()
    }
  }

  const handleDeleteEvaluation = () => {
    if (modalInput.length > 0) {
      setIsDeleting(true)
      evaluations
        .save(selectedEvaluationId, {
          isDelete: true,
          managementComments: modalInput,
        })
        .then(() => {
          removeEvaluation({
            evaluationId: selectedEvaluationId,
          })
        })
        .catch((err) => {
          if (err.response !== undefined && err.response.status >= 400) {
            dispatch({
              type: "alert",
              payload: {
                id: "Summary__deletingEvaluationFailed",
                display: true,
                message: "Deleting Evaluation failed",
                type: "error",
              },
            })
          }
        })
        .finally(() => {
          cleanCommentsInput()
          setModalOpen(false)
          setIsDeleting(false)
        })
    } else setModalError(true)
  }

  function cleanCommentsInput() {
    const input = document.getElementById(
      "input-mngt-comments"
    ) as HTMLInputElement
    input.value = ""
    setModalOpen(false)
    setModalError(false)
    setModalInput("")
  }

  useEffect(() => {
    if (id !== summary.id) {
      setId(id)
    }
  }, [id, setId, summary.id])

  const isIntersecting = () => {
    if (!summary.isFetching && !summary.lastCall) loadEvaluations()
  }

  return (
    <>
      <Modal
        dismissible={true}
        isActive={modalOpen}
        onClose={cleanCommentsInput}
        ariaLabelledBy="modal-heading-1"
        ariaDescribedBy="modal-description-1"
      >
        <ModalHeader>
          <Heading id="modal-heading-1" tagName="h3">
            Do you want to delete this evaluation?
          </Heading>
        </ModalHeader>
        <ModalBody>
          <div>
            {summary.data.some((evaluation) => evaluation.toggleStatus) && (
              <p>Deleting this will cause all open evaluations to close</p>
            )}
            <TextareaField
              id="input-mngt-comments"
              placeholder={"Reason of deletion"}
              required
              error={
                modalError
                  ? {
                      message: "The field can't be empty",
                      aria: "The field can't be empty",
                    }
                  : null
              }
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                setModalInput(e.target.value)
              }}
            />
            <div className="ebp-foppt-l-items--right">
              <ButtonGroup>
                <Button text="Cancel" onClick={cleanCommentsInput} />
                <Button
                  className="ebp-foppt-l-delete-button"
                  text="Confirm"
                  onClick={handleDeleteEvaluation}
                />
              </ButtonGroup>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        dismissible={true}
        isActive={flagModalOpen}
        onClose={() => {
          setFlagModalOpen(false)
        }}
        ariaLabelledBy="modal-heading-1"
        ariaDescribedBy="modal-description-1"
      >
        <ModalHeader>
          <Heading id="modal-heading-1" tagName="h3">
            Sorting will close all open evaluations
          </Heading>
        </ModalHeader>
        <ModalBody>
          <div>
            <p>Do you want to continue?</p>
            <div className="ebp-foppt-l-items--right">
              <ButtonGroup>
                <Button
                  text="Cancel"
                  onClick={() => {
                    setFlagModalOpen(false)
                  }}
                />
                <Button
                  text="Continue"
                  onClick={() => {
                    closeAllEvaluations()
                    setFlagModalOpen(false)
                    toggleFlag()
                  }}
                />
              </ButtonGroup>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <CustomTable<EvaluationSummary>
        columns={columns}
        options={{
          sortColumn: "flag",
          sortDirection: summary.flag ? "Des" : "Asc",
        }}
        data={summary.data}
        loading={summary.isFetching || isDeleting}
        tableType="evaluations"
        onOptionsChange={changeSortOptions}
        rowRenderer={rowRenderer}
      />
      <IntersectionObserverElement callback={isIntersecting} />
      <GoTop scrollTop={200} />
    </>
  )
}

export default Summary
