import type { ApiRequest } from "src/api/apiInterfaces"
import type {
  PilotSummaryResponse,
  PilotSummaryFormattedResponse,
  PilotResponse,
  PilotSummaryParams,
} from "./interfaces"

const pilotSummaryFormat = (
  response: PilotSummaryResponse
): PilotSummaryFormattedResponse => {
  return {
    evaluations: response.evaluations.map((evaluation) => {
      return {
        toggleStatus: false,
        flag: evaluation.evaluationInfo.flag,
        flightNo: evaluation.flightInfo.flightNo,
        destination: evaluation.flightInfo.destination,
        originCity: evaluation.flightInfo.originCity,
        originDate: evaluation.flightInfo.originDate,
        submittedBy: evaluation.evaluationInfo.submitted?.by ?? "",
        submittedOn: evaluation.evaluationInfo.submitted?.on ?? "",
        evaluationId: evaluation.evaluationId,
        avg: evaluation.evaluationInfo.avg,
        reviewedFor: evaluation.evaluationInfo.submitted?.by ?? "",
      }
    }),
    pagination: response.pagination,
  }
}

const pilotScorecardFormat = (response: PilotResponse): PilotProfile => {
  const gradings = response.gradings ?? []
  return {
    pilotInfo: response.pilot,
    gradings: gradings.map((category) => {
      const subcategories = category.subcategories.map((subcat) => {
        return { ...subcat, avg: Number(subcat.avg) }
      })
      return {
        id: category.id,
        title: category.title,
        avg: Number(category.avg),
        subcategories,
      }
    }),
  }
}

const searchPilotsFormat = (
  response: SearchResults
): FormattedSearchEmployeeResponse[] => {
  return response.pilots.map((e) => ({
    id: e.employeeNo,
    value: `${e.name} (${e.employeeNo})`,
    picture: e.picture,
    base: e.base,
    employeeNo: e.employeeNo,
    fleetType: e.fleetType,
    name: e.name,
    dateOfHire: e.dateOfHire,
    estimatedProbationEndDate: e.estimatedProbationEndDate,
    status: e.status,
  }))
}

const PilotApi = (request: ApiRequest, getEndpoint: CallableFunction) => {
  return {
    getScorecard: async (id: string) =>
      await request
        .get<PilotResponse>(getEndpoint("pilot", id))
        .then((data) => {
          return pilotScorecardFormat(data)
        }),
    getSummary: async (id: string, params: URLSearchParams) =>
      await request
        .get<PilotSummaryResponse>(getEndpoint("pilotSummary", id), { params })
        .then((data) => {
          return pilotSummaryFormat(data)
        }),
    searchPilots: async (params: URLSearchParams) =>
      await request
        .get<SearchResults>(getEndpoint("search"), { params })
        .then((data) => {
          return searchPilotsFormat(data)
        }),
  }
}

export default PilotApi
