import { onDev } from "src/utils/functions"

import type {
  AuthRequest,
  OAuthAxiosRequestHeaders,
} from "src/api/apiInterfaces"

const TokenApi = (
  auth: AuthRequest,
  authURL: string,
  getEndpoint: CallableFunction
) => {
  return {
    get: async (params: URLSearchParams, headers: OAuthAxiosRequestHeaders) => {
      if (onDev()) {
        return await auth.get<AccessToken>(
          getEndpoint("token"),
          authURL,
          headers
        )
      } else {
        return await auth.post<AccessToken>(
          getEndpoint("token"),
          params,
          headers
        )
      }
    },
  }
}

export default TokenApi
