import React from "react"
import { TableCell } from "atmos-design-system"

export function CustomCell<T>({ row, column, extraIndex }: CellProps<T>) {
  return (
    <TableCell
      rowSpan={column.rowSpan ?? 1}
      alignment={column.alignment ?? "center"}
      data-label={column.title}
      tagName="td"
      className={column.className}
    >
      {typeof column.render === "function"
        ? column.render(row, extraIndex)
        : row[column.field]}
    </TableCell>
  )
}
