const gradingOptions = [
  {
    label: "- Select one option -",
    value: "-1",
  },
  {
    label: "1 - Excellent",
    value: "1",
  },
  {
    label: "2 - Above Expectations",
    value: "2",
  },
  {
    label: "3 - Expected Performance",
    value: "3",
  },
  {
    label: "4 - Meets Minimum Standards",
    value: "4",
  },
  {
    label: "5 - Unsatisfactory",
    value: "5",
  },
  {
    label: "N/A - Not Applicable",
    value: "0",
  },
]

const data: EvaluationDetail = {
  saved: false,
  evaluationId: 0,
  status: "",
  fom: [""],
  flightInfo: {
    flightNo: "",
    originDate: "",
    originCity: "",
    destination: "",
  },
  flightType: "-1",
  flightTypeTitle: "",
  isManual: false,
  pilotInfo: {
    status: "Completed",
    name: "-",
    picture: "",
    employeeNo: "-",
    base: "-",
    fleetType: "-",
    dateOfHire: "-",
    estimatedProbationEndDate: "-",
  },
  captainInfo: {
    name: "-",
    employeeNo: "-",
    base: "-",
  },
  evaluationInfo: {
    status: "",
    flag: false,
    avg: "-",
    submitted: {
      on: "-",
      by: "-",
    },
    modified: {
      on: "-",
      by: "-",
    },
  },
  comments: "",
  captainRedactedComments: null,
  isCaptainCommentsRedacted: false,
  isCaptainCommentsApproved: false,
  managementComments: [
    {
      date: "",
      reviewer: "",
      comments: "",
    },
  ],
  gradings: [
    {
      id: 0,
      title: "",
      na: true,
      collapsed: true,
      optional: false,
      subcategories: [
        {
          id: 0,
          title: "",
          description: "",
          value: "",
          comments: "",
          isRedacted: false,
          isApproved: false,
          redactedComments: null,
        },
      ],
    },
  ],
}

const statuses = {
  NEW: "new",
  DRAFT: "draft",
  SUBMITTED: "submitted",
  COMPLETED: "completed",
}

const validationRegex = {
  inputFlightNumber: /^UA[0-9]{0,4}$/,
  inputCity: /^[aA-zZ]{0,3}$/,
}

const SIMULATION_CITY = "FTC"

export { data, gradingOptions, statuses, validationRegex, SIMULATION_CITY }
