export const PilotsScoreCardReducer = (
  state: PilotsScorecardState,
  action: PilotsScorecardActions
): PilotsScorecardState => {
  switch (action.type) {
    case "setData": {
      return {
        ...state,
        isFetching: false,
        data: {
          gradings: action.payload.gradings,
          evaluationComments: action.payload.evaluationComments,
        },
      }
    }

    case "loadData": {
      return {
        ...state,
        isFetching: true,
      }
    }
  }
}
