import React from "react"
import { faSliders } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Icon,
  TableCell,
  TableHeader,
  TableRow,
  TextInput,
  Select,
} from "atmos-design-system"

export function CustomHeader<T>({
  columns,
  options,
  sortClick,
  filterChange,
}: HeaderProps<T>) {
  return (
    <TableHeader>
      <TableRow key="header-row" className="ebp-foppt-l-table-header">
        {columns.map((column, index) => {
          return (
            <TableCell tagName="th" key={`${column.field as string}-${index}`}>
              <div
                className={
                  "ebp-foppt-l-table-header-cell " +
                  (column.alignment ?? "center")
                }
              >
                <div className="ebp-foppt-table-header-title">
                  {column.title}
                </div>
                <div className="ebp-foppt-table-header-icons">
                  {column.sort === true && (
                    <div
                      className="ebp-foppt-table-header-icon"
                      onClick={() => {
                        sortClick(column.field)
                      }}
                    >
                      {options?.sortColumn === column.field &&
                      options.sortDirection !== undefined ? (
                        column.sortIcon !== undefined ? (
                          column.sortIcon
                        ) : options.sortDirection === "Asc" ? (
                          <Icon name={"triangle-down"} theme="inverted" />
                        ) : (
                          <Icon name={"triangle-up"} theme="inverted" />
                        )
                      ) : (
                        <div>
                          <Icon name={"triangle-up"} theme="inverted" />
                          <Icon name={"triangle-down"} theme="inverted" />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </TableCell>
          )
        })}
      </TableRow>
      {columns.some((column) => column.filter !== undefined) && (
        <TableRow
          key="filters-row"
          className="ebp-foppt-l-table-header-filters"
        >
          {columns.map((column, index) => {
            const filterOptions = column.filter
            return (
              <TableCell
                tagName="th"
                key={`${column.field as string}-${index}-filter`}
              >
                {filterOptions !== undefined && (
                  <div className="ebp-foppt-table-header-filter">
                    {filterOptions.type === "select" ? (
                      <>
                        <FontAwesomeIcon icon={faSliders} />
                        <div className="atm-c-select-field__body">
                          <Select
                            onChange={(
                              e: React.ChangeEvent<HTMLSelectElement>
                            ) => {
                              filterChange(column.field, e.target.value)
                            }}
                            items={filterOptions.options}
                          />
                          <Icon
                            name="caret-down"
                            className="atm-c-select-field__icon"
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <FontAwesomeIcon icon={faSliders} />
                        <TextInput
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            filterChange(column.field, e.target.value)
                          }}
                        />
                      </>
                    )}
                  </div>
                )}
              </TableCell>
            )
          })}
        </TableRow>
      )}
    </TableHeader>
  )
}
