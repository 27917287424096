import { useCallback, useReducer } from "react"
import { data as INITIAL_STATE } from "../constants"
import { formReducer } from "./newEvaluationFormReducer"

export function formCopyReducer(
  state: EvaluationDetail,
  action: FormCopyReducerAction
): EvaluationDetail {
  switch (action.type) {
    case "copyData": {
      return action.payload
    }
    default: {
      return state
    }
  }
}

function useNewEvaluationForm() {
  const [form, dispatch] = useReducer(formReducer, INITIAL_STATE)
  const [formCopy, dispatchCopy] = useReducer(formCopyReducer, INITIAL_STATE)

  const fillData = useCallback((payload: EvaluationDetail) => {
    dispatch({ type: "fillData", payload })
  }, [])

  const copyData = useCallback((payload: EvaluationDetail) => {
    dispatchCopy({ type: "copyData", payload })
  }, [])

  const setRating = useCallback((payload: RateAndCommentsPayload) => {
    dispatch({ type: "setRating", payload })
  }, [])

  const setOptionalCategories = useCallback((payload: boolean) => {
    dispatch({ type: "setOptionalCategories", payload })
  }, [])

  const setSubComments = useCallback((payload: RateAndCommentsPayload) => {
    dispatch({ type: "setSubComments", payload })
  }, [])

  const setRedactedComments = useCallback((payload: string) => {
    dispatch({ type: "setRedactedComments", payload })
  }, [])

  const setRedacted = useCallback((payload: boolean) => {
    dispatch({ type: "setRedacted", payload })
  }, [])

  const setApproval = useCallback((payload: boolean) => {
    dispatch({ type: "setApproval", payload })
  }, [])

  const cancelRedactedComments = useCallback(() => {
    dispatch({ type: "cancelRedactedComments" })
  }, [])

  const setSubcategoryRedactedComments = useCallback(
    (payload: RateAndCommentsPayload) => {
      dispatch({ type: "setSubcategoryRedactedComments", payload })
    },
    []
  )

  const setSubcategoryRedacted = useCallback((payload: RedactedPayload) => {
    dispatch({ type: "setSubcategoryRedacted", payload })
  }, [])

  const setSubcategoryApproval = useCallback((payload: RedactedPayload) => {
    dispatch({ type: "setSubcategoryApproval", payload })
  }, [])

  const cancelSubcategoryRedactedComments = useCallback(
    (payload: SubcategoryIndex) => {
      dispatch({ type: "cancelSubcategoryRedactedComments", payload })
    },
    []
  )

  const setComment = useCallback((payload: string) => {
    dispatch({ type: "setComments", payload })
  }, [])

  const setPilot = useCallback((payload: SearchEmployeeResponse) => {
    dispatch({ type: "setPilot", payload })
  }, [])

  const setEvaluator = useCallback((payload: CaptainsInfo) => {
    dispatch({ type: "setEvaluator", payload })
  }, [])

  const setEvaluationId = useCallback((payload: number) => {
    dispatch({ type: "setEvaluationId", payload })
  }, [])

  const setFlightInfo = useCallback((payload: EvaluationFlightInfo) => {
    dispatch({ type: "setFlightInfo", payload })
  }, [])

  const setFlightType = useCallback((payload: string) => {
    dispatch({ type: "setFlightType", payload })
  }, [])

  const saveForm = useCallback(() => {
    dispatch({ type: "saved" })
  }, [])

  const openCategories = useCallback((payload: boolean) => {
    dispatch({ type: "openCategories", payload })
  }, [])

  const toggleChevron = useCallback((payload: number) => {
    dispatch({ type: "toggleChevron", payload })
  }, [])

  return {
    form,
    formCopy,
    fillData,
    copyData,
    setRating,
    setOptionalCategories,
    setSubComments,
    setRedactedComments,
    setRedacted,
    setApproval,
    cancelRedactedComments,
    setSubcategoryRedactedComments,
    setSubcategoryRedacted,
    setSubcategoryApproval,
    cancelSubcategoryRedactedComments,
    setComment,
    setPilot,
    setFlightInfo,
    setFlightType,
    setEvaluator,
    setEvaluationId,
    saveForm,
    openCategories,
    toggleChevron,
  }
}

export default useNewEvaluationForm
