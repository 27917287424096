import React, { useEffect, useCallback, useState } from "react"
import { token, user } from "src/api"
import Cookies from "js-cookie"
import CryptoJS from "crypto-js"
import Loader from "src/components/UI/Loader"
import type { OAuthAxiosRequestHeaders } from "src/api/apiInterfaces"
import { Alert, LayoutContainer } from "atmos-design-system"

function Oauth2() {
  const [notAuthorized, setNotAuthorized] = useState<boolean>(false)
  const [tokenError, setTokenError] = useState<boolean>(false)
  const [loader, setLoader] = useState<boolean>(true)
  const host = process.env["REACT_APP_URL"] ?? "https://www.united.com"
  const logoutPage = `${host}/logout`

  const goToHomePage = useCallback(() => {
    const redirect = sessionStorage.getItem("redirectAfterLogin") ?? host
    sessionStorage.removeItem("redirectAfterLogin")
    window.location.href = redirect
  }, [host])

  function displayAuthorizationError() {
    setLoader(false)
    setNotAuthorized(true)
  }

  function displayTokenError() {
    setLoader(false)
    setTokenError(true)
  }

  useEffect(() => {
    if (window.location.search.toString().includes("code")) {
      const queryParams = new URLSearchParams(window.location.search)
      const authCode = queryParams.get("code") ?? ""
      const redirectUri = process.env["AUTH_REDIRECT_URI"] ?? ""
      const domain = process.env["AUTH_DOMAIN"] ?? ""
      const authorization = process.env["AUTH_AUTHORIZATION"] ?? ""
      const params = new URLSearchParams()

      params.append("code", authCode)
      params.append("grant_type", "AUTHORIZATION_CODE")
      params.append("redirect_uri", redirectUri)
      params.append("response_type", "token")
      params.append(
        "code_verifier",
        sessionStorage.getItem("code_verifier") ?? ""
      )

      const headers: OAuthAxiosRequestHeaders = {
        "X-OAUTH-IDENTITY-DOMAIN-NAME": domain,
        Authorization: `Basic ${authorization}`,
      }
      token
        .get(params, headers)
        .then((response) => {
          const accessToken = response.access_token ?? ""
          const authorizationHeader: OAuthAxiosRequestHeaders = {
            Authorization: `Bearer ${accessToken}`,
          }

          user
            .get(authorizationHeader)
            .then((res) => {
              const cypher = CryptoJS.AES.encrypt(
                process.env["REACT_APP_API_KEY"],
                process.env["REACT_APP_API_SECRET"]
              )
              const userData = CryptoJS.AES.encrypt(
                JSON.stringify(res),
                process.env["REACT_APP_API_SECRET"]
              )
              Cookies.set("APP_SECURE", cypher, { secure: true })
              localStorage.setItem("token", accessToken)
              localStorage.setItem("user", userData)
              localStorage.setItem(
                "captainNotice",
                res.userSettings.showCaptainNotice ? "on" : "off"
              )
              goToHomePage()
            })
            .catch(() => {
              displayAuthorizationError()
            })
        })
        .catch((error) => {
          console.error(error)
          displayTokenError()
        })
    } else {
      displayTokenError()
    }
  }, [goToHomePage])

  useEffect(() => {
    if (notAuthorized || tokenError) {
      setTimeout(() => {
        window.location.href = logoutPage
      }, 8000)
    }
  }, [notAuthorized, logoutPage, tokenError])

  return (
    <>
      <Loader display={loader} />
      {(notAuthorized || tokenError) && (
        <div className="ebp-foppt-l-center-page">
          <div>
            <LayoutContainer>
              <Alert
                iconName="error"
                title={notAuthorized ? "Ineligible" : "Error"}
                variant="error"
              >
                <p>
                  {notAuthorized
                    ? "Our records indicate you are ineligible to use this system. You will be logged off now."
                    : "We are experiencing an issue. Please try again later. If the issue persists, please contact the support team."}
                </p>
              </Alert>
            </LayoutContainer>
          </div>
        </div>
      )}
    </>
  )
}

export default Oauth2
