import React from "react"
import { Icon } from "atmos-design-system"
import "./styles.scss"

interface AccordionHeaderProps {
  date?: string
  sortDirection?: string
  setSort?: CallableFunction
  title?: string
  categories?: string
  titleAfter?: string
  isSortable: boolean
}

export const AccordionHeader = ({
  date,
  sortDirection,
  setSort,
  title,
  categories,
  titleAfter,
  isSortable,
}: AccordionHeaderProps) => {
  return (
    <div className="ebp-foppt-l-accordion-headers-bar">
      {isSortable ? (
        <div className="ebp-foppt-l-accordion-headers-bar-option">
          <div
            className="ebp-foppt-l-accordion-headers-bar-option-icons clickable"
            onClick={() => {
              setSort?.()
            }}
          >
            {sortDirection !== undefined ? (
              sortDirection === SortType.Asc ? (
                <Icon name={"triangle-down"} theme="inverted" />
              ) : (
                <Icon name={"triangle-up"} theme="inverted" />
              )
            ) : (
              <div>
                <Icon name={"triangle-up"} theme="inverted" />
                <Icon name={"triangle-down"} theme="inverted" />
              </div>
            )}
          </div>
          <span>{date}</span>
          <span className="ebp-foppt-l-accordion-headers-categories-title">
            {categories}
          </span>
        </div>
      ) : (
        <div className="ebp-foppt-l-accordion-headers-bar-option">
          <span>{title}</span>
        </div>
      )}

      <div className="ebp-foppt-l-accordion-headers-bar-option">
        <span>{titleAfter}</span>
      </div>
    </div>
  )
}
