import type { ApiRequest } from "src/api/apiInterfaces"

export function flightAPItoFlightData(
  flights: FlightInfoEvaluations[]
): FlightData[] {
  return flights.map((flight) => {
    return {
      ...flight.flightInfo,
      originDate: new Date(flight.flightInfo.originDate),
      pilots: flight.evaluations.map((evaluation) => {
        return {
          flightInfo: {
            ...evaluation.flightInfo,
            originDate: new Date(evaluation.flightInfo.originDate),
          },
          status: evaluation.evaluationInfo.status,
          picture: evaluation.pilotInfo.picture,
          evaluationId: evaluation.evaluationId,
          name: evaluation.pilotInfo.name,
          employeeNo: evaluation.pilotInfo.employeeNo,
        }
      }),
    }
  })
}

const FlightsApi = (request: ApiRequest, getEndpoint: CallableFunction) => {
  return {
    list: async (params: GetFlightsParams) =>
      await request
        .get<FlightsApiResponse>(getEndpoint("flights"), {
          params,
        })
        .then((data) => {
          return flightAPItoFlightData(data.flightInfoEvaluations)
        }),
  }
}

export default FlightsApi
