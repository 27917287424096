import type { ReduxState } from "./interfaces"

const initialValues: ReduxState = {
  loader: false,
  impersonated: false,
  loggedUser: {
    uid: "",
    picture: null,
    name: "Guest",
    role: "Contributor",
    roleType: 1,
    base: "",
    jobCode: "",
    jobTitle: "",
    unionCode: "",
    context: {
      impersonation: false,
      evaluationFormSubmit: true,
      evaluationFormQuickEdit: false,
      evaluationFormShowMngtComments: false,
      systemTablesMail: false,
      systemTablesRoles: false,
      systemTablesSettings: false,
      systemTablesUserBases: false,
      flightListView: false,
      pilotsListView: false,
      pilotsListCompletedFilter: false,
      pilotProfileView: false,
      pilotProfileDeleteEvaluation: false,
      captainProfileView: false,
      captainDeleteEvaluation: false,
      captainsListView: false,
      captainsListBaseFilter: false,
      pilotSearchAutoComplete: false,
      evaluationCommentsRedact: false,
      evaluationFormCreateManual: false,
      evaluationFormCreateManualView: false,
      evaluationFormEditEvaluator: false,
      pilotScorecardView: false,
      pilotsListBaseFilter: false,
      searchRequireCompleteID: false,
    },
    userSettings: {
      labels: {
        captainNotice: "",
      },
      showCaptainNotice: true,
    },
    evaluationSettings: {
      backLimitDate: "",
      flightTypes: [],
      today: "",
      specialFlightTypes: [],
    },
  },
  user: {
    uid: "",
    picture: null,
    name: "Guest",
    role: "Contributor",
    base: "",
    roleType: 1,
    jobCode: "",
    jobTitle: "",
    unionCode: "",
    context: {
      impersonation: false,
      evaluationFormSubmit: true,
      evaluationFormQuickEdit: false,
      evaluationFormShowMngtComments: false,
      systemTablesMail: false,
      systemTablesRoles: false,
      systemTablesSettings: false,
      systemTablesUserBases: false,
      flightListView: false,
      pilotsListView: false,
      pilotsListCompletedFilter: false,
      pilotProfileView: false,
      pilotProfileDeleteEvaluation: false,
      captainProfileView: false,
      captainDeleteEvaluation: false,
      captainsListView: false,
      captainsListBaseFilter: false,
      pilotSearchAutoComplete: false,
      evaluationCommentsRedact: false,
      evaluationFormCreateManual: false,
      evaluationFormCreateManualView: false,
      evaluationFormEditEvaluator: false,
      pilotScorecardView: false,
      pilotsListBaseFilter: false,
      searchRequireCompleteID: false,
    },
    userSettings: {
      labels: {
        captainNotice: "",
      },
      showCaptainNotice: true,
    },
    evaluationSettings: {
      backLimitDate: "",
      flightTypes: [],
      today: "",
      specialFlightTypes: [],
    },
  },
  alerts: [
    {
      id: "",
      display: false,
      message: "",
      type: "success", // success | warning | error
      timer: 0,
    },
  ],
  staticAlerts: [
    {
      id: "",
      display: false,
      message: "",
      type: "success", // success | warning | error
    },
  ],
  pilot: null,
}

export default initialValues
