import type { ApiRequest } from "src/api/apiInterfaces"

const settingsFormat = (response: Settings[]): SettingsRecord => {
  return response.reduce((obj, setting) => {
    return {
      ...obj,
      [setting.key]: {
        errors: false,
        value: setting.value,
        type: setting.type,
      },
    }
  }, {})
}

const SettingsApi = (request: ApiRequest, getEndpoint: CallableFunction) => {
  return {
    get: async () =>
      await request
        .get<SettingsResponse>(getEndpoint("settings"))
        .then((data) => settingsFormat(data.settings)),
    update: async (key: string, value: StringNull) =>
      await request.post(getEndpoint("settings"), { key, value }),
  }
}

export default SettingsApi
