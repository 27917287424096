import React from 'react'
import MainLayout from "src/components/UI/MainLayout"

const ErrorPage = () => {
    return (
        <MainLayout>
            <h2>An Error has ocurred.</h2>
        </MainLayout>
    )
}
export default ErrorPage