import type { ApiRequest } from "src/api/apiInterfaces"
import type {
  GetPairingsParams,
  SearchPairingsParams,
  SearchPairingsResponse,
  PairingEvaluationsResponse,
  PairingEvaluationSummary,
} from "./interfaces"

function evaluationsToPairingEvaluationsSummary(
  evaluations: Evaluation[]
): PairingEvaluationSummary[] {
  return evaluations.map(evaluationToPairingEvaluationSummary)
}

function evaluationToPairingEvaluationSummary(
  evaluation: Evaluation
): PairingEvaluationSummary {
  return {
    evaluationId: evaluation.evaluationId,
    flightNo: evaluation.flightInfo.flightNo,
    destination: evaluation.flightInfo.destination,
    originCity: evaluation.flightInfo.originCity,
    originDate: evaluation.flightInfo.originDate,
    evaluationStatus: evaluation.evaluationInfo.status,
    status: evaluation.status,
    soonToExpireMessage: evaluation.soonToExpireMessage,
    isManual: evaluation.isManual,
  }
}

const PairingsApi = (request: ApiRequest, getEndpoint: CallableFunction) => {
  return {
    get: async (params: URLSearchParams) =>
      await request.get<PairingEvaluationsResponse>(getEndpoint("pairings"), {
        params,
      }),
    search: async (params: SearchPairingsParams) =>
      await request.post<SearchPairingsResponse>(
        getEndpoint("search_pairings"),
        params
      ),
  }
}

export default PairingsApi
export {
  evaluationsToPairingEvaluationsSummary,
  evaluationToPairingEvaluationSummary,
}
