import React from "react"
import { useSelector } from "react-redux"
import { Icon, SelectField, TextareaField } from "atmos-design-system"
import { gradingOptions } from "./constants"
import { renderMultilineComments } from "./util"
import type { UserState } from "src/api/user/interfaces"

interface GradingFormProps {
  id: number
  flightType: string
  isSubmitted: boolean
  loader: boolean
  editionMode: boolean
  saved: boolean
  gradings: Grading[]
  updateModalErrorOpen: (payload: boolean) => void
  updateModalSimulatorOpen: (payload: boolean) => void
  updateSafeSave: (payload: boolean) => void
  setRating: (payload: RateAndCommentsPayload) => void
  setSubComments: (payload: RateAndCommentsPayload) => void
  setSubcategoryRedactedComments: CallableFunction
  setSubcategoryRedacted: CallableFunction
  setSubcategoryApproval: CallableFunction
  cancelSubcategoryRedactedComments: CallableFunction
  toggleChevron: (payload: number) => void
}

function displaySubcategoryValue(v: string) {
  if (v === "" || v === null) {
    return "Not Rated"
  }

  const item = gradingOptions.filter((item) => item.value === v)

  return item[0].label
}

export default function GradingForm({
  id,
  flightType,
  isSubmitted,
  gradings,
  loader,
  editionMode,
  saved,
  updateModalErrorOpen,
  updateModalSimulatorOpen,
  updateSafeSave,
  setRating,
  setSubComments,
  toggleChevron,
  setSubcategoryRedactedComments,
  setSubcategoryRedacted,
  setSubcategoryApproval,
  cancelSubcategoryRedactedComments,
}: GradingFormProps) {
  const user = useSelector((state: UserState) => state.user)
  const context = user.context
  const { specialFlightTypes } = user.evaluationSettings
  const isRealFlight = specialFlightTypes.includes(flightType)

  return (
    <>
      {gradings.length > 0 && !loader
        ? gradings.map((category: Category, catIndex: number) => {
            return (
              <React.Fragment key={catIndex}>
                <div className="atm-c-box-section__header ebp-foppt-c-evaluation-form-section__header">
                  <div className="ebp-foppt-c-evaluation-form__grading">
                    <div className="ebp-foppt-c-evaluation-form__grading--descriptions">
                      <h4 className="atm-c-box-section__title">
                        {category.title}
                      </h4>
                    </div>
                    <div className="ebp-foppt-c-evaluation-form__grading--fields">
                      <div className="ebp-foppt-c-evaluation-form__grading--dropdown">
                        <div className="ebp-foppt-c-evaluation-form__grading--select">
                          <div>
                            <h4 className="atm-c-box-section__title">
                              GRADING
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div className="ebp-foppt-c-evaluation-form__grading--comments">
                        <h4 className="atm-c-box-section__title">COMMENTS</h4>
                      </div>
                      <div
                        className={`ebp-foppt-c-evaluation-form__grading--chevron ebp-foppt-c-evaluation-form__grading--chevron--${0}`}
                      >
                        <h4 className="atm-c-box-section__title">
                          <a
                            className="ebp-foppt-u-cursor-hand"
                            onClick={() => {
                              toggleChevron(catIndex)
                            }}
                          >
                            <span
                              className={`ebp-foppt-c-evaluation-form__grading--caret-${
                                category.collapsed ? "down" : "up"
                              }`}
                            >
                              <Icon
                                name={`caret-${
                                  category.collapsed ? "down" : "up"
                                }`}
                              />
                            </span>
                          </a>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  id={`category-${category.id}`}
                  className={`ebp-foppt-c-evaluation-form__grading--categories ebp-foppt-c-evaluation-form__grading--categories--${
                    category.id
                  } ${category.collapsed ? "ebp-foppt-u-hide" : ""}`}
                >
                  {category?.subcategories.map((subcategory, scatIndex) => {
                    const options = [...gradingOptions]

                    if (!isRealFlight && !category.optional) {
                      options.pop()
                    }

                    if (isRealFlight && !category.na) {
                      options.pop()
                    }

                    return (
                      <div
                        key={scatIndex}
                        className="ebp-foppt-c-evaluation-form__grading ebp-foppt-c-evaluation-form__category"
                      >
                        <div className="ebp-foppt-c-evaluation-form__grading--descriptions">
                          <h4>{subcategory.title}</h4>
                          <p>{subcategory.description}</p>
                        </div>
                        <div className="ebp-foppt-c-evaluation-form__grading--fields">
                          <div className="ebp-foppt-c-evaluation-form__grading--dropdown">
                            <div className="ebp-foppt-c-evaluation-form__grading--select">
                              {editionMode ? (
                                <SelectField
                                  label=""
                                  value={
                                    subcategory.value === ""
                                      ? "-1"
                                      : subcategory.value
                                  }
                                  className={
                                    ["-1", ""].includes(subcategory.value) &&
                                    saved
                                      ? `ebp-foppt-u-input-error ebp-foppt-u-input-error--${id} ebp-foppt-u-cursor-hand`
                                      : "ebp-foppt-u-cursor-hand"
                                  }
                                  onChange={(
                                    e: React.ChangeEvent<HTMLSelectElement>
                                  ) => {
                                    const values =
                                      gradings[0].subcategories.filter(
                                        (
                                          _subcat: Subcategory,
                                          subcatIndex: number
                                        ) => subcatIndex !== scatIndex
                                      )

                                    if (
                                      values[0].value === "0" &&
                                      e.target.value === "0" &&
                                      specialFlightTypes.includes(flightType)
                                    ) {
                                      e.preventDefault()
                                      updateModalErrorOpen(true)
                                      return false
                                    }

                                    if (
                                      !isRealFlight &&
                                      e.target.value === "0" &&
                                      !["", null].includes(subcategory.comments)
                                    ) {
                                      updateModalSimulatorOpen(true)
                                      return false
                                    }

                                    updateSafeSave(true)

                                    setRating({
                                      catIndex,
                                      scatIndex,
                                      value: e.target.value,
                                    })

                                    if (
                                      user.context.evaluationCommentsRedact &&
                                      isSubmitted &&
                                      (subcategory.comments === "" ||
                                        subcategory.comments === null)
                                    ) {
                                      setSubcategoryRedacted({
                                        catIndex,
                                        scatIndex,
                                        value: ["4", "5"].includes(
                                          e.target.value
                                        ),
                                      })
                                    }

                                    return true
                                  }}
                                  items={options}
                                />
                              ) : (
                                <div>
                                  {displaySubcategoryValue(subcategory.value)}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="ebp-foppt-c-evaluation-form__grading--comments">
                            {editionMode ? (
                              <>
                                {user.context.evaluationCommentsRedact &&
                                  isSubmitted && (
                                    <div className="ebp-foppt-c-evaluation-form__grading--buttons">
                                      {!subcategory.isRedacted && (
                                        <>
                                          <a
                                            className={
                                              "ebp-foppt-u-cursor-hand"
                                            }
                                            onClick={() => {
                                              setSubcategoryRedacted({
                                                catIndex,
                                                scatIndex,
                                                value: !subcategory.isRedacted,
                                              })
                                              updateSafeSave(true)
                                            }}
                                          >
                                            <Icon name="edit" />
                                            <span>Edit</span>
                                          </a>
                                        </>
                                      )}
                                      {subcategory.comments !== "" &&
                                        subcategory.comments !== null && (
                                          <>
                                            {!subcategory.isRedacted && (
                                              <a
                                                className={`ebp-foppt-u-cursor-hand ${
                                                  subcategory.isApproved
                                                    ? "approved"
                                                    : ""
                                                }`}
                                                onClick={() => {
                                                  setSubcategoryApproval({
                                                    catIndex,
                                                    scatIndex,
                                                    value:
                                                      !subcategory.isApproved,
                                                  })
                                                  updateSafeSave(true)
                                                }}
                                              >
                                                <Icon
                                                  name={`thumbs-${
                                                    subcategory.isApproved
                                                      ? "down"
                                                      : "up"
                                                  }`}
                                                />
                                                <span>
                                                  {subcategory.isApproved
                                                    ? "Unapprove"
                                                    : "Approve"}
                                                </span>
                                              </a>
                                            )}
                                          </>
                                        )}
                                    </div>
                                  )}
                                <TextareaField
                                  label=""
                                  maxLength="750"
                                  placeholder={
                                    ["4", "5"].includes(subcategory.value)
                                      ? "Mandatory"
                                      : "Optional"
                                  }
                                  className={
                                    ["4", "5"].includes(subcategory.value) &&
                                    !isSubmitted &&
                                    (subcategory?.comments === "" ||
                                      subcategory?.comments === null)
                                      ? `ebp-foppt-u-input-error ebp-foppt-u-input-error--${id}`
                                      : ""
                                  }
                                  required
                                  onChange={(
                                    e: React.ChangeEvent<HTMLTextAreaElement>
                                  ) => {
                                    updateSafeSave(true)
                                    setSubComments({
                                      catIndex,
                                      scatIndex,
                                      value: e.target.value,
                                    })
                                  }}
                                  defaultValue={
                                    subcategory !== undefined
                                      ? subcategory?.comments
                                      : null
                                  }
                                  disabled={
                                    (isSubmitted &&
                                      context.evaluationCommentsRedact) ||
                                    (!isRealFlight && subcategory.value === "0")
                                  }
                                />

                                {subcategory.isRedacted &&
                                  context.evaluationCommentsRedact && (
                                    <>
                                      <div className="ebp-foppt-c-evaluation-form__grading--comments-controls">
                                        <label>Redacted comments:</label>
                                        <a
                                          className="ebp-foppt-u-cursor-hand"
                                          onClick={() => {
                                            cancelSubcategoryRedactedComments({
                                              catIndex,
                                              scatIndex,
                                            })
                                            updateSafeSave(true)
                                          }}
                                        >
                                          <Icon name="x" />
                                        </a>
                                      </div>
                                      <TextareaField
                                        label=""
                                        maxLength="750"
                                        placeholder="Mandatory"
                                        required
                                        value={
                                          subcategory.redactedComments ?? ""
                                        }
                                        className={
                                          ["4", "5"].includes(
                                            subcategory.value
                                          ) &&
                                          (subcategory?.redactedComments ===
                                            "" ||
                                            subcategory?.redactedComments ===
                                              null)
                                            ? `ebp-foppt-u-input-error ebp-foppt-u-input-error--${id}`
                                            : ""
                                        }
                                        onChange={(
                                          e: React.ChangeEvent<HTMLTextAreaElement>
                                        ) => {
                                          updateSafeSave(true)
                                          setSubcategoryRedactedComments({
                                            catIndex,
                                            scatIndex,
                                            value: e.target.value,
                                          })
                                        }}
                                      />
                                    </>
                                  )}
                              </>
                            ) : (
                              <>
                                {context.evaluationCommentsRedact ? (
                                  <>
                                    {subcategory.isRedacted ? (
                                      <>
                                        {subcategory?.comments === "" ||
                                        subcategory?.comments === null
                                          ? "No Comments"
                                          : renderMultilineComments(
                                              subcategory?.comments
                                            )}

                                        <div className="ebp-foppt-c-evaluation-form__grading--comments-redacted">
                                          <span>(Redacted Comments)</span>
                                          {subcategory?.redactedComments ===
                                            "" ||
                                          subcategory?.redactedComments === null
                                            ? "No Comments"
                                            : renderMultilineComments(
                                                subcategory?.redactedComments
                                              )}
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        {subcategory?.comments === "" ||
                                        subcategory?.comments === null ? (
                                          "No Comments"
                                        ) : (
                                          <>
                                            <div className="ebp-foppt-c-evaluation-form__grading--comments-approval">
                                              <div
                                                className={`ebp-foppt-c-evaluation-form__grading--comments-approval__label ebp-foppt-c-evaluation-form__grading--comments-approval__label-${
                                                  subcategory.isApproved
                                                    ? "approved"
                                                    : "unapproved"
                                                }`}
                                              >
                                                <Icon
                                                  name={`thumbs-${
                                                    subcategory.isApproved
                                                      ? "up"
                                                      : "down"
                                                  }`}
                                                />
                                                <span>
                                                  {subcategory.isApproved
                                                    ? "Approved"
                                                    : "Unapproved"}
                                                </span>
                                              </div>
                                            </div>
                                            {renderMultilineComments(
                                              subcategory?.comments
                                            )}
                                          </>
                                        )}
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {subcategory?.comments === "" ||
                                    subcategory?.comments === null
                                      ? "No Comments"
                                      : renderMultilineComments(
                                          subcategory?.comments
                                        )}
                                  </>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </React.Fragment>
            )
          })
        : null}
    </>
  )
}
