import React from "react"
import img from "../../../assets/images/user_profile.jpg"
import "./styles.scss"

interface EmployeePictureProps {
  picture: StringNull
  size: string
}

export const EmployeePicture = ({ picture, size }: EmployeePictureProps) => {
  return (
    <div>
      <div
        className={`ebp-foppt-c-employee-card ebp-foppt-c-employee-card__size--${size}`}
      >
        <img src={picture ?? img} />
      </div>
    </div>
  )
}
