import React from "react"
import { BoxSection, Grid, GridItem } from "atmos-design-system"
import { useSelector } from "react-redux"
import { type UserState } from "src/api/user/interfaces"
import { captains } from "src/api"
import { EmployeePicture } from "src/components/Employee/Picture"
import Search from "src/components/UI/Search"

interface EvaluatorInformationFormProps {
  evaluator: CaptainsInfo | null
  setEvaluator: (item: FormattedSearchCaptainResponse) => void
}

function renderSearchResults(item: FormattedSearchCaptainResponse) {
  return (
    <>
      <EmployeePicture picture={item.picture} size="xs-small" />
      {item.value}
    </>
  )
}

export default function EvaluatorInformationForm({
  evaluator,
  setEvaluator,
}: EvaluatorInformationFormProps) {
  const user = useSelector((state: UserState) => state.user)
  const context = user.context
  return (
    <BoxSection title="Captain / Instructor / Evaluator / LCA Information:">
      {!context.evaluationFormEditEvaluator ? (
        evaluator !== null && (
          <Grid variant="4up">
            <GridItem>
              <b>Name</b>
              <p>{evaluator.name}</p>
            </GridItem>
            <GridItem>
              <b>Employee No</b>
              <p>{evaluator.employeeNo}</p>
            </GridItem>
            <GridItem>
              <b>Base</b>
              <p>{evaluator.base}</p>
            </GridItem>
            <GridItem></GridItem>
          </Grid>
        )
      ) : (
        <>
          <div
            className={`ebp-foppt-c-employee-search ebp-foppt-c-employee-search__inline`}
          >
            <Search<FormattedSearchCaptainResponse>
              onSelect={setEvaluator}
              api={captains.searchCaptains}
              render={renderSearchResults}
              label="Evaluator"
              requiredLabel="(Required)"
            />
          </div>
          {evaluator?.employeeNo !== undefined && (
            <Grid variant="4up">
              <GridItem>
                <b>Name</b>
                <p>{evaluator.name}</p>
              </GridItem>
              <GridItem>
                <b>Employee No</b>
                <p>{evaluator.employeeNo}</p>
              </GridItem>
              <GridItem>
                <b>Base</b>
                <p>{evaluator.base}</p>
              </GridItem>
              <GridItem></GridItem>
            </Grid>
          )}
        </>
      )}
    </BoxSection>
  )
}
