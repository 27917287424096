import React, { useEffect, useState } from "react"
import { useIdleTimer } from "react-idle-timer"
import { type RouteComponentProps, withRouter } from "react-router-dom"
import {
  Button,
  ButtonGroup,
  Modal,
  ModalHeader,
  ModalBody,
} from "atmos-design-system"
import { Routes } from "src/utils/routes"

const ONE_MINUTE = 1_000 * 60
const PROMPT_BEFORE_IDLE = ONE_MINUTE
const IDLE = 30 * ONE_MINUTE + PROMPT_BEFORE_IDLE

function UserSessionModal({ history }: RouteComponentProps) {
  const [timeoutModalOpen, setTimeoutModalOpen] = useState<boolean>(false)

  const [remaining, setRemaining] = useState<number>(0)

  const onIdle = () => {
    history.push(Routes.Logout)
  }

  const onPrompt = () => {
    if (isLastActiveTab()) setTimeoutModalOpen(true)
  }

  function extendSession() {
    setTimeoutModalOpen(false)
    activate()
  }

  const { isLastActiveTab, getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onPrompt,
    promptBeforeIdle: PROMPT_BEFORE_IDLE,
    timeout: IDLE,
    crossTab: true,
    syncTimers: 100,
  })

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000))
    }, 500)

    return () => {
      clearInterval(interval)
    }
  })

  return (
    <>
      <Modal
        dismissible={true}
        isActive={timeoutModalOpen}
        onClose={extendSession}
      >
        <ModalHeader></ModalHeader>
        <ModalBody>
          <div>
            <p>
              This session will timeout in <span>{remaining}</span> seconds. Do
              you want to extend your session?
            </p>
            <div className="ebp-foppt-l-items--center">
              <ButtonGroup>
                <Button
                  text="End Session"
                  onClick={() => {
                    history.push(Routes.Logout)
                  }}
                />
                <Button
                  text="Extend Session"
                  variant="primary"
                  onClick={extendSession}
                />
              </ButtonGroup>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default withRouter(UserSessionModal)
