import React, { useState, useEffect, useCallback } from "react"
import { Icon } from "atmos-design-system"

interface GoTopProps {
  scrollTop: number
}

const GoTop = ({ scrollTop = 200 }: GoTopProps) => {
  const [display, setDisplay] = useState(false)
  function goTop() {
    window.scrollTo(0, 0)
  }

  const onScroll = useCallback(() => {
    const scroll = Math.floor(document.documentElement.scrollTop)
    setDisplay(scroll > scrollTop)
  }, [scrollTop])

  useEffect(() => {
    window.addEventListener("scroll", onScroll)

    return () => {
      window.removeEventListener("scroll", onScroll)
    }
  }, [onScroll])

  return (
    <>
      {display && (
        <a className="ebp-foppt-c-go-up" onClick={goTop}>
          <Icon name="caret-up" theme="inverted" />
        </a>
      )}
    </>
  )
}

export default GoTop
