import React, { useEffect } from "react"
import { LayoutContainer, Alert } from "atmos-design-system"
import { useSelector, useDispatch } from "react-redux"

interface AlertState {
  alerts: [
    {
      display: boolean
      message: string
      type: string
      dismissible: boolean
      timer: number
    }
  ]
  staticAlerts: [
    {
      display: boolean
      message: string
      type: string
      dismissible: boolean
    }
  ]
}

function GlobalAlertContainer() {
  const DEFAULT_TIMER = 8000

  const alerts = useSelector((state: AlertState) => state.alerts)
  const staticAlerts = useSelector((state: AlertState) => state.staticAlerts)
  const dispatch = useDispatch()

  useEffect(() => {
    let timeout: NodeJS.Timeout
    if (alerts.length > 0) {
      timeout = setTimeout(() => {
        dispatch({
          type: "removeAlerts",
        })
      }, alerts[0].timer ?? DEFAULT_TIMER)
    }
    return () => {
      clearTimeout(timeout)
    }
  }, [dispatch, alerts])

  useEffect(() => {
    return () => {
      dispatch({
        type: "removeStaticAlerts",
      })
    }
  }, [dispatch])

  return (
    <>
      {alerts.map((alert, index) => {
        return (
          alert.display && (
            <LayoutContainer key={`alert-${index}`}>
              <Alert
                iconName={alert.type}
                title={alert.message}
                variant={alert.type}
                dismissible={true}
                closeAlertMessage="alert"
              />
            </LayoutContainer>
          )
        )
      })}
      {staticAlerts.map((alert, index) => {
        return (
          alert.display && (
            <LayoutContainer key={`static-alert-${index}`}>
              <Alert
                iconName={alert.type}
                title={alert.message}
                variant={alert.type}
                dismissible={false}
                closeAlertMessage="alert"
              />
            </LayoutContainer>
          )
        )
      })}
    </>
  )
}

export default GlobalAlertContainer
