import type { ApiRequest } from "src/api/apiInterfaces"
import type {
  CompleteEvaluationResponse,
  CompletedEvaluationsFormattedResponse,
  GetCompletedEvaluationParams,
} from "./interfaces"

const evaluationsFormat = (
  data: CompleteEvaluationResponse
): CompletedEvaluationsFormattedResponse => {
  const { evaluations, pagination } = data

  const completedEvaluations = evaluations.map((evaluation) => {
    const { evaluationId, flightInfo, pilotInfo, evaluationInfo } = evaluation

    return {
      evaluationId,
      flightNo: flightInfo.flightNo,
      originCity: flightInfo.originCity,
      destination: flightInfo.destination,
      originDate: flightInfo.originDate,
      evaluationDate: evaluationInfo.submitted?.on ?? "",
      pilotId: pilotInfo.employeeNo,
      picture: pilotInfo.picture,
      pilotName: pilotInfo.name,
    }
  })
  return {
    evaluations: completedEvaluations,
    pagination,
  }
}

const CompletedEvaluationsApi = (
  request: ApiRequest,
  getEndpoint: CallableFunction
) => {
  return {
    get: async (params: GetCompletedEvaluationParams) =>
      await request
        .get<CompleteEvaluationResponse>(getEndpoint("completed_evaluations"), {
          params,
        })
        .then((data) => evaluationsFormat(data)),
  }
}

export default CompletedEvaluationsApi
