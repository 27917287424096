import React from "react"
import {
  BoxSection,
  Grid,
  SelectField,
  TextField,
  GridItem,
  Tooltip,
} from "atmos-design-system"
import { Datepicker } from "src/components/UI/Datepicker"
import moment from "moment"
import { validationRegex, SIMULATION_CITY } from "./constants"
import { useSelector } from "react-redux"
import type { UserState } from "src/api/user/interfaces"

interface FlightInformationFormProps {
  flightType: string
  setFlightType: CallableFunction
  flightInfo: EvaluationFlightInfo
  setFlightInfo: (payload: EvaluationFlightInfo) => void
  setOptionalCategories: (payload: boolean) => void
  showRequiredFieldErrors: boolean
}

export default function FlightInformationForm({
  flightType,
  setFlightType,
  flightInfo,
  setFlightInfo,
  setOptionalCategories,
  showRequiredFieldErrors,
}: FlightInformationFormProps) {
  const { evaluationSettings } = useSelector((state: UserState) => state.user)
  const { backLimitDate, flightTypes, specialFlightTypes, today } =
    evaluationSettings
  const isSpecialFlight = specialFlightTypes.includes(flightType)

  return (
    <BoxSection title="Flight Information:">
      <Grid variant="4up">
        <GridItem>
          <SelectField
            label="Flight Type"
            value={flightType}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setFlightType(e.target.value)
              setFlightInfo({
                ...flightInfo,
                flightNo: specialFlightTypes.includes(e.target.value)
                  ? "UA"
                  : "",
                originCity: specialFlightTypes.includes(e.target.value)
                  ? ""
                  : SIMULATION_CITY,
                destination: specialFlightTypes.includes(e.target.value)
                  ? ""
                  : SIMULATION_CITY,
              })

              setOptionalCategories(specialFlightTypes.includes(e.target.value))
            }}
            items={flightTypes}
            className={
              showRequiredFieldErrors && ["-1", ""].includes(flightType)
                ? `ebp-foppt-u-input-error ebp-foppt-u-cursor-hand`
                : "ebp-foppt-u-cursor-hand"
            }
          />
        </GridItem>
        <GridItem>
          <Datepicker
            label="Origin Date"
            date={flightInfo.originDate}
            setDate={(date: string) => {
              setFlightInfo({
                ...flightInfo,
                originDate: date,
              })
            }}
            isOutsideRange={(date: moment.Moment) => {
              return !date.isBetween(
                moment(backLimitDate),
                moment(today),
                "days",
                "(]"
              )
            }}
            disabled={flightType === "-1"}
          />
        </GridItem>
        <GridItem>
          <TextField
            label="Origin City"
            type="text"
            labelAfter={
              isSpecialFlight && (
                <Tooltip buttonText="Click for more information">
                  IATA Code, i.e. DEN, MUC
                </Tooltip>
              )
            }
            error={
              flightInfo.originCity.length > 0 &&
              flightInfo.originCity.length < 3 &&
              isSpecialFlight
                ? {
                    message: "Value must have 3 characters.",
                  }
                : null
            }
            value={flightInfo.originCity}
            maxLength={3}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const newValue = e.target.value
              if (validationRegex.inputCity.test(newValue)) {
                setFlightInfo({
                  ...flightInfo,
                  originCity: newValue.toLocaleUpperCase(),
                })
              }
            }}
            disabled={!isSpecialFlight}
            className={
              showRequiredFieldErrors && flightInfo.originCity.length !== 3
                ? `ebp-foppt-u-input-error ebp-foppt-u-cursor-hand`
                : "ebp-foppt-u-cursor-hand"
            }
          />
        </GridItem>
        <GridItem>
          <TextField
            label="Destination City"
            type="text"
            labelAfter={
              isSpecialFlight && (
                <Tooltip buttonText="Click for more information">
                  IATA Code, i.e. DEN, MUC
                </Tooltip>
              )
            }
            error={
              flightInfo.destination.length > 0 &&
              flightInfo.destination.length < 3 &&
              isSpecialFlight
                ? {
                    message: "Value must have 3 characters.",
                  }
                : null
            }
            value={flightInfo.destination}
            maxLength={3}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const newValue = e.target.value
              if (validationRegex.inputCity.test(newValue)) {
                setFlightInfo({
                  ...flightInfo,
                  destination: newValue.toLocaleUpperCase(),
                })
              }
            }}
            disabled={!isSpecialFlight}
            className={
              showRequiredFieldErrors && flightInfo.destination.length !== 3
                ? `ebp-foppt-u-input-error ebp-foppt-u-cursor-hand`
                : "ebp-foppt-u-cursor-hand"
            }
          />
        </GridItem>
      </Grid>
      <Grid variant="4up">
        <GridItem>
          <TextField
            label={isSpecialFlight ? "Flight Number" : "Event"}
            type="text"
            labelAfter={
              isSpecialFlight && (
                <Tooltip buttonText="Click for more information">
                  4 digit Flight Number required, i.e. UA0123
                </Tooltip>
              )
            }
            value={flightInfo.flightNo}
            maxLength={isSpecialFlight ? 6 : 10}
            disabled={flightType === "-1"}
            error={
              flightInfo.flightNo.length > 2 &&
              flightInfo.flightNo.length < 6 &&
              isSpecialFlight
                ? {
                    message:
                      "Value should have leading zeros (e.g. 1234, 0123, 0001)",
                  }
                : null
            }
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const newValue = e.target.value
              setFlightInfo({
                ...flightInfo,
                flightNo:
                  isSpecialFlight &&
                  !validationRegex.inputFlightNumber.test(newValue)
                    ? "UA"
                    : newValue,
              })
            }}
            className={
              showRequiredFieldErrors &&
              (isSpecialFlight
                ? flightInfo.flightNo.length !== 6
                : flightInfo.flightNo.length === 0)
                ? `ebp-foppt-u-input-error ebp-foppt-u-cursor-hand`
                : "ebp-foppt-u-cursor-hand"
            }
          />
        </GridItem>
      </Grid>
    </BoxSection>
  )
}
