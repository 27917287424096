import React, { useEffect } from "react"
import { Alert, Logo, TextField, Button } from "atmos-design-system"
import "./style.scss"
import { type RouteComponentProps, withRouter } from "react-router-dom"

function Authorize({ history }: RouteComponentProps) {
  function login() {
    if (window.location.search.toString().includes("redirect_uri")) {
      const queryParams = new URLSearchParams(window.location.search)
      const redirectUri = queryParams.get("redirect_uri") ?? ""
      const params = new URLSearchParams({
        code: "EBP-FOPPT-UI-REACT-APP",
      })
      const queryString = params.toString()
      window.location.href = `${redirectUri}?${queryString}`
    }
  }

  useEffect(() => {
    if (window.location.host !== "localhost:3005") {
      history.push("/logout")
    }
  }, [history])

  return (
    <div className="ebp-foppt-c-login">
      <div className="ebp-foppt-c-login__gradient">
        <div className="ebp-foppt-c-login__container">
          <div className="ebp-foppt-c-login__fields">
            <Logo href="/" title="United Airlines homepage" />
            <TextField
              label=""
              type="text"
              placeholder="uID or Username"
              value="v123456"
            />
            <TextField
              label=""
              type="password"
              placeholder="Password"
              value="ebp-foppt"
            />
            <Button text="Login" variant="primary" onClick={login} />
          </div>

          <div className="ebp-foppt-c-login__alert">
            <Alert
              iconName="warning"
              title="Login for Local Environment"
              variant="warning"
            >
              <p>No real credentials are needed to log in.</p>
            </Alert>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(Authorize)
