import type { ApiRequest } from "src/api/apiInterfaces"

const MailingListApi = (request: ApiRequest, getEndpoint: CallableFunction) => {
  return {
    get: async () =>
      await request.get<MailingListResponse>(getEndpoint("mailingList")),

    post: async (body: MailingList) =>
      await request.post<MailingPostResponse>(getEndpoint("mailingList"), body),

    delete: async (id: number) =>
      await request.delete(getEndpoint("mailingList", id)),
  }
}

export default MailingListApi
