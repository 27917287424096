import React from "react"
import MainLayout from "src/components/UI/MainLayout"
import PilotsScorecard from "src/components/Pilots/ScoreCard"

function PilotsScorecardView() {
  return (
    <MainLayout context="pilotScorecardView">
      <PilotsScorecard />
    </MainLayout>
  )
}

export default PilotsScorecardView
