import React, { useEffect, useState } from "react"
import { pairings } from "src/api"
import { useDispatch, useSelector } from "react-redux"
import PairingList from "./PairingList"
import type { PilotPairingEvaluations } from "src/api/pairings/interfaces"
import type { UserState } from "src/api/user/interfaces"
import PairingsSearch from "./PairingsSearch"

function Pairings() {
  const dispatch = useDispatch()
  const captainId = useSelector((state: UserState) => state.user.uid)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false)
  const [pairingEvaluations, setPairingEvaluations] = useState<
    PilotPairingEvaluations[]
  >([])

  useEffect(() => {
    if (isDataLoaded || captainId.length === 0 || isLoading) return

    setIsLoading(true)
    setIsDataLoaded(true)

    dispatch({ type: "loader" })
    const params = new URLSearchParams()
    params.append("captainId", captainId)

    pairings
      .get(params)
      .then((response) => {
        if (response.evaluationToExpireMessage !== null) {
          dispatch({
            type: "alert",
            payload: {
              id: "Pairings__evaluationToExpireMessage",
              display: true,
              message: response.evaluationToExpireMessage,
              type: "error",
              dismissible: false,
            },
          })
        }
        setPairingEvaluations([...response.pairingEvaluations])
      })
      .catch((err) => {
        if (err.response !== undefined && err.response.status >= 400) {
          dispatch({
            type: "alert",
            payload: {
              id: "Pairings__fetchingListFailed",
              display: true,
              message: "Fetching Pairings list failed",
              type: "error",
            },
          })
        }
      })
      .finally(() => {
        setIsLoading(false)
        dispatch({ type: "loader" })
      })
  }, [captainId, dispatch, isLoading, isDataLoaded])

  return (
    <>
      <PairingsSearch
        setIsLoading={setIsLoading}
        setIsDataLoaded={setIsDataLoaded}
      />
      <PairingList pairings={pairingEvaluations} />
    </>
  )
}

export default Pairings
