import { useCallback, useReducer } from "react"

type ExtraStateActions =
  | { type: "submitBtn" }
  | { type: "loader"; payload: boolean }
  | { type: "apiCall"; payload: boolean }
  | { type: "modalOpen"; payload: boolean }
  | { type: "modalSaveOpen"; payload: boolean }
  | { type: "modalCommentsOpen"; payload: boolean }
  | { type: "modalErrorOpen"; payload: boolean }
  | { type: "modalBackOpen"; payload: boolean }
  | { type: "modalSimulatorOpen"; payload: boolean }
  | { type: "safeSave"; payload: boolean }
  | { type: "saveAction"; payload: boolean }
  | { type: "editionMode"; payload: boolean }
  | { type: "isLogCommentsEmpty"; payload: boolean }
  | { type: "isMngtCommentsEmpty"; payload: boolean }
  | { type: "canSubmit"; payload: boolean }
  | { type: "failedFetchingOnQuickEdit"; payload: string }
  | { type: "showRequiredFieldErrors"; payload: boolean }
  | { type: "isSubmitting"; payload: boolean }

const INITIAL_STATE_EXTRA = {
  loader: false,
  modalOpen: false,
  modalSaveOpen: false,
  modalCommentsOpen: false,
  modalErrorOpen: false,
  modalBackOpen: false,
  modalSimulatorOpen: false,
  submitBtn: true,
  safeSave: false,
  saveAction: false,
  editionMode: false,
  isLogCommentsEmpty: false,
  isMngtCommentsEmpty: false,
  canSubmit: false,
  failedFetchingOnQuickEdit: "",
  showRequiredFieldErrors: false,
  isSubmitting: false,
}

function formStateControls(
  state: typeof INITIAL_STATE_EXTRA,
  action: ExtraStateActions
) {
  switch (action.type) {
    case "submitBtn": {
      return { ...state, [action.type]: !state[action.type] }
    }
    default: {
      return { ...state, [action.type]: action.payload }
    }
  }
}

function useNewEvaluationFormControls() {
  const [states, dispatch] = useReducer(formStateControls, INITIAL_STATE_EXTRA)

  const toggleSubmit = useCallback(() => {
    dispatch({ type: "submitBtn" })
  }, [])

  const updateLoader = useCallback((payload: boolean) => {
    dispatch({ type: "loader", payload })
  }, [])

  const updateModalOpen = useCallback((payload: boolean) => {
    dispatch({ type: "modalOpen", payload })
  }, [])

  const updateModalSaveOpen = useCallback((payload: boolean) => {
    dispatch({ type: "modalSaveOpen", payload })
  }, [])

  const updateModalCommentsOpen = useCallback((payload: boolean) => {
    dispatch({ type: "modalCommentsOpen", payload })
  }, [])

  const updateModalErrorOpen = useCallback((payload: boolean) => {
    dispatch({ type: "modalErrorOpen", payload })
  }, [])

  const updateModalBackOpen = useCallback((payload: boolean) => {
    dispatch({ type: "modalBackOpen", payload })
  }, [])

  const updateModalSimulatorOpen = useCallback((payload: boolean) => {
    dispatch({ type: "modalSimulatorOpen", payload })
  }, [])

  const updateSafeSave = useCallback((payload: boolean) => {
    dispatch({ type: "safeSave", payload })
  }, [])

  const updateSaveAction = useCallback((payload: boolean) => {
    dispatch({ type: "saveAction", payload })
  }, [])

  const updateEditionMode = useCallback((payload: boolean) => {
    dispatch({ type: "editionMode", payload })
  }, [])

  const updateIsLogCommentsEmpty = useCallback((payload: boolean) => {
    dispatch({ type: "isLogCommentsEmpty", payload })
  }, [])

  const updateIsMngtCommentsEmpty = useCallback((payload: boolean) => {
    dispatch({ type: "isMngtCommentsEmpty", payload })
  }, [])

  const updateCanSubmit = useCallback((payload: boolean) => {
    dispatch({ type: "canSubmit", payload })
  }, [])

  const updateFailedFetchingOnQuickEdit = useCallback((payload: string) => {
    dispatch({ type: "failedFetchingOnQuickEdit", payload })
  }, [])

  const updateShowRequiredFieldErrors = useCallback((payload: boolean) => {
    dispatch({ type: "showRequiredFieldErrors", payload })
  }, [])

  const updateIsSubmitting = useCallback((payload: boolean) => {
    dispatch({ type: "isSubmitting", payload })
  }, [])

  return {
    states,
    toggleSubmit,
    updateLoader,
    updateModalOpen,
    updateModalSaveOpen,
    updateModalCommentsOpen,
    updateModalErrorOpen,
    updateModalBackOpen,
    updateModalSimulatorOpen,
    updateSafeSave,
    updateSaveAction,
    updateEditionMode,
    updateIsLogCommentsEmpty,
    updateIsMngtCommentsEmpty,
    updateCanSubmit,
    updateFailedFetchingOnQuickEdit,
    updateShowRequiredFieldErrors,
    updateIsSubmitting,
  }
}

export default useNewEvaluationFormControls
