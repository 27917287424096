import React, { Fragment, useEffect, useState } from "react"
import "./styles.scss"
import CustomTable from "src/components/UI/Table/Table"
import { Icon, TableRow } from "atmos-design-system"
import { CustomCell } from "src/components/UI/Table/Cell"

interface ScorecardProps {
  data: PilotCategory[]
}

function Scorecard({ data }: ScorecardProps) {
  const columns: Array<Column<PilotCategory>> = [
    {
      field: "title",
      title: "Category",
      alignment: "left",
      render: (row, index = -1) => {
        if (index >= 0)
          return (
            <p className="ebp-foppt-l-subcategory-text">
              {row.subcategories[index].title}
            </p>
          )
        return <p className="ebp-foppt-l-category-text">{row.title}</p>
      },
    },
    {
      field: "avg",
      title: "Avg Rating",
      alignment: "center",
      className: "ebp-foppt-table__avg-cell",
      render: (row, index = -1) => {
        if (index >= 0)
          return (
            <p className="ebp-foppt-l-subcategory-text">
              {row.subcategories[index].avg}
            </p>
          )
        return `${row.avg}`
      },
    },
    {
      field: "avg",
      title: "",
      alignment: "right",
      className: "ebp-foppt-table__action-cell",
      render: (row: PilotCategory, index = -1) => {
        if (index >= 0) return ``
        return (
          <a
            onClick={() => {
              setTableOpenStatus((prevState) => {
                prevState[row.id] = !prevState[row.id]
                return [...prevState]
              })
            }}
          >
            <Icon
              name={tableOpenStatus[row.id] ? "caret-down" : "caret-right"}
            />
          </a>
        )
      },
    },
  ]
  const rowRenderer = (
    row: PilotCategory,
    index: number,
    columns: Array<Column<PilotCategory>>
  ) => {
    const extraRows = row.subcategories.map((_subcat, extraIndex) => {
      return (
        <TableRow
          key={`extrarow-${index}-${extraIndex}`}
          className="ebp-foppt-l-table__row"
        >
          {columns.map((column, columnIndex) => {
            return (
              <CustomCell
                column={column}
                row={row}
                key={`${
                  column.field as string
                }-${index}-${extraIndex}-${columnIndex}`}
                extraIndex={extraIndex}
              />
            )
          })}
        </TableRow>
      )
    })
    return (
      <Fragment key={`fragment-${index}`}>
        <TableRow key={`row-${index}`} className="ebp-foppt-l-table__row">
          {columns.map((column, columnIndex) => {
            return (
              <CustomCell
                column={{
                  ...column,
                  rowSpan: 1,
                }}
                row={row}
                key={`${column.field as string}-${index}-${columnIndex}`}
              />
            )
          })}
        </TableRow>
        {tableOpenStatus[row.id] && extraRows}
      </Fragment>
    )
  }
  const [tableOpenStatus, setTableOpenStatus] = useState<boolean[]>([])
  useEffect(() => {
    const toggleStatus: boolean[] = []
    data.forEach((category) => {
      toggleStatus[category.id] = false
    })
    setTableOpenStatus(toggleStatus)
  }, [data])

  return (
    <div>
      <CustomTable<PilotCategory>
        columns={columns}
        data={data}
        loading={false}
        tableType="categories"
        rowRenderer={rowRenderer}
      />
    </div>
  )
}

export default Scorecard
