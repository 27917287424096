const DATE_FORMAT = "MM/DD/YYYY"
const REGEX = {
  numeric: /^\d{1,4}$/,
  userID: /^[uU]\d{6}$/,
  simpleUser: /^[aA-zZ\d ]{3,}?$/,
}
const HELP_MAIL = "mailto:newhirepilot@united.com?subject=FOPPT%20inquiry"
const ITEMS_TO_PAGINATE = 5

const enum SwitchType {
  On = "on",
  Off = "off",
}

export { DATE_FORMAT, REGEX, HELP_MAIL, ITEMS_TO_PAGINATE, SwitchType }
