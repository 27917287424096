import React from "react"
import { Switch, Route } from "react-router-dom"
import EvaluationFormView, {
  NewEvaluationFormView,
} from "./pages/Pilot/EvaluationFormView"
import PilotsView from "./pages/Pilots"
import FlightsView from "./pages/Flights/FlightsView"
import SignIn from "./pages/Auth/SignIn"
import Logout from "./pages/Auth/Logout"
import Authorize from "./pages/Auth/Authorize"
import Oauth2 from "./pages/Auth/Oauth2"
import AdminPilotView from "./pages/Pilot/AdminPilotView"
import MailingListView from "./pages/MailingList/MailingListView"
import CaptainsListView from "./pages/Captains/CaptainsListView"
import LoginAs from "./pages/LoginAs/LoginAs"
import UserRolesView from "./pages/UserRoles/UserRolesView"
import UserBasesView from "./pages/UserBases/UserBasesView"
import SettingsView from "./pages/Settings/SettingsView"
import ErrorPage from "./pages/Error/ErrorPage"
import HomePage from "./pages/Home"
import CaptainEvaluationsView from "./pages/Captain/CaptainEvaluationsView"
import PilotsScorecardView from "./pages/Pilots/ScorecardView"
import { Routes } from "./utils/routes"
import { onDev } from "./utils/functions"

function App() {
  return (
    <>
      <Switch>
        <Route path={Routes.Flights}>
          <FlightsView />
        </Route>
        <Route path={`${Routes.Evaluation}/:id`}>
          <EvaluationFormView />
        </Route>
        <Route path={Routes.NewEvaluation}>
          <NewEvaluationFormView />
        </Route>
        <Route path={Routes.Pilots}>
          <PilotsView />
        </Route>
        <Route path={Routes.PilotsScorecard}>
          <PilotsScorecardView />
        </Route>
        <Route path={`${Routes.Pilot}/:id`}>
          <AdminPilotView />
        </Route>
        <Route path={Routes.Captains}>
          <CaptainsListView />
        </Route>
        <Route path={`${Routes.Captain}/:id`}>
          <CaptainEvaluationsView />
        </Route>
        <Route path={Routes.Roles}>
          <UserRolesView />
        </Route>
        <Route path={Routes.MailingList}>
          <MailingListView />
        </Route>
        <Route path={Routes.UserBases}>
          <UserBasesView />
        </Route>
        <Route path={Routes.Settings}>
          <SettingsView />
        </Route>
        <Route path={Routes.Error}>
          <ErrorPage />
        </Route>
        <Route path={Routes.Sign}>
          <SignIn />
        </Route>
        {/* This route is ONLY for local */}
        {onDev() && (
          <Route path={Routes.OAuth2Authorizer}>
            <Authorize />
          </Route>
        )}
        <Route path={Routes.OAuth2IdResponse}>
          <Oauth2 />
        </Route>
        <Route path={Routes.LoginAs}>
          <LoginAs />
        </Route>
        <Route path={Routes.Logout}>
          <Logout />
        </Route>
        <Route path={Routes.Home}>
          <HomePage />
        </Route>
      </Switch>
    </>
  )
}

export default App
