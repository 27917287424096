export const enum Routes {
  Home = "/",
  Sign = "/sign",
  LoginAs = "/login/:role/:employeeId",
  OAuth2Authorizer = "/oauth2/rest/authorize",
  OAuth2IdResponse = "/oauth2/idpresponse",
  Flights = "/flights",
  Pilots = "/pilots",
  PilotsScorecard = "/pilotsScorecard",
  Pilot = "/pilot",
  Captains = "/captains",
  Captain = "/captain",
  Evaluation = "/evaluation",
  NewEvaluation = "/new-evaluation",
  Roles = "/roles",
  MailingList = "/mailingList",
  UserBases = "/userBases",
  Settings = "/settings",
  Logout = "/logout",
  Error = "/error",
}
