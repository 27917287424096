import React, { useEffect } from "react"
import Loader from "../../components/UI/Loader"
import Cookies from "js-cookie"
import randomstring from "randomstring"
import CryptoJS from "crypto-js"
import {
  removePilotsFilterSettings,
  removeCaptainsFilterSettings,
} from "src/utils/functions"

function SignIn() {
  useEffect(() => {
    const codeVerifier = randomstring.generate(128)
    // Saves the code verifier in session storage <- do not save in localStorage
    sessionStorage.setItem("code_verifier", codeVerifier)
    // Encrypt the code verifier
    const codeChallenge = CryptoJS.enc.Base64url.stringify(
      CryptoJS.SHA256(codeVerifier)
    )

    const authorizerUri = process.env["AUTH_AUTHORIZE_ENDPOINT"] ?? ""
    const redirectUri = process.env["AUTH_REDIRECT_URI"] ?? ""
    const clientId = process.env["AUTH_CLIENT_ID"] ?? ""
    const domain = process.env["AUTH_DOMAIN"] ?? ""
    const scope = process.env["AUTH_SCOPE"] ?? ""
    const params = new URLSearchParams({
      client_id: clientId,
      domain,
      redirect_uri: redirectUri,
      code_challenge_method: "S256",
      response_type: "code",
      scope: `openid profile ${scope}`,
      code_challenge: codeChallenge,
    })
    const queryString = params.toString()
    localStorage.removeItem("token")
    Cookies.remove("APP_SECURE")
    removePilotsFilterSettings()
    removeCaptainsFilterSettings()
    window.location.href = `${authorizerUri}?${queryString}`
  }, [])

  return <Loader display />
}

export default SignIn
