import React from "react"
import { Heading, Grid, GridItem } from "atmos-design-system"
import Card from "src/components/UI/Card"
import img from "../../../../public/assets/images/user_profile.jpg"

interface PilotCardProps {
  pilotName: string
  pilotPicture?: string | null
  pilotStatus?: string
  base: string
  fleetType: string
  dateOfHire: string
  endDate: string
}

function PilotCard({
  pilotName = "",
  pilotPicture = null,
  pilotStatus = "",
  base = "",
  fleetType = "",
  dateOfHire = "",
  endDate = "",
}: PilotCardProps): JSX.Element {
  return (
    <Card picture={pilotPicture ?? img}>
      <Heading tagName="h4">{pilotName}</Heading>
      {pilotStatus !== "" && (
        <Heading tagName="h5" className="atm-u-color-utility-warning">
          {pilotStatus}
        </Heading>
      )}
      <div className="ebp-foppt-l-padding">
        <Grid variant="4up">
          <GridItem>
            <b>Base</b>
            <p>{base}</p>
          </GridItem>
          <GridItem>
            <b>Fleet Type</b>
            <p>{fleetType}</p>
          </GridItem>
          <GridItem>
            <b>Date of Hire</b>
            <p>{dateOfHire}</p>
          </GridItem>
          <GridItem>
            <b>Estimated Probation End Date</b>
            <p>{endDate}</p>
          </GridItem>
        </Grid>
      </div>
    </Card>
  )
}

export default PilotCard
