import moment from "moment"
import { useCallback, useReducer } from "react"
import { useDispatch } from "react-redux"
import { captain } from "src/api"
import type {
  CaptainEvaluationsFormattedResponse,
  GetCaptainEvaluationParams,
} from "src/api/captain/interfaces"

interface EvaluationActionPayload {
  evaluationId: number
}

interface DatePayload {
  dateFrom: moment.Moment
  dateTo: moment.Moment
}

type ExtraStateActions =
  | {
      type: "loadPage"
      payload: {
        response: CaptainEvaluationsFormattedResponse
        append: boolean
      }
    }
  | { type: "isFetching"; payload: boolean }
  | { type: "captainId"; payload: string }
  | {
      type: "closeAllEvaluations"
    }
  | {
      type: "setToggleStatus"
      payload: EvaluationActionPayload
    }
  | {
      type: "removeEvaluation"
      payload: EvaluationActionPayload
    }
  | {
      type: "toggleFlag"
    }
  | {
      type: "setDate"
      payload: DatePayload
    }
  | { type: "lastCall"; payload: boolean }

interface StateTypes {
  page: number
  pageSize: number
  lastCall: boolean
  isFetching: boolean
  captainId: string
  data: CaptainEvaluation[]
  flag: boolean
  submittedDateFrom: moment.Moment
  submittedDateTo: moment.Moment
}

const INITIAL_STATE_EXTRA: StateTypes = {
  page: 1,
  pageSize: 25,
  lastCall: false,
  isFetching: false,
  captainId: "",
  data: [],
  flag: false,
  submittedDateFrom: moment().subtract(1, "month"),
  submittedDateTo: moment(),
}

function captainEvaluationStateControls(
  state: StateTypes,
  action: ExtraStateActions
): StateTypes {
  switch (action.type) {
    case "isFetching": {
      return { ...state, [action.type]: action.payload }
    }
    case "captainId": {
      return { ...state, [action.type]: action.payload }
    }
    case "loadPage": {
      return {
        ...state,
        isFetching: false,
        data: action.payload.append
          ? [...state.data, ...action.payload.response.evaluations]
          : action.payload.response.evaluations,
        page: Number(action.payload.response.pagination.page) + 1,
        lastCall: action.payload.response.evaluations.length < state.pageSize,
      }
    }
    case "setToggleStatus": {
      const { evaluationId } = action.payload
      const elemIndex = state.data.findIndex(
        (elem) => elem.evaluationId === evaluationId
      )
      const newData = [...state.data]
      newData[elemIndex].toggleStatus = !state.data[elemIndex].toggleStatus

      return {
        ...state,
        data: newData,
      }
    }

    case "removeEvaluation": {
      const { evaluationId } = action.payload

      return {
        ...state,
        data: state.data.filter((elem) => elem.evaluationId !== evaluationId),
      }
    }
    case "closeAllEvaluations": {
      const newData = [...state.data]
      newData.forEach((evaluation) => {
        evaluation.toggleStatus = false
      })
      return {
        ...state,
        data: newData,
      }
    }
    case "toggleFlag": {
      return {
        ...state,
        flag: !state.flag,
      }
    }
    case "setDate": {
      return {
        ...state,
        submittedDateFrom: action.payload.dateFrom,
        submittedDateTo: action.payload.dateTo,
      }
    }
    case "lastCall": {
      return {
        ...state,
        lastCall: action.payload,
      }
    }
    default: {
      return { ...state }
    }
  }
}

function useCaptainEvaluations() {
  const reduxDispatch = useDispatch()
  const [states, dispatch] = useReducer(
    captainEvaluationStateControls,
    INITIAL_STATE_EXTRA
  )

  const setIsFetching = useCallback((payload: boolean) => {
    dispatch({ type: "isFetching", payload })
  }, [])

  const getParams = useCallback(
    (
      append: boolean,
      captainId?: string,
      flag?: boolean,
      dateFrom?: moment.Moment,
      dateTo?: moment.Moment
    ): GetCaptainEvaluationParams => {
      const flagExists = flag ?? states.flag
      const submittedDateFromExists = dateFrom ?? states.submittedDateFrom
      const submittedDateToExists = dateTo ?? states.submittedDateTo
      return {
        page: append ? states.page : 1,
        pageSize: states.pageSize,
        captainId: captainId ?? states.captainId,
        ...(flagExists && { flag: flagExists }),
        ...(submittedDateFromExists !== null && {
          submittedDateFrom: submittedDateFromExists.format("MM/DD/YYYY"),
        }),
        ...(submittedDateToExists !== null && {
          submittedDateTo: submittedDateToExists.format("MM/DD/YYYY"),
        }),
      }
    },
    [
      states.captainId,
      states.flag,
      states.page,
      states.pageSize,
      states.submittedDateFrom,
      states.submittedDateTo,
    ]
  )

  const loadEvaluations = useCallback(
    (
      append = true,
      captainId?: string,
      flag?: boolean,
      dateFrom?: moment.Moment,
      dateTo?: moment.Moment
    ) => {
      const params = getParams(append, captainId, flag, dateFrom, dateTo)
      if (
        params.submittedDateFrom === undefined ||
        params.submittedDateTo === undefined
      )
        return

      setIsFetching(true)
      captain
        .getEvaluations(params)
        .then((response) => {
          dispatch({
            type: "loadPage",
            payload: { append, response },
          })
        })
        .catch((err) => {
          setIsFetching(false)
          if (
            err?.code === "ERR_NETWORK" ||
            (err?.response !== undefined && err.response?.status >= 400)
          ) {
            dispatch({ type: "lastCall", payload: false })
            reduxDispatch({
              type: "alert",
              payload: {
                id: "CaptainEvaluations__fetchingFailed",
                display: true,
                message: "Fetching Evaluations failed",
                type: "error",
              },
            })
          }
        })
    },
    [setIsFetching, getParams, reduxDispatch]
  )

  const setCaptainId = useCallback(
    (payload: string) => {
      dispatch({ type: "captainId", payload })
      loadEvaluations(false, payload)
    },
    [loadEvaluations]
  )

  const toggleStatus = useCallback((payload: EvaluationActionPayload) => {
    dispatch({ type: "setToggleStatus", payload })
  }, [])

  const removeEvaluation = useCallback((payload: EvaluationActionPayload) => {
    dispatch({ type: "removeEvaluation", payload })
    dispatch({ type: "closeAllEvaluations" })
  }, [])

  const closeAllEvaluations = useCallback(() => {
    dispatch({ type: "closeAllEvaluations" })
  }, [])

  const toggleFlag = useCallback(() => {
    dispatch({ type: "toggleFlag" })
    loadEvaluations(false, undefined, !states.flag)
  }, [loadEvaluations, states.flag])

  const setDate = useCallback(
    (payload: DatePayload) => {
      dispatch({ type: "setDate", payload })
      loadEvaluations(
        false,
        undefined,
        undefined,
        payload.dateFrom,
        payload.dateTo
      )
    },
    [loadEvaluations]
  )

  return {
    states,
    getParams,
    setIsFetching,
    setCaptainId,
    loadEvaluations,
    toggleStatus,
    removeEvaluation,
    closeAllEvaluations,
    toggleFlag,
    setDate,
  }
}

export default useCaptainEvaluations
