import React, { useEffect, useState } from "react"
import CustomTable from "src/components/UI/Table/Table"
import { mailingList } from "src/api"
import { useDispatch } from "react-redux"
import {
  Modal,
  ModalHeader,
  SelectField,
  ModalBody,
  ModalFooter,
  Heading,
  TextField,
  Form,
  ButtonGroup,
  Button,
} from "atmos-design-system"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash } from "@fortawesome/free-solid-svg-icons"
import { sortData, sysTablesRegex } from "src/utils/functions"

const MailingList = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false)
  const [validEmail, setValidEmail] = useState<boolean>(false)
  const [mailingListData, setMailingListData] = useState<MailingList[]>([])
  const [filteredData, setFilteredData] = useState<MailingList[]>([])
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [sortOptions, setSortOptions] = useState<TableOptions<MailingList>>({
    sortColumn: "email",
    sortDirection: "Asc",
  })
  const [filterOptions, setFilterOptions] = useState<FiltersMailing>({
    base: "",
    email: "",
  })
  const [deletedMail, setDeletedMail] = useState<MailingList>({
    id: 0,
    base: "",
    createdAt: "",
    email: "",
  })
  const [bases, setBases] = useState([
    {
      label: "",
      value: "",
    },
  ])
  const dispatch = useDispatch()
  const [newEmail, setNewEmail] = useState<MailingList>({
    id: 0,
    createdAt: "",
    email: "",
    base: "",
  })

  const columns: Array<Column<MailingList>> = [
    {
      field: "base",
      title: "Base",
      sort: true,
      filter: {
        type: "select",
        options: bases,
      },
    },
    {
      field: "email",
      title: "Email",
      sort: true,
      filter: {
        type: "textbox",
      },
    },
    {
      field: "createdAt",
      title: "TimeStamp",
      sort: true,
      render: (row: MailingList) => `${row.createdAt}`,
    },
    {
      field: "createdBy",
      title: "",
      render: (row: MailingList) => {
        return (
          <a
            onClick={() => {
              setDeletedMail(row)
              setIsDeleteOpen(true)
            }}
          >
            <FontAwesomeIcon icon={faTrash} className="ebp-foppt-l-icon" />
          </a>
        )
      },
    },
  ]

  const filterData = (data: MailingList[], filters: FiltersMailing) => {
    return data.filter((a) => {
      return (
        a.email.toLowerCase().includes(filters.email.toLowerCase()) &&
        a.base.toLowerCase().includes(filters.base.toLowerCase())
      )
    })
  }

  const changeEvent = (event: EventPayload<MailingList>) => {
    switch (event.type) {
      case "sort": {
        setSortOptions({
          sortColumn: event.column,
          sortDirection: event.direction,
        })
        const sortedData = sortData(
          [...mailingListData],
          event.column,
          event.direction
        )
        setMailingListData(sortedData)
        setFilteredData(filterData(sortedData, filterOptions))
        break
      }
      case "filter": {
        setFilterOptions({ ...filterOptions, [event.column]: event.value })
        setFilteredData(
          filterData([...mailingListData], {
            ...filterOptions,
            [event.column]: event.value,
          })
        )
        break
      }
    }
  }

  const resetForm = () => {
    setNewEmail({
      id: 0,
      base: "",
      createdAt: "",
      email: "",
    })

    setValidEmail(false)
  }

  useEffect(() => {
    if (isLoading && !isDataLoaded) {
      mailingList
        .get()
        .then((response) => {
          setMailingListData(response.mailingLists)
          setFilteredData(response.mailingLists)
          setIsDataLoaded(true)

          const basesResponse = response.bases.map((item) => ({
            value: item,
            label: item,
          }))

          const emptyValue = { label: "--Show all bases--", value: "" }

          const myItems = [emptyValue, ...basesResponse]
          setBases(myItems)
        })
        .catch((err) => {
          if (err.response !== undefined && err.response.status >= 400) {
            dispatch({
              type: "alert",
              payload: {
                id: "MailingList__fetchingListFailed",
                display: true,
                message: "Fetching Mailing list failed",
                type: "error",
              },
            })
          }
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [isLoading, dispatch, isDataLoaded])

  function validateEmail(e: React.FormEvent<HTMLInputElement>) {
    const { target } = e
    setValidEmail(
      sysTablesRegex.emailRegex.test((target as HTMLInputElement).value)
    )
  }

  const validateForm = () => {
    return validEmail && newEmail.base !== ""
  }

  function submitEmailForm() {
    setIsLoading(true)
    mailingList
      .post(newEmail)
      .then((response) => {
        setMailingListData([...mailingListData, response.mailingList])
        setFilteredData(
          filterData([...mailingListData, response.mailingList], filterOptions)
        )

        dispatch({
          type: "alert",
          payload: {
            id: "MailingList__emailAddedSuccess",
            display: true,
            message: `Email ${newEmail.email} was added to ${newEmail.base}`,
            type: "success",
          },
        })
      })
      .catch((err) => {
        if (err.response !== undefined && err.response.status >= 400) {
          dispatch({
            type: "alert",
            payload: {
              id: "MailingList__emailAddedError",
              display: true,
              message: err.response.data.errors.responseError[0],
              type: "error",
            },
          })
        }
      })
      .finally(() => {
        setIsLoading(false)
        setIsOpen(false)
        resetForm()
      })
  }

  function deleteMailHandler() {
    setIsLoading(true)
    mailingList
      .delete(deletedMail.id)
      .then(() => {
        const newData = mailingListData.filter(
          (item) => item.id !== deletedMail.id
        )
        setFilteredData(filterData(newData, filterOptions))
        setMailingListData(newData)
        dispatch({
          type: "alert",
          payload: {
            id: "MailingList__emailDeletedSuccess",
            display: true,
            message: `Email ${deletedMail.email} was deleted`,
            type: "success",
          },
        })

        setDeletedMail({
          id: 0,
          base: "",
          email: "",
          createdAt: "",
          createdBy: "",
        })
      })
      .catch((err) => {
        if (err.response !== undefined && err.response.status >= 400) {
          dispatch({
            type: "alert",
            payload: {
              id: "MailingList__emailDeletedError",
              display: true,
              message: "Deleting Mailing list failed",
              type: "error",
            },
          })
        }
      })
      .finally(() => {
        setIsLoading(false)
        setIsDeleteOpen(false)
        setIsOpen(false)
      })
  }

  return (
    <>
      <div className="ebp-foppt-l-modal-add">
        <ButtonGroup>
          <Button
            text="ADD EMAIL"
            onClick={() => {
              setIsOpen(true)
            }}
          ></Button>
        </ButtonGroup>
      </div>
      <Modal
        isActive={isOpen}
        closeButtonText="Close Dialog"
        onClose={() => {
          setIsOpen(false)
          resetForm()
        }}
      >
        <ModalHeader>
          <Heading id="modal-heading-1" tagName="h2">
            Add Email
          </Heading>
        </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={(e: React.ChangeEvent<HTMLInputElement>) => {
              e.preventDefault()
            }}
          >
            <SelectField
              items={bases}
              selected=""
              value={newEmail.base}
              setFocus={true}
              required
              label="Base:"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setNewEmail((prevState) => {
                  return {
                    ...prevState,
                    base: e.target.value,
                  }
                })
              }}
              optionalLabel=""
            />
            <TextField
              label="United Email:"
              type="text"
              placeholder="email"
              value={newEmail.email}
              required={true}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setNewEmail((prevState) => {
                  return {
                    ...prevState,
                    email: e.target.value,
                  }
                })
                validateEmail(e)
              }}
            />
          </Form>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup className="ebp-foppt-l-items--center">
            <Button
              text="Cancel"
              onClick={() => {
                setIsOpen(false)
                resetForm()
              }}
            />
            <Button
              variant="primary"
              type="submit"
              disabled={!validateForm()}
              text="Add"
              onClick={() => {
                setIsLoading(true)
                submitEmailForm()
              }}
            />
          </ButtonGroup>
        </ModalFooter>
      </Modal>
      <Modal
        dismissible={true}
        isActive={isDeleteOpen}
        onClose={() => {
          setIsDeleteOpen(false)
        }}
        ariaLabelledBy="modal-heading-1"
        ariaDescribedBy="modal-description-1"
      >
        <ModalHeader>
          <Heading id="modal-heading-1" tagName="h2">
            Do you want to delete this email?
          </Heading>
        </ModalHeader>
        <ModalBody>
          <p>
            {deletedMail.base} | {deletedMail.email}
          </p>
          <ButtonGroup>
            <Button
              text="Cancel"
              onClick={() => {
                setIsDeleteOpen(false)
              }}
            />
            <Button
              className="ebp-foppt-l-delete-button"
              text="Confirm"
              onClick={() => {
                deleteMailHandler()
              }}
            />
          </ButtonGroup>
        </ModalBody>
      </Modal>
      <CustomTable<MailingList>
        columns={columns}
        data={filteredData}
        loading={isLoading}
        options={sortOptions}
        onOptionsChange={changeEvent}
        tableType="Mailing Lists"
      />
    </>
  )
}
export default MailingList
