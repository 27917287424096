import React from "react"
import Search from "../../../components/UI/Search"
import { Icon } from "atmos-design-system"
import { EmployeePicture } from "../Picture"
import "./styles.scss"

interface EmployeeSearchProps {
  api: (params: URLSearchParams) => Promise<FormattedSearchEmployeeResponse[]>
  employee: SearchEmployeeResponse | null
  setEmployee: CallableFunction
  inline?: boolean
  required?: TextFieldError
  label?: string
  requiredLabel?: string
  labelAfter?: React.ReactNode
}

export function EmployeeSearch({
  api,
  employee,
  setEmployee,
  inline = true,
  required = null,
  ...props
}: EmployeeSearchProps) {
  function renderSearchResults(item: FormattedSearchEmployeeResponse) {
    return (
      <>
        <EmployeePicture picture={item.picture} size="xs-small" />
        {item.value}
      </>
    )
  }

  function onSelect(item: FormattedSearchEmployeeResponse) {
    setEmployee(item)
  }

  function onRemove() {
    setEmployee(null)
  }

  return (
    <>
      <div
        className={`ebp-foppt-c-employee-search${
          inline ? " ebp-foppt-c-employee-search__inline" : ""
        }`}
      >
        <Search<FormattedSearchEmployeeResponse>
          onSelect={onSelect}
          api={api}
          render={renderSearchResults}
          required={required}
          {...props}
        />
        {employee !== null && (
          <>
            <div className="ebp-foppt-c-employee-search__tag">
              <div className="ebp-foppt-c-employee-search__tag--profile">
                <EmployeePicture picture={employee.picture} size="x-small" />
                <span>{employee?.name}</span>
              </div>
              <button onClick={onRemove}>
                <Icon name="x" />
              </button>
            </div>
          </>
        )}
      </div>
    </>
  )
}
