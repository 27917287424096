import React from "react"
import MailingList from "src/components/MailingList"
import MainLayout from "src/components/UI/MainLayout"

const MailingListView = () => {
  return (
    <MainLayout context="systemTablesMail">
      <MailingList />
    </MainLayout>
  )
}
export default MailingListView
