import React, { useEffect, useState } from "react"
import {
  Modal,
  ModalHeader,
  ModalBody,
  Heading,
  ButtonGroup,
  Button,
} from "atmos-design-system"
import { useDispatch, useSelector } from "react-redux"
import Markdown from "react-markdown"
import { userRequest } from "src/api/api"
import type { UserState } from "src/api/user/interfaces"

const FlightsDisclaimer = () => {
  const user = useSelector((state: UserState) => state.user)
  const captainNotice = localStorage.getItem("captainNotice") ?? null
  const [showCaptainNotice, setShowCaptainNotice] = useState(false)
  const dispatch = useDispatch()

  const acknowledgeCaptainNotice = async () => {
    setShowCaptainNotice(false)
    userRequest
      .acknowledge()
      .then(() => {
        localStorage.setItem("captainNotice", "off")
      })
      .catch(() => {
        dispatch({
          type: "alert",
          payload: {
            id: "FlightDisclaimer__acknowledgeFailed",
            display: true,
            message: "Failed submitting acknowledgement",
            type: "error",
          },
        })
      })
  }

  useEffect(() => {
    if (captainNotice !== null) {
      setShowCaptainNotice(captainNotice === "on")
    }
  }, [captainNotice])

  return (
    <Modal
      variant="wide"
      dismissible={false}
      isActive={showCaptainNotice}
      focusLockProps={{
        persistentFocus: true,
        returnFocus: true,
      }}
      ariaLabelledBy="modal-heading-1"
      ariaDescribedBy="modal-description-1"
    >
      <ModalHeader>
        <Heading id="modal-heading-1" tagName="h2"></Heading>
      </ModalHeader>
      <ModalBody>
        <Markdown>{user.userSettings.labels.captainNotice}</Markdown>
        <hr />
        <div className="ebp-foppt-l-items--right">
          <ButtonGroup>
            <Button
              text="Accept"
              variant="primary"
              onClick={acknowledgeCaptainNotice}
            />
          </ButtonGroup>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default FlightsDisclaimer
