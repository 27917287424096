import type { ApiRequest } from "src/api/apiInterfaces"
import type { CaptainsResponse } from "./interfaces"

const searchCaptainsFormat = (
  response: SearchCaptainResults
): FormattedSearchCaptainResponse[] => {
  return response.captains.map((e) => ({
    id: e.employeeNo,
    value: `${e.name} (${e.employeeNo})`,
    picture: e.picture,
    base: e.base,
    employeeNo: e.employeeNo,
    name: e.name,
    role: e.role,
  }))
}
const CaptainsApi = (request: ApiRequest, getEndpoint: CallableFunction) => {
  return {
    get: async (params: URLSearchParams) =>
      await request.get<CaptainsResponse>(getEndpoint("captains"), {
        params,
      }),
    searchCaptains: async (params: URLSearchParams) =>
      await request
        .get<SearchCaptainResults>(getEndpoint("captains"), { params })
        .then((data) => {
          return searchCaptainsFormat(data)
        }),
  }
}

export default CaptainsApi
