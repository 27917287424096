import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { type RouteComponentProps, withRouter } from "react-router"
import { RoleType } from "src/api/apiTypes"
import type { UserState } from "src/api/user/interfaces"
import Loader from "src/components/UI/Loader"
import MainLayout from "src/components/UI/MainLayout"
import { Routes } from "src/utils/routes"

function HomePage({ history }: RouteComponentProps) {
  const user = useSelector((state: UserState) => state.user)
  useEffect(() => {
    if (user.uid === "") return
    switch (user.roleType) {
      case RoleType.Pilot:
        history.push(Routes.PilotsScorecard)
        break
      case RoleType.Contributor:
      case RoleType.Instructor:
        history.push(Routes.Flights)
        break
      case RoleType.SrStaffRep:
      case RoleType.Admin:
      case RoleType.SuperAdmin:
        history.push(Routes.Pilots)
        break
      default:
        history.push(Routes.Error)
    }
  }, [user, history])
  return (
    <MainLayout>
      <Loader display />
    </MainLayout>
  )
}

export default withRouter(HomePage)
