import React, { useEffect, useState } from "react"
import { faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  ButtonGroup,
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalFooter,
  Form,
  SelectField,
  TextField,
  ModalHeader,
} from "atmos-design-system"
import CustomTable from "src/components/UI/Table/Table"
import { rules } from "src/api"
import { useDispatch } from "react-redux"
import { sortData, sysTablesRegex } from "src/utils/functions"

const filterData = (data: RulesData[], filters: AppliedFilters) => {
  if (filters.attribute === "" && filters.role === "" && filters.value === "")
    return data
  return data.filter((a) => {
    return (
      (filters.attribute === "" || a.attribute === filters.attribute) &&
      (filters.role === "" || a.role === filters.role) &&
      (filters.value === "" ||
        a.value.toLowerCase().includes(filters.value.toLowerCase()))
    )
  })
}

function UserRoles() {
  const rolesValues = [
    {
      label: "Contributor",
      value: "Contributor",
    },
    {
      label: "Instructor",
      value: "Instructor",
    },
    {
      label: "Sr. Staff Rep",
      value: "Sr. Staff Rep",
    },
    {
      label: "Admin",
      value: "Admin",
    },
    {
      label: "Super Admin",
      value: "Super Admin",
    },
  ]

  const attributesValues = [
    {
      label: "Job Code",
      value: "Job Code",
    },
    {
      label: "User ID",
      value: "User ID",
    },
    {
      label: "Union Code",
      value: "Union Code",
    },
  ]

  const filterDefaultLabel = { label: "Show All", value: "" }
  const attributeItems = [filterDefaultLabel, ...attributesValues]
  const roleItems = [filterDefaultLabel, ...rolesValues]

  const columns: Array<Column<RulesData>> = [
    {
      field: "role",
      title: "ROLE",
      sort: true,
      filter: {
        options: roleItems,
        type: "select",
      },
    },
    {
      field: "attribute",
      title: "ATTRIBUTE",
      sort: true,
      filter: {
        options: attributeItems,
        type: "select",
      },
    },
    {
      field: "value",
      title: "VALUE",
      sort: true,
      filter: {
        type: "textbox",
      },
    },
    {
      field: "id",
      title: "",
      render: (row: RulesData) => {
        return (
          <a
            onClick={() => {
              setSelectedRuleIndex(data.findIndex((rule) => rule.id === row.id))
              setIsModalOpen(true)
            }}
          >
            <FontAwesomeIcon icon={faTrash} className="ebp-foppt-l-icon" />
          </a>
        )
      },
    },
  ]
  const [selectedRuleIndex, setSelectedRuleIndex] = useState<number>(-1)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [isAddOpen, setIsAddOpen] = useState<boolean>(false)
  const [data, setData] = useState<RulesData[]>([])
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false)
  const [filteredData, setFilteredData] = useState<RulesData[]>([])
  const [validRegex, setValidRegex] = useState<boolean>(false)
  const [newRule, setNewRule] = useState<Omit<RulesData, "id">>({
    role: "",
    value: "",
    attribute: "",
  })
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isDeleting, setIsDeleting] = useState<boolean>(false)
  const [sortOptions, setSortOptions] = useState<TableOptions<RulesData>>({
    sortColumn: "value",
    sortDirection: "Asc",
  })
  const [filterOptions, setFilterOptions] = useState<AppliedFilters>({
    role: "",
    attribute: "",
    value: "",
  })
  const dispatch = useDispatch()

  const changeEvent = (event: EventPayload<RulesData>) => {
    switch (event.type) {
      case "sort": {
        setSortOptions({
          sortColumn: event.column,
          sortDirection: event.direction,
        })
        const sortedData = sortData([...data], event.column, event.direction)
        setData(sortedData)
        setFilteredData(filterData(sortedData, filterOptions))
        break
      }
      case "filter": {
        setFilterOptions({ ...filterOptions, [event.column]: event.value })
        setFilteredData(
          filterData([...data], {
            ...filterOptions,
            [event.column]: event.value,
          })
        )
        break
      }
    }
  }

  const resetForm = () => {
    setNewRule({
      role: "",
      value: "",
      attribute: "",
    })
    setValidRegex(false)
  }

  useEffect(() => {
    if (isLoading && !isDataLoaded) {
      rules
        .get()
        .then((response) => {
          setData(response)
          setIsDataLoaded(true)
          setFilteredData(response)
        })
        .catch((err) => {
          if (err.response !== undefined && err.response.status >= 400) {
            dispatch({
              type: "alert",
              payload: {
                id: "Roles__fetchingListFailed",
                display: true,
                message: "Fetching Roles List failed",
                type: "error",
              },
            })
          }
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [isLoading, dispatch, isDataLoaded])

  const submitRuleForm = () => {
    setIsLoading(true)
    rules
      .post(newRule)
      .then((response) => {
        setData([...data, response.rule])
        setFilteredData(filterData([...data, response.rule], filterOptions))

        dispatch({
          type: "alert",
          payload: {
            id: "Roles__addedSuccess",
            display: true,
            message: `Role ${newRule.role} with attribute ${newRule.attribute}
             and value ${newRule.value} was added successfully`,
            type: "success",
          },
        })
      })
      .catch((err) => {
        if (err.response !== undefined && err.response.status >= 400) {
          dispatch({
            type: "alert",
            payload: {
              id: "Roles__addedError",
              display: true,
              message: err.response.data.errors.responseError[0],
              type: "error",
            },
          })
        }
      })
      .finally(() => {
        setIsLoading(false)
        setIsAddOpen(false)
        resetForm()
      })
  }

  const deleteSelectedRule = () => {
    setIsDeleting(true)
    rules
      .delete(data[selectedRuleIndex].id)
      .then(() => {
        setSelectedRuleIndex(-1)
        dispatch({
          type: "alert",
          payload: {
            id: "Roles__deletedSuccess",
            display: true,
            message: `The role ${data[selectedRuleIndex].role} with attribute 
            ${data[selectedRuleIndex].attribute} and value 
            ${data[selectedRuleIndex].value} was deleted`,
            type: "success",
          },
        })
        const newData = data.filter(
          (_rule, index) => index !== selectedRuleIndex
        )
        setData(newData)
        setFilteredData(filterData(newData, filterOptions))
      })
      .catch((err) => {
        if (err.response !== undefined && err.response.status >= 400) {
          dispatch({
            type: "alert",
            payload: {
              id: "Roles__deletedError",
              display: true,
              message: "Deleting Role failed",
              type: "error",
            },
          })
        }
      })
      .finally(() => {
        setIsDeleting(false)
        setIsModalOpen(false)
        setIsLoading(false)
      })
  }

  function validateRegex(e: React.FormEvent<HTMLInputElement>) {
    let regex: RegExp

    switch (newRule.attribute) {
      case "User ID":
        regex = sysTablesRegex.userIdRegex
        break
      case "Job Code":
        regex = sysTablesRegex.jobCodeRegex
        break
      case "Union Code":
        regex = sysTablesRegex.unionCodeRegex
        break
      default:
        return
    }
    const { target } = e
    setValidRegex(
      newRule.role !== "" && regex.test((target as HTMLInputElement).value)
    )
  }

  const defaultLabelOption = { label: "--Please Select a option--", value: "" }
  const roleLabels = [defaultLabelOption, ...rolesValues]
  const attributeLabels = [defaultLabelOption, ...attributesValues]

  return (
    <>
      <div className="ebp-foppt-l-modal-add">
        <ButtonGroup>
          <Button
            text="ADD ROLE"
            onClick={() => {
              setIsAddOpen(true)
            }}
          ></Button>
        </ButtonGroup>
      </div>
      <CustomTable
        columns={columns}
        data={filteredData}
        loading={isLoading || isDeleting}
        tableType="Roles"
        options={sortOptions}
        onOptionsChange={changeEvent}
      />
      <Modal
        isActive={isAddOpen}
        closeButtonText="Close Dialog"
        onClose={() => {
          setIsAddOpen(false)
          resetForm()
        }}
      >
        <ModalHeader>
          <Heading id="modal-heading-1" tagName="h2">
            Add Role
          </Heading>
        </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={(e: React.ChangeEvent<HTMLInputElement>) => {
              e.preventDefault()
            }}
          >
            <SelectField
              items={roleLabels}
              selected=""
              value={newRule?.role}
              setFocus={true}
              required
              label="Role:"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setNewRule((prevState) => {
                  return {
                    ...prevState,
                    role: e.target.value,
                    value: "",
                  }
                })
                setValidRegex(false)
              }}
              optionalLabel=""
            />
            <SelectField
              items={attributeLabels}
              value={newRule?.attribute}
              setFocus={true}
              required
              label="Attribute:"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setValidRegex(false)
                setNewRule((prevState) => {
                  return {
                    ...prevState,
                    attribute: e.target.value,
                    value: "",
                  }
                })
              }}
              optionalLabel=""
            />
            <TextField
              label="Value:"
              type="text"
              error={
                newRule.value.length >= 2 &&
                !validRegex &&
                newRule.attribute !== "" && {
                  message: `Enter a valid format for an ${newRule.attribute}`,
                }
              }
              value={newRule?.value}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setNewRule((prevState) => {
                  return {
                    ...prevState,
                    value: e.target.value,
                  }
                })
                validateRegex(e)
              }}
            />
          </Form>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup className="ebp-foppt-l-items--center">
            <Button
              text="Cancel"
              onClick={() => {
                setIsAddOpen(false)
                resetForm()
              }}
            />
            <Button
              variant="primary"
              type="submit"
              disabled={!validRegex}
              text="Add"
              onClick={() => {
                submitRuleForm()
              }}
            ></Button>
          </ButtonGroup>
        </ModalFooter>
      </Modal>
      <Modal
        dismissible={true}
        isActive={isModalOpen}
        onClose={() => {
          setIsModalOpen(false)
        }}
        ariaLabelledBy="modal-heading-1"
        ariaDescribedBy="modal-description-1"
      >
        <ModalHeader>
          <Heading id="modal-heading-1" tagName="h3">
            Do you want to delete this Role?
          </Heading>
        </ModalHeader>
        <ModalBody>
          <div>
            {selectedRuleIndex >= 0 && data.length > 0 && (
              <p>{`${data[selectedRuleIndex].role} / ${data[selectedRuleIndex].attribute} / ${data[selectedRuleIndex].value} `}</p>
            )}
            <p>This role will be deleted permanently</p>
            <ButtonGroup className="ebp-foppt-l-items--right">
              <Button
                text="Cancel"
                onClick={() => {
                  setIsModalOpen(false)
                }}
              />
              <Button
                className="ebp-foppt-l-delete-button"
                text="Confirm"
                onClick={deleteSelectedRule}
              />
            </ButtonGroup>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default UserRoles
