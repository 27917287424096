import React from "react"
import { BoxSection } from "atmos-design-system"

export default function GradingGuidelines() {
  return (
    <BoxSection title="Grading Guidelines:">
      <p>
        Please follow the grading guidelines defined below. Data integrity is
        enhanced when this grading scale is applied consistently and accurately.
      </p>

      <strong>1 - Excellent</strong>
      <p>
        Consistently performs and meets the highest of expectation. Proficiency
        regarding this item exceeds all standards.
      </p>

      <strong>2 - Above Expectations</strong>
      <p>
        Consistently strives to perform above expectation. Proficiency regarding
        this item meets standards and they are occasionally exceeded.
      </p>

      <strong>3 - Expected Performance</strong>
      <p>
        Generally performs and meets expectations. Proficiency regarding this
        item meets standards.
      </p>

      <strong>4 - Meets Minimum Standards</strong>
      <p>
        Occasionally performs below expectations. Proficiency regarding this
        item is adequate and could use improvement.
      </p>

      <strong>5 - Unsatisfactory</strong>
      <p>
        Consistently fails to perform within expectations. Proficiency regarding
        this item is not adequate and falls below standards.
      </p>
    </BoxSection>
  )
}
