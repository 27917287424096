import React from "react"
import "./styles.scss"

interface Props {
  label: string
  values: CheckboxesType[]
  setValues: (values: CheckboxesType[]) => void
}

function CheckboxItems({ label = "", values, setValues }: Props) {
  function getState(checked: boolean) {
    return checked ? "on" : "off"
  }

  return (
    <>
      <strong>{label}</strong>
      <div>
        {values.map((value, key) => {
          return (
            <label
              key={key}
              className={`ebp-foppt-u-cursor-hand ebp-foppt-c-checkboxitems ebp-foppt-c-checkboxitems--${getState(
                value.selected
              )}`}
            >
              <input
                type="checkbox"
                value={getState(value.selected)}
                checked={value.selected}
                onChange={() => {
                  const newState = values.map((item) => {
                    if (item.value === value.value) {
                      item.selected = !value.selected
                    }

                    return item
                  })
                  setValues(newState)
                }}
              />
              {value.label}
            </label>
          )
        })}
      </div>
    </>
  )
}

export default CheckboxItems
