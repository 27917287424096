import { SwitchType } from "./constants"

const oneDay = 1000 * 60 * 60 * 24

export function isDateTooOld(date: Date, howOld: number) {
  const daysOld = howOld * oneDay
  const dateAge = date.getTime() + daysOld

  return dateAge < Date.now()
}

export function howManyDaysLeft(date: Date, howOld: number) {
  const daysOld = howOld * oneDay
  const daysLeft = (date.getTime() - Date.now() + daysOld) / oneDay
  const toFloor = Math.floor(daysLeft)

  return toFloor > 1
    ? `${toFloor} days left`
    : toFloor < 0
    ? `Evaluation Time Expired`
    : "Last Day to evaluate"
}

export function sortData<T>(
  data: T[],
  column: keyof T,
  direction: "Asc" | "Des"
) {
  return data.sort((a, b) => {
    if (a[column] < b[column]) {
      return direction === "Asc" ? -1 : 1
    }
    if (a[column] > b[column]) {
      return direction === "Asc" ? 1 : -1
    }
    return 0
  })
}

export function onDev() {
  return process.env["NODE_ENV"] === "development"
}

export function onLocal() {
  return process.env["LOCAL_API"] === SwitchType.On
}

interface filterSettingsProps {
  bases?: CheckboxesType[] | null
  status?: CheckboxesType[] | null
}
const PILOTS_FILTER_SETTINGS_KEY = "pilots_filter_settings"
const CAPTAINS_FILTER_SETTINGS_KEY = "captains_filter_settings"

function getFilterSettings(key: string) {
  const json = localStorage.getItem(key)
  const settings = {
    bases: null,
    status: null,
  }

  if (json !== null) {
    return JSON.parse(json)
  }

  return settings
}

function removeFilterSettings(key: string) {
  localStorage.removeItem(key)
}

function saveFilterSettings(
  key: string,
  { bases = null, status = null }: filterSettingsProps
) {
  let settings = getFilterSettings(key)

  if (bases !== null) {
    settings = { ...settings, bases }
  }
  if (status !== null) {
    settings = { ...settings, status }
  }

  localStorage.setItem(key, JSON.stringify(settings))
}

export function getPilotsFilterSettings() {
  return getFilterSettings(PILOTS_FILTER_SETTINGS_KEY)
}

export function removePilotsFilterSettings() {
  removeFilterSettings(PILOTS_FILTER_SETTINGS_KEY)
}

export function savePilotsFilterSettings(data: filterSettingsProps) {
  saveFilterSettings(PILOTS_FILTER_SETTINGS_KEY, data)
}

export function getCaptainsFilterSettings() {
  return getFilterSettings(CAPTAINS_FILTER_SETTINGS_KEY)
}

export function removeCaptainsFilterSettings() {
  removeFilterSettings(CAPTAINS_FILTER_SETTINGS_KEY)
}

export function saveCaptainsFilterSettings(data: filterSettingsProps) {
  saveFilterSettings(CAPTAINS_FILTER_SETTINGS_KEY, data)
}

export const sysTablesRegex = {
  userIdRegex: /^(a|e|f|g|i|n|s|u|v)[0-9]{6}$/i,
  jobCodeRegex: /^\d{6}$/,
  unionCodeRegex: /^[a-z]{2,3}$/i,
  emailRegex: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
}
