import React from "react"
import { withRouter } from "react-router-dom"
import MainLayout from "src/components/UI/MainLayout"
import AdminPilotTabs from "src/components/Pilot/Tabs/AdminPilotTabs"

interface defaultProps {
  match: {
    params: {
      id: string
    }
  }
}

const AdminPilotView = ({ match: { params } }: defaultProps) => {
  const { id } = params
  return (
    <MainLayout context="pilotProfileView">
      <AdminPilotTabs id={id} />
    </MainLayout>
  )
}

export default withRouter(AdminPilotView)
