import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { captain } from "src/api"
import CaptainEvaluations from "./CaptainEvaluations"
import CaptainCard from "./CaptainCard"

interface CaptainDetailsProps {
  id: string
}

const CaptainDetails = ({ id }: CaptainDetailsProps) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true)
  const [captainDetails, setCaptainDetails] = useState<CaptainProfile>({
    name: "",
    base: "",
    picture: "",
    role: "",
  })
  useEffect(() => {
    if (isLoading) {
      captain
        .getDetails(id)
        .then((response) => {
          setIsLoading(false)
          setCaptainDetails(response)
        })
        .catch((err) => {
          setIsLoading(false)
          if (err.response !== undefined && err.response.status >= 400) {
            dispatch({
              type: "alert",
              payload: {
                id: "CaptainView__fetchingDetailsFailed",
                display: true,
                message: "Fetching Details failed",
                type: "error",
              },
            })
          }
        })
    }
  }, [dispatch, id, isLoading])

  return (
    <>
      <CaptainCard
        captainName={captainDetails.name}
        captainPicture={captainDetails.picture}
        base={captainDetails.base}
        role={captainDetails.role}
      />
      <CaptainEvaluations captainId={id} />
    </>
  )
}

export default CaptainDetails
