import type { ApiRequest } from "src/api/apiInterfaces"
import type { PilotsResponse, PilotsScorecardResponse } from "./interfaces"

const PilotsApi = (request: ApiRequest, getEndpoint: CallableFunction) => {
  return {
    get: async (params: URLSearchParams) =>
      await request.get<PilotsResponse>(getEndpoint("pilots"), {
        params,
      }),
    getPilotsScorecard: async (id: string) =>
      await request.get<PilotsScorecardResponse>(
        getEndpoint("pilotsScoreCard", id)
      ),
  }
}

export default PilotsApi
