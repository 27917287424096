import React from "react"
import CaptainsList from "src/components/Captains/CaptainsList"
import MainLayout from "src/components/UI/MainLayout"

const CaptainsListView = () => {
  return (
    <MainLayout context="captainsListView">
      <CaptainsList />
    </MainLayout>
  )
}

export default CaptainsListView
