import React, { useEffect, useState } from "react"
import CustomTable from "src/components/UI/Table/Table"
import { useDispatch } from "react-redux"
import {
  Modal,
  ModalHeader,
  SelectField,
  ModalBody,
  ModalFooter,
  Heading,
  TextField,
  Form,
  ButtonGroup,
  Button,
} from "atmos-design-system"
import { userBases } from "src/api"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash } from "@fortawesome/free-solid-svg-icons"
import { sortData, sysTablesRegex } from "src/utils/functions"

const UserBases = () => {
  const [isAddOpen, setIsAddOpen] = useState<boolean>(false)
  const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false)
  const [userBasesData, setUserBasesData] = useState<UserBase[]>([])
  const [validEmployeeId, setValidEmployeeId] = useState<boolean>(false)
  const [filteredData, setFilteredData] = useState<UserBase[]>([])
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [newUserBase, setNewUserBase] = useState<UserBase>({
    employeeId: "",
    base: "",
    createdAt: "",
    id: 0,
  })
  const [deletedUserBase, setDeletedUserBase] = useState<UserBase>({
    base: "",
    employeeId: "",
    createdAt: "",
    id: 0,
  })
  const [bases, setBases] = useState([
    {
      label: "",
      value: "",
    },
  ])
  const [sortOptions, setSortOptions] = useState<TableOptions<UserBase>>({
    sortColumn: "employeeId",
    sortDirection: "Asc",
  })
  const [filterOptions, setFilterOptions] = useState<FiltersUserBases>({
    base: "",
    employeeId: "",
  })
  const dispatch = useDispatch()

  const columns: Array<Column<UserBase>> = [
    {
      field: "base",
      title: "Base",
      sort: true,
      filter: {
        type: "select",
        options: bases,
      },
    },
    {
      field: "employeeId",
      title: "Employee Id",
      sort: true,
      filter: {
        type: "textbox",
      },
    },
    {
      field: "employeeId",
      title: "",
      render: (row: UserBase) => {
        return (
          <a
            onClick={() => {
              setIsDeleteOpen(true)
              setDeletedUserBase(row)
            }}
          >
            <FontAwesomeIcon icon={faTrash} className="ebp-foppt-l-icon" />
          </a>
        )
      },
    },
  ]

  useEffect(() => {
    if (isLoading && !isDataLoaded) {
      userBases
        .get()
        .then((response) => {
          setUserBasesData(response.userBases)
          setFilteredData(response.userBases)
          setIsDataLoaded(true)

          const basesResponse = response.bases.map((item) => ({
            value: item,
            label: item,
          }))

          const emptyValue = { label: "--Show all bases--", value: "" }

          const myItems = [emptyValue, ...basesResponse]
          setBases(myItems)
        })
        .catch((err) => {
          if (err.response !== undefined && err.response.status >= 400) {
            dispatch({
              type: "alert",
              payload: {
                id: "UserBases__fetchingListFailed",
                display: true,
                message: "Fetching User Bases failed",
                type: "error",
              },
            })
          }
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [isLoading, dispatch, isDataLoaded])

  const filterData = (data: UserBase[], filters: FiltersUserBases) => {
    if (filters.employeeId === "" && filters.base === "") return data
    return data.filter((a) => {
      return (
        a.employeeId.toLowerCase().includes(filters.employeeId.toLowerCase()) &&
        a.base.toLowerCase().includes(filters.base.toLowerCase())
      )
    })
  }

  const changeEvent = (event: EventPayload<UserBase>) => {
    switch (event.type) {
      case "sort": {
        setSortOptions({
          sortColumn: event.column,
          sortDirection: event.direction,
        })
        const sortedData = sortData(
          [...userBasesData],
          event.column,
          event.direction
        )
        setUserBasesData(sortedData)
        setFilteredData(filterData(sortedData, filterOptions))
        break
      }
      case "filter": {
        setFilterOptions({ ...filterOptions, [event.column]: event.value })
        setFilteredData(
          filterData([...userBasesData], {
            ...filterOptions,
            [event.column]: event.value,
          })
        )
        break
      }
    }
  }

  const validateEmployeeId = (e: React.FormEvent<HTMLInputElement>) => {
    const { target } = e
    setValidEmployeeId(
      sysTablesRegex.userIdRegex.test((target as HTMLInputElement).value)
    )
  }

  const validateForm = () => {
    return newUserBase.base !== "" && validEmployeeId
  }

  const resetForm = () => {
    setNewUserBase({
      id: 0,
      base: "",
      createdAt: "",
      employeeId: "",
    })
    setValidEmployeeId(false)
  }

  function submitNewUserBaseForm() {
    userBases
      .post(newUserBase)
      .then((response) => {
        setUserBasesData([...userBasesData, response.userBase])
        setFilteredData(
          filterData([...userBasesData, response.userBase], filterOptions)
        )

        dispatch({
          type: "alert",
          payload: {
            id: "UserBases__addedSuccess",
            display: true,
            message: `User Base ${newUserBase.base} with employee Id ${newUserBase.employeeId}
            was added`,
            type: "success",
          },
        })
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.status >= 400) {
          dispatch({
            type: "alert",
            payload: {
              id: "UserBases__addedError",
              display: true,
              message: error.response.data.errors.responseError[0],
              type: "error",
            },
          })
        }
      })
      .finally(() => {
        resetForm()
        setIsLoading(false)
        setIsAddOpen(false)
      })
  }

  function deleteUserBaseHandler() {
    setIsLoading(true)
    userBases
      .delete(deletedUserBase.id)
      .then(() => {
        const newData = userBasesData.filter(
          (item) => item.id !== deletedUserBase.id
        )
        setFilteredData(filterData(newData, filterOptions))
        setUserBasesData(newData)
        dispatch({
          type: "alert",
          payload: {
            id: "UserBases__deletedSuccess",
            display: true,
            message: `Employee Id ${deletedUserBase.employeeId} 
            with User base ${deletedUserBase.base} was deleted`,
            type: "success",
          },
        })

        setDeletedUserBase({
          base: "",
          employeeId: "",
          createdAt: "",
          id: 0,
        })
      })
      .catch((err) => {
        if (err.response !== undefined && err.response.status >= 400) {
          dispatch({
            type: "alert",
            payload: {
              id: "UserBases__deletedError",
              display: true,
              message: "Deleting User Base failed",
              type: "error",
            },
          })
        }
      })
      .finally(() => {
        setIsLoading(false)
        setIsDeleteOpen(false)
      })
  }
  return (
    <>
      <div className="ebp-foppt-l-modal-add">
        <ButtonGroup>
          <Button
            text="ADD USER BASE"
            onClick={() => {
              setIsAddOpen(true)
            }}
          ></Button>
        </ButtonGroup>
      </div>
      <Modal
        isActive={isAddOpen}
        closeButtonText="Close Dialog"
        onClose={() => {
          setIsAddOpen(false)
          resetForm()
        }}
      >
        <ModalHeader>
          <Heading id="modal-heading-1" tagName="h2">
            Add User Base
          </Heading>
        </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={(e: React.ChangeEvent<HTMLInputElement>) => {
              e.preventDefault()
            }}
          >
            <SelectField
              setFocus={true}
              required
              value={newUserBase.base}
              items={bases}
              label="Base:"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setNewUserBase((prevState) => {
                  return {
                    ...prevState,
                    base: e.target.value,
                  }
                })
              }}
              optionalLabel=""
            />
            <TextField
              label="Employee Id:"
              type="text"
              placeholder="United Id"
              value={newUserBase.employeeId}
              required={true}
              maxLength={7}
              error={
                newUserBase.employeeId.length >= 7 &&
                !validEmployeeId && {
                  message: "Enter a valid format for an employee id",
                }
              }
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setNewUserBase((prevState) => {
                  return {
                    ...prevState,
                    employeeId: e.target.value,
                  }
                })
                validateEmployeeId(e)
              }}
            />
          </Form>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup className="ebp-foppt-l-items--center">
            <Button
              text="Cancel"
              onClick={() => {
                setIsAddOpen(false)
                resetForm()
              }}
            />
            <Button
              variant="primary"
              type="submit"
              disabled={!validateForm()}
              text="Add"
              onClick={() => {
                setIsLoading(true)
                submitNewUserBaseForm()
              }}
            />
          </ButtonGroup>
        </ModalFooter>
      </Modal>
      <Modal
        dismissible={true}
        isActive={isDeleteOpen}
        onClose={() => {
          setIsDeleteOpen(false)
        }}
        ariaLabelledBy="modal-heading-1"
        ariaDescribedBy="modal-description-1"
      >
        <ModalHeader>
          <Heading id="modal-heading-1" tagName="h2">
            Do you want to delete this user base?
          </Heading>
        </ModalHeader>
        <ModalBody>
          <p>
            {deletedUserBase?.employeeId} | {deletedUserBase?.base}
          </p>
          <ButtonGroup className="ebp-foppt-l-items--right">
            <Button
              text="Cancel"
              onClick={() => {
                setIsDeleteOpen(false)
              }}
            />
            <Button
              className="ebp-foppt-l-delete-button"
              text="Confirm"
              onClick={() => {
                deleteUserBaseHandler()
              }}
            />
          </ButtonGroup>
        </ModalBody>
      </Modal>
      <CustomTable<UserBase>
        columns={columns}
        data={filteredData}
        loading={isLoading}
        options={sortOptions}
        onOptionsChange={changeEvent}
        tableType="User Bases"
      />
    </>
  )
}
export default UserBases
