import React from "react"
import { Logo } from "atmos-design-system"
import "./styles.scss"

interface LoaderProps {
  display: boolean
}

function Loader({ display = false }: LoaderProps): JSX.Element {
  return (
    <>
      {display && (
        <div className="ebp-foppt-c-loader">
          <div className="ebp-foppt-c-loader__container">
            <div className="ebp-foppt-c-loader__box">
              <div className="ebp-foppt-c-loader__logo">
                <Logo href="#" title="United Airlines homepage" />
                Please wait ...
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Loader
