import React from "react"
import moment from "moment"
import { DatepickerFieldSingle } from "atmos-design-system"
import { DATE_FORMAT } from "src/utils/constants"
import "./styles.scss"

interface DatepickerProps {
  label: StringNull
  date: StringNull
  setDate: CallableFunction
  disabled?: boolean
  isOutsideRange?: (date: moment.Moment) => boolean
}

export const Datepicker = ({
  label = null,
  date,
  setDate,
  disabled = false,
  isOutsideRange = () => false,
}: DatepickerProps) => {
  return (
    <>
      <DatepickerFieldSingle
        label={label}
        displayFormat={DATE_FORMAT}
        placeholder={DATE_FORMAT}
        date={date !== null ? moment(date) : null}
        onChange={(date: moment.Moment) => {
          setDate(date !== null ? date.format(DATE_FORMAT) : null)
        }}
        disabled={disabled}
        numberOfMonths={1}
        isOutsideRange={isOutsideRange}
        className={`ebp-foppt-c-datepicker ${
          disabled ? "ebp-foppt-c-datepicker__disabled" : ""
        } ${date === null ? "ebp-foppt-c-datepicker__error" : ""}`}
      />
    </>
  )
}
