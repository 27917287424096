import React, { useEffect, useState } from "react"
import { Tabs, Tab } from "atmos-design-system"
import PilotCard from "src/components/Pilot/Card"
import { pilot } from "src/api"
import { useDispatch, useSelector } from "react-redux"
import Scorecard from "src/components/Pilot/Scorecard"
import Summary from "../Summary"

interface PilotTabsProps {
  id: string
}

export const AdminPilotTabs = ({ id = "" }: PilotTabsProps) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [scoreCardData, setScorecardData] = useState<PilotCategory[]>([])
  const pilotData = useSelector((state: PilotState) => state.pilot)
  const [loadPilotData, setLoadPilotData] = useState(false)
  const [pilotInfo, setPilotInfo] = useState<PilotInfo>({
    name: "-",
    base: "-",
    dateOfHire: "-",
    employeeNo: "-",
    estimatedProbationEndDate: "-",
    fleetType: "-",
    picture: null,
    status: "-",
  })

  useEffect(() => {
    if (pilotData === null) {
      setLoadPilotData(true)
    } else {
      setPilotInfo(pilotData)
    }
    setIsLoading(true)

    return () => {
      dispatch({
        type: "pilot",
        payload: null,
      })
    }
  }, [dispatch, pilotData])

  useEffect(() => {
    if (isLoading) {
      dispatch({ type: "loader" })
      pilot
        .getScorecard(id)
        .then((response) => {
          setScorecardData(response.gradings)
          if (loadPilotData) setPilotInfo(response.pilotInfo)
        })
        .catch((err) => {
          if (err.response !== undefined && err.response.status >= 400) {
            dispatch({
              type: "alert",
              payload: {
                id: "AdminPilotTabs__fetchingScorecardFailed",
                display: true,
                message: "Fetching Scorecard failed",
                type: "error",
              },
            })
          }
        })
        .finally(() => {
          dispatch({ type: "loader" })
        })
    }
  }, [dispatch, loadPilotData, id, isLoading])

  return (
    <>
      <PilotCard
        pilotName={pilotInfo.name}
        pilotPicture={pilotInfo.picture}
        base={pilotInfo.base}
        fleetType={pilotInfo.fleetType}
        dateOfHire={pilotInfo.dateOfHire}
        endDate={pilotInfo.estimatedProbationEndDate}
        pilotStatus={pilotInfo.status}
      />
      <Tabs variant="small" className="ebp-foppt-tabs">
        <Tab title={"Summary"}>
          <Summary id={id} />
        </Tab>
        <Tab title={"Scorecard"}>
          <Scorecard data={scoreCardData} />
        </Tab>
      </Tabs>
    </>
  )
}

export default AdminPilotTabs
