import type { ApiRequest } from "src/api/apiInterfaces"

const UserBasesApi = (request: ApiRequest, getEndpoint: CallableFunction) => {
  return {
    get: async () =>
      await request.get<UserBasesResponse>(getEndpoint("userBases")),

    post: async (body: UserBase) =>
      await request.post<UserBasesPostResponse>(getEndpoint("userBases"), body),

    delete: async (id: number) =>
      await request.delete(getEndpoint("userBases", id)),
  }
}

export default UserBasesApi
