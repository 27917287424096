import React, { type PropsWithChildren } from "react"
import img from "../../../../public/assets/images/user_profile.jpg"
import "./styles.scss"

interface CardProps {
  picture: string | null | undefined
}

function Card({
  picture = null,
  children,
}: PropsWithChildren<CardProps>): JSX.Element {
  return (
    <div className="atm-c-band--rhapsody-blue ebp-foppt-c-card">
      <div className="ebp-foppt-c-card__container">
        <div className="ebp-foppt-c-card__margin">
          <div className="ebp-foppt-c-card__box">
            <div className="ebp-foppt-c-card__img">
              <img src={picture ?? img} />
            </div>
          </div>
        </div>
      </div>
      <div style={{ height: 60 }}></div>
      <div className="ebp-foppt-l-items--align-center">{children}</div>
    </div>
  )
}

export default Card
