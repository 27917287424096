import React from "react"
import { Icon } from "atmos-design-system"

interface QuickEditActionsProps {
  editionMode: boolean
  updateEditionMode: (payload: boolean) => void
  cancelEdit: () => void
}

export default function QuickEditActions({
  editionMode,
  updateEditionMode,
  cancelEdit,
}: QuickEditActionsProps) {
  return (
    <div className="ebp-foppt-c-evaluation-form__quick-edition">
      {editionMode ? (
        <a className="ebp-foppt-u-cursor-hand" onClick={cancelEdit}>
          <Icon name="x" />
        </a>
      ) : (
        <a
          className="ebp-foppt-u-cursor-hand"
          onClick={() => {
            updateEditionMode(true)
          }}
        >
          <Icon name="edit" />
        </a>
      )}
    </div>
  )
}
