import React from "react"
import { Table, TableCell, TableBody, TableRow } from "atmos-design-system"
import "./styles.scss"
import { CustomHeader } from "./Header"
import { CustomBody } from "./Body"
import Loader from "../Loader"

function CustomTable<T>(props: TableProps<T>): JSX.Element {
  const {
    columns,
    data,
    options,
    loading,
    tableType,
    onOptionsChange,
    rowRenderer,
    onRowClick,
  } = props

  function sortClick(column: keyof T) {
    if (onOptionsChange !== undefined) {
      if (options?.sortDirection === "Asc") {
        onOptionsChange({ type: "sort", column, direction: "Des" })
      } else onOptionsChange({ type: "sort", column, direction: "Asc" })
    }
  }

  function filterChange(column: keyof T, value: string) {
    if (onOptionsChange !== undefined) {
      onOptionsChange({ type: "filter", column, value })
    }
  }

  return (
    <>
      <Table stackedFirstCellAsHeading={false} className="ebp-foppt-table">
        <CustomHeader<T>
          columns={columns}
          options={options}
          sortClick={sortClick}
          filterChange={filterChange}
        />
        {data.length > 0 ? (
          <CustomBody<T>
            columns={columns}
            data={data}
            rowRenderer={rowRenderer}
            onRowClick={onRowClick}
          />
        ) : (
          !loading && (
            <TableBody>
              <TableRow>
                <TableCell colSpan={8}>
                  <h3>There are no {tableType ?? "entries"} to display</h3>
                </TableCell>
              </TableRow>
            </TableBody>
          )
        )}
      </Table>
      {loading && <Loader display={true} />}
    </>
  )
}

export default CustomTable
