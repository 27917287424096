import React from "react"
import MainLayout from "src/components/UI/MainLayout"
import PilotsList from "src/components/Pilots/PilotsList"

function PilotsView() {
  return (
    <MainLayout context="pilotsListView">
      <PilotsList />
    </MainLayout>
  )
}

export default PilotsView
