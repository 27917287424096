import { useCallback, useReducer } from "react"
import { useDispatch } from "react-redux"
import { completedEvaluations } from "src/api"
import type {
  CompletedEvaluationsFormattedResponse,
  GetCompletedEvaluationParams,
} from "src/api/completedEvaluations/interfaces"
type ExtraStateActions =
  | { type: "archived" }
  | {
      type: "loadPage"
      payload: {
        response: CompletedEvaluationsFormattedResponse
        append: boolean
      }
    }
  | { type: "isFetching"; payload: boolean }
  | { type: "captainId"; payload: string }
  | {
      type: "sortChange"
      payload: {
        sortColumn: keyof CompletedEvaluations
        sortDirection: "Asc" | "Des"
      }
    }
  | { type: "lastCall"; payload: boolean }

interface StateTypes {
  sortColumn: keyof CompletedEvaluations
  sortDirection: "Asc" | "Des"
  page: number
  pageSize: number
  lastCall: boolean
  isFetching: boolean
  archived: boolean
  captainId: string
  data: CompletedEvaluations[]
}

const INITIAL_STATE_EXTRA: StateTypes = {
  sortColumn: "evaluationDate",
  sortDirection: "Des",
  page: 1,
  pageSize: 25,
  lastCall: false,
  isFetching: true,
  archived: false,
  captainId: "",
  data: [],
}

function completeEvaluationStateControls(
  state: StateTypes,
  action: ExtraStateActions
): StateTypes {
  switch (action.type) {
    case "archived": {
      return {
        ...state,
        [action.type]: !state[action.type],
        page: 1,
        data: [],
        isFetching: true,
      }
    }
    case "isFetching": {
      return { ...state, [action.type]: action.payload }
    }
    case "captainId": {
      return { ...state, [action.type]: action.payload }
    }
    case "sortChange": {
      return {
        ...state,
        sortDirection: action.payload.sortDirection,
        sortColumn: action.payload.sortColumn,
        page: 1,
        data: [],
        isFetching: true,
      }
    }
    case "loadPage": {
      return {
        ...state,
        isFetching: false,
        data: action.payload.append
          ? [...state.data, ...action.payload.response.evaluations]
          : action.payload.response.evaluations,
        page: Number(action.payload.response.pagination.page) + 1,
        lastCall: action.payload.response.evaluations.length < state.pageSize,
      }
    }
    case "lastCall": {
      return {
        ...state,
        lastCall: action.payload,
      }
    }
    default: {
      return { ...state }
    }
  }
}

function useCompleteEvaluation() {
  const reduxDispatch = useDispatch()
  const [states, dispatch] = useReducer(
    completeEvaluationStateControls,
    INITIAL_STATE_EXTRA
  )

  const setIsFetching = useCallback((payload: boolean) => {
    dispatch({ type: "isFetching", payload })
  }, [])

  const getParams = useCallback(
    (
      append: boolean,
      captainId?: string,
      sort?: {
        column: keyof CompletedEvaluations
        direction: "Asc" | "Des"
      },
      archived?: boolean
    ): GetCompletedEvaluationParams => {
      return {
        page: append ? states.page : 1,
        pageSize: states.pageSize,
        captainId: captainId ?? states.captainId,
        sortDirection: sort?.direction ?? states.sortDirection,
        sortColumn: sort?.column ?? states.sortColumn,
        archived: archived ?? states.archived,
      }
    },
    [
      states.archived,
      states.captainId,
      states.page,
      states.pageSize,
      states.sortColumn,
      states.sortDirection,
    ]
  )

  const loadEvaluations = useCallback(
    (
      append = true,
      captainId?: string,
      sort?: {
        column: keyof CompletedEvaluations
        direction: "Asc" | "Des"
      },
      archived?: boolean
    ) => {
      setIsFetching(true)
      completedEvaluations
        .get(getParams(append, captainId, sort, archived))
        .then((response) => {
          dispatch({
            type: "loadPage",
            payload: { append, response },
          })
        })
        .catch((err) => {
          if (
            err?.code === "ERR_NETWORK" ||
            (err?.response !== undefined && err.response?.status >= 400)
          ) {
            dispatch({ type: "lastCall", payload: false })
            reduxDispatch({
              type: "alert",
              payload: {
                id: "CompletedEvaluations__fetchingFailed",
                display: true,
                message: "Fetching Completed Evaluations failed",
                type: "error",
              },
            })
          }
          setIsFetching(false)
        })
    },
    [setIsFetching, getParams, reduxDispatch]
  )

  const toggleArchived = useCallback(() => {
    dispatch({ type: "archived" })
    loadEvaluations(false, undefined, undefined, !states.archived)
  }, [loadEvaluations, states.archived])

  const changeSorting = useCallback(
    (payload: {
      sortDirection: "Asc" | "Des"
      sortColumn: keyof CompletedEvaluations
    }) => {
      dispatch({ type: "sortChange", payload })
      loadEvaluations(false, undefined, {
        column: payload.sortColumn,
        direction: payload.sortDirection,
      })
    },
    [loadEvaluations]
  )

  const setCaptainId = useCallback(
    (payload: string) => {
      dispatch({ type: "captainId", payload })
      loadEvaluations(false, payload)
    },
    [loadEvaluations]
  )

  return {
    states,
    toggleArchived,
    changeSorting,
    getParams,
    setIsFetching,
    setCaptainId,
    loadEvaluations,
  }
}

export default useCompleteEvaluation
