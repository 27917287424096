import React, { useEffect, useState } from "react"
import { LayoutContainer } from "atmos-design-system"
import Header from "./Header"
import Loader from "./Loader"
import { useSelector } from "react-redux"
import { type RouteComponentProps, withRouter } from "react-router"
import type { UserContext, UserState } from "src/api/user/interfaces"

interface MainLayoutProps extends RouteComponentProps {
  children: React.ReactNode
  context?: keyof UserContext
}

interface LoaderState {
  loader: boolean
}

function MainLayout({ history, context, children }: MainLayoutProps) {
  const loader = useSelector((state: LoaderState) => {
    return state.loader
  })
  const [hasPermission, setHasPermission] = useState(false)
  const user = useSelector((state: UserState) => state.user)
  useEffect(() => {
    if (
      context !== undefined &&
      user.uid !== "" &&
      user.context[context] !== undefined
    ) {
      if (user.context[context]) setHasPermission(true)
      else history.push("/error")
    } else if (context === undefined) setHasPermission(true)
  }, [user, context, history])

  return (
    <>
      <Header />
      <LayoutContainer>{hasPermission ? children : null}</LayoutContainer>
      <Loader display={loader} />
    </>
  )
}

export default withRouter(MainLayout)
