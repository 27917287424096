import React from "react"
import UserRoles from "src/components/UserRoles"
import MainLayout from "src/components/UI/MainLayout"

const UserRolesView = () => {
  return (
    <MainLayout context="systemTablesRoles">
      <UserRoles />
    </MainLayout>
  )
}

export default UserRolesView
