import React, { useEffect, useState, useCallback } from "react"
import Popover, { PopoverPlacement } from "@idui/react-popover"
import { type RouteComponentProps, withRouter } from "react-router"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFlag } from "@fortawesome/free-solid-svg-icons"
import {
  Button,
  Icon,
  Modal,
  ModalHeader,
  Heading,
  ModalBody,
  ButtonGroup,
} from "atmos-design-system"
import CustomTable from "../UI/Table/Table"
import usePilotsList from "./hooks/usePilotList"
import Search from "../UI/Search"
import CheckboxItems from "../UI/CheckboxItems"
import { pilot } from "src/api"
import img from "../../../public/assets/images/user_profile.jpg"
import "./styles.scss"
import { useDispatch, useSelector } from "react-redux"
import type { UserState, UserContext } from "src/api/user/interfaces"
import { Routes } from "src/utils/routes"
import GoTop from "../UI/GoTop"
import { IntersectionObserverElement } from "../UI/IntersectionObserverElement"
import {
  getPilotsFilterSettings,
  removePilotsFilterSettings,
  savePilotsFilterSettings,
} from "src/utils/functions"
import { RoleType } from "src/api/apiTypes"
import { EmployeePicture } from "../Employee/Picture"

interface LoggedUserState {
  loggedUser: {
    uid: string
    picture: string
    name: string
    role: string
    roleType: RoleType
    context: UserContext
  }
}

function PilotsList({ history }: RouteComponentProps) {
  const [openModal, setOpenModal] = useState<boolean>(false)
  const {
    states,
    changeSorting,
    loadPilots,
    setBases,
    setStatus,
    setBasesAndStatus,
    loadBasesAndStatus,
  } = usePilotsList()
  const dispatch = useDispatch()
  const loggedUser = useSelector((state: LoggedUserState) => state.loggedUser)
  const isSuperAdmin = loggedUser.roleType === RoleType.SuperAdmin
  const isAdmin = loggedUser.roleType === RoleType.Admin
  const defaultBase = useSelector((state: UserState) => state.user.base)
  const context = useSelector((state: UserState) => state.user.context)
  const flags: Record<string, Flags> = {
    Yellow: {
      color: "yellow",
      message:
        "More evaluations needed - does not meet minimum 10 evaluations by month 6 ",
    },
    Orange: {
      color: "orange",
      message:
        "More evaluations needed - does not meet minimum 15 evaluations by month 9 ",
    },
  }

  const columns: Array<Column<PilotInfo>> = [
    {
      field: "flag",
      title: "",
      sort: false,
      render: (row) => {
        const flag = row.flag ?? ""
        return (
          <p>
            {Object.keys(flags).includes(flag) && (
              <Popover
                content={flags[flag].message}
                placement={PopoverPlacement.right}
              >
                <FontAwesomeIcon
                  icon={faFlag}
                  className={`ebp-foppt-l-icon-${flags[flag].color ?? ""}`}
                />
              </Popover>
            )}
          </p>
        )
      },
    },
    {
      field: "name",
      title: "Employee",
      sort: false,
      render: (row: PilotInfo) => (
        <>
          <div className="ebp-foppt-employe-container">
            <div className="ebp-foppt-pilots-image-cropper-tbl">
              <img
                src={
                  row.picture !== "" && row.picture !== null ? row.picture : img
                }
                alt="pilot picture"
              />
            </div>
            <div>
              <p className="ebp-foppt-no-margin ebp-foppt-pilot-name">
                {row.name}
              </p>
              <p className="ebp-foppt-no-margin">{row.employeeNo}</p>
            </div>
          </div>
        </>
      ),
    },
    {
      field: "base",
      title: "Base",
      sort: false,
    },
    {
      field: "fleetType",
      title: "Fleet Type",
      sort: false,
    },
    {
      field: "dateOfHire",
      title: "Probation Start Date",
      sort: true,
    },
    {
      field: "estimatedProbationEndDate",
      title: "Estimated Probation End Date",
      sort: true,
    },
    {
      field: "status",
      title: "Status",
      sort: false,
    },
    {
      field: "employeeNo",
      title: "",
      render: (row: PilotInfo) => {
        return (
          <a
            onClick={(e) => {
              e.stopPropagation()
              dispatch({
                type: "pilot",
                payload: row,
              })
              history.push(`${Routes.Pilot}/${row.employeeNo}`)
            }}
            className="atm-c-table__action-cell"
          >
            <Icon name="caret-right" />
          </a>
        )
      },
    },
  ]

  const handlerOpenModal = () => {
    setOpenModal(true)
  }

  function sortChange(payload: OptionsChangeFunctionParams<PilotInfo>) {
    window.onscroll = null
    if (payload.type === "sort") {
      changeSorting({
        isSuperAdmin,
        sortColumn: payload.column,
        sortDirection: payload.direction,
      })
    }
  }

  const defaultStatuses = useCallback(() => {
    const status = [
      {
        value: "Active",
        label: "Active",
        selected: true,
      },
      {
        value: "LeaveOfAbsence",
        label: "Leave of Absence",
        selected: !isSuperAdmin && !isAdmin,
      },
    ]
    context.pilotsListCompletedFilter &&
      status.push({
        value: "Completed",
        label: "Completed",
        selected: false,
      })
    return status
  }, [isSuperAdmin, isAdmin, context.pilotsListCompletedFilter])

  const isIntersecting = () => {
    if (!states.isFetching && !states.lastCall) loadPilots(true, isSuperAdmin)
  }

  useEffect(() => {
    if (
      defaultBase !== "" &&
      states.bases.length === 0 &&
      context.pilotsListCompletedFilter !== undefined
    ) {
      const settings = getPilotsFilterSettings()

      if (settings.bases !== null && settings.status !== null) {
        loadBasesAndStatus({ ...settings, isSuperAdmin })
      } else if (settings.bases !== null && settings.status === null) {
        loadBasesAndStatus({
          bases: settings.bases,
          status: defaultStatuses(),
          isSuperAdmin,
        })
      } else {
        setBasesAndStatus({
          bases: [
            {
              label: defaultBase,
              value: defaultBase,
              selected: !isSuperAdmin,
            },
          ],
          status: defaultStatuses(),
          isSuperAdmin,
        })
      }
    }
  }, [
    isSuperAdmin,
    defaultBase,
    context.pilotsListCompletedFilter,
    defaultStatuses,
    states.bases.length,
    setBasesAndStatus,
    loadBasesAndStatus,
  ])

  const resetFilters = () => {
    const newBases: CheckboxesType[] = states.bases.map((base) => {
      return {
        label: base.label,
        value: base.value,
        selected: isSuperAdmin ? false : base.value === defaultBase,
      }
    })
    setBasesAndStatus({
      bases: newBases,
      status: defaultStatuses(),
      isSuperAdmin,
    })
    removePilotsFilterSettings()
  }

  const filter = () => {
    savePilotsFilterSettings({ bases: states.bases, status: states.status })
    loadPilots(false, isSuperAdmin)
    setOpenModal(false)
  }

  function renderResults(item: FormattedSearchEmployeeResponse) {
    return (
      <>
        <EmployeePicture picture={item.picture} size="x-small" />
        {item.value}
      </>
    )
  }

  return (
    <>
      <div className="ebp-foppt-filters-container">
        <Button text="Add Filter" onClick={handlerOpenModal} />
        <Search<FormattedSearchEmployeeResponse>
          onSelect={(item) => {
            history.push(`${Routes.Pilot}/${item.id}`)
          }}
          api={pilot.searchPilots}
          render={renderResults}
        />
      </div>
      <div>
        <p className="ebp-foppt-table-label">
          All employees currently in the probation period
        </p>
        <CustomTable<PilotInfo>
          columns={columns}
          data={states.data}
          loading={states.isFetching}
          options={{
            sortColumn: states.sortColumn,
            sortDirection: states.sortDirection,
          }}
          tableType="pilots"
          onOptionsChange={sortChange}
          onRowClick={(row) => {
            dispatch({
              type: "pilot",
              payload: row,
            })
            history.push(`${Routes.Pilot}/${row.employeeNo}`)
          }}
        />
        <Modal
          variant="wide"
          dismissible={true}
          isActive={openModal}
          onClose={() => {
            setOpenModal(false)
          }}
          focusLockProps={{
            persistentFocus: true,
            returnFocus: true,
          }}
          ariaLabelledBy="modal-heading-1"
          ariaDescribedBy="modal-description-1"
        >
          <ModalHeader>
            <Heading id="modal-heading-1" tagName="h2"></Heading>
          </ModalHeader>
          <ModalBody>
            {context.pilotsListBaseFilter ? (
              <CheckboxItems
                label="Bases"
                values={states.bases}
                setValues={(bases) => {
                  setBases({ bases })
                }}
              />
            ) : null}

            <CheckboxItems
              label="Status"
              values={states.status}
              setValues={(status) => {
                setStatus({ status })
              }}
            />
            <hr />
            <div className="ebp-foppt-l-items--right">
              <ButtonGroup>
                <Button
                  text="Reset"
                  onClick={() => {
                    resetFilters()
                    setOpenModal(false)
                  }}
                />
                <Button text="Filter" variant="primary" onClick={filter} />
              </ButtonGroup>
            </div>
          </ModalBody>
        </Modal>
      </div>
      {states.data.length > 0 && (
        <IntersectionObserverElement callback={isIntersecting} />
      )}
      <GoTop scrollTop={200} />
    </>
  )
}

export default withRouter(PilotsList)
