import React from "react"
import he from "he"

export function renderMultilineComments(comment: string): JSX.Element | null {
  return comment !== undefined && comment !== null && comment !== "" ? (
    <>
      {comment.split("\n").map((line: string, i: number) => (
        <p key={i}>{line}</p>
      ))}
    </>
  ) : null
}

export function decodeHtmlEntities(value: string): string {
  return value !== undefined && value !== null ? he.decode(value) : ""
}
